import React from 'react';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { WppTypography, WppRadio } from 'buildingBlocks';
import { OPTIMIZATION_DIRECTION_TEXT } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { REVENUE_TYPES_STYLES, GOAL_SETTINGS } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { OptimizationDirection } from 'containers/StrategyWizard/types';

const { marginRight, optimizationDirectionText } = REVENUE_TYPES_STYLES;
const { optimizationStyle, optimizationOptionDiv, cardSelectedStyle, cardNotSelectedStyle } = GOAL_SETTINGS;

type OptimizationOptionProps = {
  optDirection: OptimizationDirection
};

const OptimizationOption = (props: OptimizationOptionProps) => {
  const { optDirection } = props;
  const { control } = useFormContext();
  const selectedOptDirection = useWatch({ name: 'customGoal.optimizationDirection' });
  const isSelected = selectedOptDirection === optDirection;

  return (
    <Controller
      name="customGoal.optimizationDirection"
      defaultValue=""
      control={control}
      rules={{
        required: 'Select an option for Copilot to Minimize or Maximize the goal value.',
      }}
      render={({ field: { onChange } }) => (
        <div
          style={{ ...optimizationStyle, ...(isSelected ? cardSelectedStyle : cardNotSelectedStyle) }}
          onClick={() => onChange(optDirection)}
          role="button"
          tabIndex={0}
        >
          <div style={optimizationOptionDiv}>
            <WppTypography tag="p" type="m-strong">{OPTIMIZATION_DIRECTION_TEXT[optDirection].displayName}</WppTypography>
            <WppRadio
              name="optimizationDirection"
              className="revenue-radio-btn"
              checked={isSelected}
              style={marginRight}
              value={optDirection}
            />
          </div>
          <WppTypography tag="p" type="xs-body" style={optimizationDirectionText}>{OPTIMIZATION_DIRECTION_TEXT[optDirection].desc}</WppTypography>
        </div>
      )}
    />
  );
};

export default OptimizationOption;
