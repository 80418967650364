import _ from 'lodash';
import React from 'react';
import { WppActionButton, WppInlineMessage } from 'buildingBlocks';
import { ATTACH_DSP_OBJECT_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { Flight } from 'utils/types';

type FlightStatusProps = {
  alreadyAttached: boolean
  notEligible: boolean
  status: Flight
  strategy: number
  flightOverrideStrategy: React.MouseEventHandler<HTMLWppActionButtonElement>
  removeFlight?: React.MouseEventHandler<HTMLWppActionButtonElement>
};

export const FlightStatus = ({
  alreadyAttached,
  status,
  strategy,
  flightOverrideStrategy,
  removeFlight,
  notEligible,
}: FlightStatusProps) => {
  if (alreadyAttached) {
    return (
      <div style={ATTACH_DSP_OBJECT_STYLES.alredyAttachMsgContainer}>
        <WppInlineMessage
          style={ATTACH_DSP_OBJECT_STYLES.alreadyAttachedMsg}
          size="s"
          message={`This is already attached to strategy ${strategy}. Do you want to instead attach it to this strategy ?`}
          type="warning"
          showTooltipFrom={96}
        />
        <>
          <WppActionButton
            className="link"
            variant="secondary"
            style={ATTACH_DSP_OBJECT_STYLES.yesNoButton}
            onClick={flightOverrideStrategy}
          >
            Yes
          </WppActionButton>
          <WppActionButton
            className="link"
            variant="secondary"
            style={ATTACH_DSP_OBJECT_STYLES.yesNoButton}
            onClick={removeFlight}
          >
            No
          </WppActionButton>
        </>
      </div>
    );
  } if (notEligible && _.get(status, 'ineligibilityReasons', []).length > 0) {
    return (
      <WppInlineMessage
        size="s"
        type="warning"
        message={Array.isArray(status.ineligibilityReasons) && _.join(status.ineligibilityReasons, '\n')}
      />
    );
  }

  return <div />;
};

export default FlightStatus;
