import _ from 'lodash';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import dspIconStyles from 'components/DspIcon/style';
import { DSP } from 'constantsBase';
import { WppCheckbox, WppTableBodyRow, WppTableBodyCell, WppInput, WppTypography, ObjectDropdown, WppTableBody } from 'buildingBlocks';
import { OutcomeDetailsType } from 'containers/StrategyWizard/types';
import { REVENUE_TYPES_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { getBudgetKeyWithPrefix, numberValidate } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { RevTypeOption } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { InputChangeEventDetail, WppInputCustomEvent } from 'utils/types';
import ValidationMessages from './ValidationMessages';

const { outcomesRowCheckbox, outcomeValueWidth, dspnameicon, outcomesName, outcomesDspName, outcomeBillable, outcomeValue } = REVENUE_TYPES_STYLES;

type OutcomesOptionRowProps = {
  outcomeDetails: OutcomeDetailsType
  externalId: string
  selectedObjects: Array<string>
  setSelectedObjs: Dispatch<SetStateAction<Array<string>>>
  updateSingleOutcome: (extId: string, value: string | number) => void
  revenueTypeOptions: Array<RevTypeOption>
  dsp: number
};

const OutcomesOptionRow = (props: OutcomesOptionRowProps) => {
  const {
    selectedObjects, setSelectedObjs, updateSingleOutcome,
    externalId, outcomeDetails, revenueTypeOptions, dsp,
  } = props;

  const { control, setValue, formState: { errors } } = useFormContext();
  const { externalType, name } = outcomeDetails;
  const budgetKey = getBudgetKeyWithPrefix(externalId);
  const hasOutcomeErrors = _.get(errors, `budget[${budgetKey}].outcome`);
  const hasValueErrors = _.get(errors, `budget[${budgetKey}].revenueValue`);
  const budgetObj = useWatch({ name: `budget[${budgetKey}]` });
  const revValue = useWatch({ name: `budget[${budgetKey}].revenueValue`, control });
  const optionExtType = useWatch({ name: `budget[${budgetKey}].externalType`, control });
  const { code: dspName } = DSP.getById(dsp);
  const dspHeaderStyle = { ...dspIconStyles[dspName] };

  useEffect(() => {
    // ensures externalType is set on form. in edgecases where user swaps flight in single platform flow, externalType isnt being set properly
    if (!optionExtType) {
      setValue(`budget.${budgetKey}.externalType`, externalType);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetObj]);

  const checkboxClickHandle = (extId) => {
    const tempArray = [...selectedObjects];
    if (_.includes(tempArray, extId)) {
      const index = tempArray.indexOf(extId);
      tempArray.splice(index, 1);
    } else {
      tempArray.push(extId);
    }
    setSelectedObjs(tempArray);
  };

  return (
    <WppTableBody className="wpp-tbody">
      <WppTableBodyRow className="wpp-table-body-row">
        <WppTableBodyCell className="wpp-table-body-cell">
          <WppCheckbox
            onClick={() => checkboxClickHandle(externalId)}
            checked={_.includes(selectedObjects, externalId)}
            style={outcomesRowCheckbox}
            className={_.includes(selectedObjects, externalId) ? 'visible-checkbox' : 'wpp-checkbox'}
          />
        </WppTableBodyCell>
        <WppTableBodyCell className="wpp-table-body-cell" style={outcomesName}>
          <WppTypography type="s-body" tag="p">{externalId} | {name}</WppTypography>
        </WppTableBodyCell>
        <WppTableBodyCell className="wpp-table-body-cell" style={outcomesDspName}>
          <WppTypography type="s-body" tag="p" style={{ ...dspHeaderStyle, ...dspnameicon }}>{dspName}</WppTypography>
        </WppTableBodyCell>
        <WppTableBodyCell className="wpp-table-body-cell" style={outcomeBillable}>
          <Controller
            name={`budget[${budgetKey}].outcome`}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <ObjectDropdown
                options={revenueTypeOptions}
                revenueTypeOptions={revenueTypeOptions}
                keyFn={(o) => o.text}
                onChange={(e) => {
                  updateSingleOutcome(externalId, e.value);
                  onChange(e.value);
                }}
                revenueTypeOptionsVal={value}
                text={_.toUpper(value)}
                selection
                fluid
                className={hasOutcomeErrors && 'error'}
              />
            )}
          />
        </WppTableBodyCell>
        <WppTableBodyCell className="wpp-table-body-cell" style={outcomeValue}>
          <Controller
            name={`budget[${budgetKey}].revenueValue`}
            control={control}
            rules={{
              required: true,
              validate: {
                greaterThanZero: (v) => _.toNumber(v) > 0,
              },
            }}
            render={(properties) => (
              <WppInput
                className={hasValueErrors && 'error'}
                type="decimal"
                size="s"
                onWppChange={(
                  event: WppInputCustomEvent<InputChangeEventDetail>,
                ) => {
                  const val = event.target.value;
                  properties.field.onChange(val);
                  updateSingleOutcome(externalId, val);
                }}
                value={properties.field.value}
                onKeyDown={(event) => numberValidate(event)}
                style={outcomeValueWidth}
                {..._.omit(properties, 'formState')}
                maskOptions={{
                  decimalPatternOptions: {
                    precision: 4,
                    thousandSeparator: '',
                    decimalSeparator: '.',
                  },
                }}
              />
            )}
          />
        </WppTableBodyCell>
      </WppTableBodyRow>
      {(hasOutcomeErrors || hasValueErrors) && (
        <WppTableBodyRow className="wpp-table-body-row outcomeValueTableError">
          <WppTableBodyCell className="wpp-table-body-cell" colSpan={5} style={outcomeValue}>
            <ValidationMessages
              hasOutcomeErrors={!!hasOutcomeErrors}
              hasValueErrors={!!hasValueErrors}
              isValueEmpty={_.isEmpty(revValue)}
            />
          </WppTableBodyCell>
        </WppTableBodyRow>
      )}
    </WppTableBody>
  );
};

export default OutcomesOptionRow;
