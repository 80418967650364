import React from 'react';
import { Status } from 'containers/StrategyWizard/types';
import { COPILOT_COLORS } from 'globalStyles';
import { pluralizer } from 'utils/formattingUtils';
import { FetchState, FlightDisplayName } from 'utils/types';

const { YELLOWS, TEALS, REDS } = COPILOT_COLORS.NEW_DESIGN_SYSTEM;

type FlightSyncResponseProps = {
  syncFlightsStatus: Status
  flightDisplayName: FlightDisplayName
};

type SyncSuccessProps = {
  result: string
  displayFn: Function
};

const SyncSuccess = ({ result, displayFn }: SyncSuccessProps) => (
  <div style={{ alignSelf: 'center', marginTop: '4px' }}>
    Found&nbsp;
    <span style={{ color: +result === 0 ? YELLOWS.Y500_GOLD : TEALS.T500_KEPPEL }}>{result}</span>
    &nbsp;new {`${displayFn(Number(result))}`}
  </div>
);

const SyncFailure = ({ error }: { error: string }) => (
  <div style={{ alignSelf: 'center' }}>
    <span style={{ color: REDS.R700_VALENTINE }}>{error}</span>
  </div>
);

const FlightSyncResponse = ({ syncFlightsStatus, flightDisplayName: { single, multiple } }: FlightSyncResponseProps) => {
  switch (syncFlightsStatus.kind) {
    case FetchState.success:
      return (
        <SyncSuccess result={syncFlightsStatus.result} displayFn={pluralizer(single, multiple)} />
      );
    case FetchState.error:
      return (
        <SyncFailure error={syncFlightsStatus.error} />
      );
    case FetchState.initial:
    case FetchState.loading:
    default:
      return null;
  }
};

export default FlightSyncResponse;
