import _ from 'lodash';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { WppButton, WppModal, WppTypography } from 'buildingBlocks';
import { CONFIRMATION_MODAL } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { FormulaType, WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { GlobalState } from 'reducers';

const { modalButtonStyle, backButtonConfirmModalBtn, bckModalDescription } = CONFIRMATION_MODAL;

type BackButtonModalProps = {
  setAWGModalOpen: (x: any) => void
};

const BackButtonConfirmModal = ({ setAWGModalOpen }: BackButtonModalProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const reduxGoalStep = useSelector<GlobalState>((state) => state.strategyWizard.goalSelectionStep) as WizardFormGoalSelection;
  const { resetField, clearErrors } = useFormContext();

  const handleBackButton = () => {
    clearErrors('customGoal');
    resetField('customGoal', { defaultValue: _.get(reduxGoalStep, 'customGoal') as Partial<FormulaType> });
    resetField('metricsConfig', { defaultValue: _.get(reduxGoalStep, 'metricsConfig') as Partial<FormulaType> });
    setAWGModalOpen(false);
  };

  return (
    <>
      <WppButton variant="secondary" size="m" style={backButtonConfirmModalBtn} onClick={() => setOpenModal(true)}>Back</WppButton>
      <WppModal open={openModal}>
        <div slot="header">
          <WppTypography type="xl-heading" tag="p">Unsaved Changes</WppTypography>
        </div>
        <div slot="body" style={bckModalDescription}>
          <WppTypography type="s-body" tag="p">Are you sure you want to leave without saving? Your changes will not be saved. </WppTypography>
        </div>
        <div style={modalButtonStyle} slot="actions">
          <WppButton variant="secondary" size="s" onClick={handleBackButton}>Leave Without Saving</WppButton>
          <WppButton variant="primary" size="s" onClick={() => setOpenModal(false)}>Continue Editing</WppButton>
        </div>
      </WppModal>
    </>
  );
};

export default BackButtonConfirmModal;
