import React, { Dispatch, SetStateAction } from 'react';
import { RevTypeOption } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { RevenueTypeConfig } from 'containers/StrategyWizard/types';
import OutcomeOption from './OutcomeOption';

type DspOutcomesOptionsProps = {
  dsp: number
  outcomes: RevenueTypeConfig
  selectedObjects: Array<string>
  setSelectedObjs: Dispatch<SetStateAction<Array<string>>>
  updateSingleOutcome: (extId: string, value: string | number) => void
  revenueTypeOptions: Array<RevTypeOption>
};

const DspOutcomesOptions = (props: DspOutcomesOptionsProps) => {
  const { dsp, outcomes, selectedObjects, setSelectedObjs,
    updateSingleOutcome, revenueTypeOptions } = props;

  return (
    <OutcomeOption
      outcomesList={outcomes}
      dsp={dsp}
      selectedObjects={selectedObjects}
      setSelectedObjs={setSelectedObjs}
      updateSingleOutcome={updateSingleOutcome}
      revenueTypeOptions={revenueTypeOptions}
    />
  );
};

export default DspOutcomesOptions;
