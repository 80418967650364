import _ from 'lodash';
import React from 'react';
import { WppGrid, WppInput, WppIconSearch, WppSegmentedControl, WppSegmentedControlItem, WppTypography } from 'buildingBlocks';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { GrabFormulaFilters } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { useFormulaContext } from '../contexts/FormulaProvider';

const { filterLabelStyle, formulaFiltersDiv } = FORMULA_SECTION_STYLES;

type FormulaFiltersProps = {
  searchValue: string
  onSearchChange: (newValue: string) => void
  filterValue: GrabFormulaFilters
  setFilterValue: (value: GrabFormulaFilters) => void
  openModal: boolean
};

const FormulaFilters = ({ searchValue, onSearchChange, filterValue, setFilterValue, openModal }: FormulaFiltersProps) => {
  const { searchInputRef, dropzoneRef } = useFormulaContext();
  const searchPlaceholder = filterValue === GrabFormulaFilters.copilot ? 'Search by goal' : 'Search by goal, strategy name or ID';

  return (
    <WppGrid container fullWidth style={formulaFiltersDiv}>
      <WppGrid item all={12}>
        <WppInput
          placeholder={searchPlaceholder}
          value={searchValue}
          onWppChange={(e) => onSearchChange(e.target.value)}
          onClick={() => { dropzoneRef.current.blur(); }}
          ref={openModal ? searchInputRef : null}
        >
          <WppIconSearch slot="icon-start" aria-label="Search icon" />
        </WppInput>
      </WppGrid>
      <WppGrid item all={12} style={filterLabelStyle}>
        <WppSegmentedControl value={filterValue} size="s">
          <WppSegmentedControlItem value={GrabFormulaFilters.users} onClick={() => setFilterValue(GrabFormulaFilters.users)}>
            <WppTypography type="s-midi" tag="p">{_.capitalize(GrabFormulaFilters.users)}</WppTypography>
          </WppSegmentedControlItem>
          <WppSegmentedControlItem value={GrabFormulaFilters.copilot} onClick={() => setFilterValue(GrabFormulaFilters.copilot)}>
            <WppTypography type="s-midi" tag="p">{_.capitalize(GrabFormulaFilters.copilot)}</WppTypography>
          </WppSegmentedControlItem>
        </WppSegmentedControl>
      </WppGrid>
    </WppGrid>
  );
};

export default FormulaFilters;
