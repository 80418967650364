import _ from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { WppGrid, WppTypography } from 'buildingBlocks';
import ErrorMessage from 'components/ErrorComponent/ErrorMessage';
import { CHARACTER_LIMIT } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import Tooltip from 'containers/Tooltip';
import Dropzone from './Dropzone';
import { useFormulaContext } from '../contexts/FormulaProvider';

const {
  flexSpaceBetween, sectionHeaderContainer, dropzoneErrorStyle, characterInfoSection,
  tooltip, dropAreaInfoSection, characterCountErrorStyle,
} = FORMULA_SECTION_STYLES;

const TOOL_TIP_CONTENT = 'You can drag and drop metrics and operators into this space or type them in with your keyboard. If you make a mistake you can use the undo and redo buttons at the bottom left. Happy formula building!';

const DropHeader = () => (
  <div style={flexSpaceBetween}>
    <div style={sectionHeaderContainer}>
      <WppTypography type="s-strong" tag="p">Formula Builder</WppTypography>
      <Tooltip content={TOOL_TIP_CONTENT} tooltipstyling={tooltip} />
    </div>
  </div>
);

const DropArea = () => {
  const { control, formState: { errors } } = useFormContext();
  const { characterCount } = useFormulaContext();
  const hasCharacterLimitError = characterCount > CHARACTER_LIMIT;

  return (
    <WppGrid direction="column">
      <DropHeader />
      <Controller
        name="customGoal.formula"
        control={control}
        rules={{
          required: 'You must enter a formula in the Formula Builder window.',
        }}
        render={() => <Dropzone />}
      />
      <div style={dropAreaInfoSection}>
        {!_.isEmpty(_.get(errors, 'customGoal.formula')) && (
          <ErrorMessage
            errorText={_.get(errors, 'customGoal.formula.message') as unknown as string}
            style={dropzoneErrorStyle}
          />
        )}
        <div style={characterInfoSection}>
          <WppTypography type="xs-body" tag="p">Characters:</WppTypography>
          <WppTypography type="xs-strong" tag="p" style={{ ...(hasCharacterLimitError ? characterCountErrorStyle : {}) }}>{characterCount}/{CHARACTER_LIMIT}</WppTypography>
        </div>
      </div>
    </WppGrid>
  );
};

export default DropArea;
