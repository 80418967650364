import { CSSProperties } from 'react';
import { COPILOT_TYPOGRAPHY, COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';

const {
  NEW_DESIGN_SYSTEM: { NEUTRALS, BLUES, GREENS, YELLOWS, REDS },
} = COPILOT_COLORS;

const { SPACING } = COPILOT_LAYOUT;

export const ATTACH_DSP_OBJECT_STYLES: { [key: string]: CSSProperties } = {
  grid: {
    padding: SPACING[24],
    margin: 0,
  },
  row: {
    padding: 0,
  },
  column: {
    padding: 0,
  },
  attachFlightHeading: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  subHeader: {
    ...COPILOT_TYPOGRAPHY.BODY.SMALL,
    color: NEUTRALS.N800_MATTER,
    fontWeight: 400,
    fontSize: 14,
    fontFamily: 'Inter',
  },
  button: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    width: '211px',
    height: SPACING[32],
    marginTop: SPACING[24],
  },
  optTypeBanner: {
    borderRadius: SPACING[4],
    border: `1px solid ${YELLOWS.Y700_METAL}`,
    background: YELLOWS.Y50_DAWN,
    color: YELLOWS.Y700_METAL,
    padding: SPACING[16],
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: SPACING[24],
  },
  optTypeBannerTitle: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    marginTop: `-${SPACING[2]}px`,
  },
  optTypeBannerDesc: {
    ...COPILOT_TYPOGRAPHY.BODY.SMALL,
  },
  infoIcon: {
    color: YELLOWS.Y700_METAL,
    border: `1px solid ${YELLOWS.Y700_METAL}`,
    fontSize: SPACING[8],
    marginRight: SPACING[8],
  },
  flightMenuSelectorText: {
    ...COPILOT_TYPOGRAPHY.BODY.MEDIUM,
    paddingBottom: 0,
  },
  flightMenuSelectorTextActive: {
    ...COPILOT_TYPOGRAPHY.HEADING.H5,
    color: NEUTRALS.N400_GRANITE,
  },
  flightMenuSelectorTextInactive: {
    color: NEUTRALS.N400_GRANITE,
    fontWeight: 400,
  },
  bulkUploadField: {
    ...COPILOT_TYPOGRAPHY.BODY.SMALL,
    color: NEUTRALS.N400_GRANITE,
    fontFamily: 'Gilroy',
  },
  bulkUploadMessage: {
    color: YELLOWS.Y700_METAL,
    margin: '4px 0px 0px 2px',
  },
  bulkUploadButton: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    borderRadius: 4,
    color: BLUES.B500_WAVE,
    borderColor: BLUES.B500_WAVE,
    backgroundColor: 'transparent',
    textTransform: 'capitalize',
    padding: '4px 16px',
    marginTop: SPACING[12],
  },
  alreadyAttachedMsg: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: 'var(--wpp-grey-color-100)',
  },
  yesNoButton: {
    border: 'initial',
    padding: '5px',
    boxShadow: 'initial',
    color: NEUTRALS.N600_ABBEY,
    fontWeight: 600,
    fontSize: 14,
    background: 'initial',
    margin: 'initial',
    textTransform: 'capitalize',
  },
  form: {
    borderRadius: SPACING[8],
    boxShadow: '0px 1px 4px 0px rgba(52, 58, 63, 0.12)',
    padding: SPACING[24],
    backgroundColor: NEUTRALS.N0_WHITE,
  },
  bulkUploadBtn: {
    marginTop: SPACING[12],
  },
  bulkTextArea: {
    width: '696px',
    height: '136px',
  },
  flightMenuSelectorTabs: {
    marginBottom: SPACING[12],
  },
  alredyAttachMsgContainer: {
    marginLeft: SPACING[20],
    marginTop: '5px',
    width: '515px',
  },
};

export const OPTTYPE_CARD: { [key: string]: CSSProperties } = {
  optTypeImg: {
    width: '100%',
  },
  optTypeInformation: {
    display: 'block',
    marginTop: SPACING[16],
    marginBottom: SPACING[16],
  },
  optTypeDescription: {
    font: '14px',
    height: '66px',
    marginBottom: SPACING[16],
  },
  optTypeCard: {
    marginBottom: '-4px',
  },
};

export const MODAL_STYLES: { [key: string]: CSSProperties } = {
  modal: {
    borderRadius: 4,
  },
  closeIcon: {
    position: 'absolute',
    right: SPACING[16],
    top: SPACING[12],
    fontSize: 24,
    fontWeight: 'normal',
    cursor: 'pointer',
  },
  header: {
    ...COPILOT_TYPOGRAPHY.HEADING.H4,
    color: NEUTRALS.N800_MATTER,
    padding: '10px 24px',
    borderBottomColor: NEUTRALS.N200_LINEN,
  },
  contentHeaderDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '.75rem',
    alignItems: 'flex-start',
  },
  contentHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  clearSelectionsBtn: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    borderRadius: SPACING[4],
    backgroundColor: NEUTRALS.N0_WHITE,
    color: NEUTRALS.N900_BLACK,
    border: `1px solid ${NEUTRALS.N300_ALTO}`,
    textTransform: 'capitalize',
    padding: '5px 16px',
    boxShadow: 'none',
  },
  questionIcon: {
    marginLeft: SPACING[8],
    fontSize: 16,
    color: NEUTRALS.N400_GRANITE,
  },
  memberAdvertiserFields: {
    padding: '0px 0px 0px 10px',
  },
  formFieldLabel: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    color: NEUTRALS.N600_ABBEY,
    fontWeight: 500,
    marginBottom: SPACING[4],
  },
  optLvlRadioCard: {
    display: 'flex',
    padding: SPACING[16],
    minHeight: 160,
  },
  optLvlRadioButton: {
    marginRight: SPACING[8],
    marginTop: SPACING[2],
  },
  optLvlRadioText: {
    ...COPILOT_TYPOGRAPHY.HEADING.H5,
    display: 'flex',
    marginBottom: SPACING[8],
    minWidth: '100px',
    color: NEUTRALS.N800_MATTER,
  },
  optTypeCardGroup: {
    margin: 0,
    padding: '24px 24px 0px 24px',
  },
  optTypeCard: {
    margin: '0px 0px 24px 0px',
    borderRadius: SPACING[4],
  },
  optTypeTextContainer: {
    width: '300px',
    marginRight: SPACING[16],
  },
  optTypeHeader: {
    ...COPILOT_TYPOGRAPHY.BODY.SMALL,
    background: YELLOWS.Y50_DAWN,
    color: YELLOWS.Y700_METAL,
    padding: SPACING[8],
    margin: '8px 0px',
  },
  optTypeDescription: {
    ...COPILOT_TYPOGRAPHY.BODY.SMALL,
    color: NEUTRALS.N400_GRANITE,
  },
  optTypeImg: {
    width: '100%',
  },
  optTypeBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  optTypeModalBtn: {
    margin: '0px 24px 24px 0px',
  },
  attachFlights: {
    padding: '0.75rem',
  },
  attachFlightsHeader: {
    ...COPILOT_TYPOGRAPHY.HEADING.H5,
    color: NEUTRALS.N800_MATTER,
    marginBottom: SPACING[4],
  },
  selectedFlights: {
    marginTop: '-22px',
    padding: '0.75rem',
    maxHeight: '250px',
    overflowY: 'auto',
    display: 'block',
  },
  button: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    textTransform: 'capitalize',
    marginLeft: SPACING[12],
    padding: '4px 16px',
    borderRadius: SPACING[4],
  },
  updateButton: {
    fontSize: '14px',
    fontWeight: 'normal',
    backgroundColor: 'transparent',
    padding: '0px',
    border: 'none',
    color: BLUES.B500_WAVE,
    cursor: 'default',
    outline: '0',
    marginBottom: SPACING[4],
    boxShadow: 'none',
    textTransform: 'none',
  },
  updateBtnHover: {
    backgroundColor: BLUES.B50_SEA,
    padding: '4px',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  attachButton: {
    fontWeight: 500,
    float: 'right',
  },
  popUp: {
    filter: 'none',
    backgroundColor: BLUES.B300_SKY,
    top: '-20px',
    borderRadius: '4px',
  },
  popUpMessage: {
    backgroundColor: BLUES.B300_SKY,
    color: NEUTRALS.N0_WHITE,
    boxShadow: 'none',
    padding: '0px',
    margin: '0px',
  },
  optTypeButton: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    border: `1px solid ${YELLOWS.Y700_METAL}`,
    borderRadius: SPACING[4],
    boxShadow: 'none',
    margin: 'auto',
    marginLeft: SPACING[4],
    padding: SPACING[4],
    background: YELLOWS.Y50_DAWN,
    color: YELLOWS.Y700_METAL,
    textTransform: 'capitalize',
    minWidth: 184,
  },
  actionButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: SPACING[8],
    marginTop: SPACING[5],
  },
  advertiseFormStyle: {
    margin: 0,
  },
  attachFormFeildLabel: {
    marginBottom: SPACING[8],
  },
  attachAdvertiseContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'inherit',
  },
  weightModalProgress: {
    height: '100%',
  },
  advertiseRefreshButton: {
    position: 'absolute',
    right: '31px',
    marginTop: '-5px',
  },
};

export const FLIGHT_DETAIL_STYLES: { [key: string]: CSSProperties } = {
  baseTable: {
    width: '100%',
    fontSize: '14px',
    margin: 0,
    cursor: 'default',
    tableLayout: 'fixed',
  },
  headerWppTable: {
    ...COPILOT_TYPOGRAPHY.DESCRIPTION.BOLD,
    width: '100%',
    border: 'none',
    paddingLeft: SPACING[20],
    borderBottom: `1px solid ${COPILOT_COLORS.WPP.grey300}`,
    marginBottom: '0px',
  },
  additionalExpiredStyles: {
    border: `1px solid ${NEUTRALS.N100_CLOUD}`,
    backgroundColor: NEUTRALS.N100_CLOUD,
  },
  additionalToBeDetachedStyles: {
    border: `1px solid ${NEUTRALS.N100_CLOUD}`,
    backgroundColor: REDS.R50_VISAGE,
  },
  reactivatedFlightStyles: {
    backgroundColor: GREENS.G50_CANE,
    border: `1px solid ${NEUTRALS.N100_CLOUD}`,
  },
  loaderOrAccordion: {
    width: '3%',
    textAlign: 'right',
    paddingRight: '5px',
    cursor: 'pointer',
  },
  firstHeaderCell: {
    width: '3%',
    minWidth: '36px',
  },
  childLevelHeaderCell: {
    width: '3%',
    minWidth: '36px',
    backgroundColor: NEUTRALS.N0_WHITE,
  },
  flightName: {
    width: '55%',
    paddingLeft: '0px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  childLevelFlightName: {
    width: '55%',
    paddingLeft: SPACING[8],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderTopLeftRadius: SPACING[4],
    borderBottomLeftRadius: SPACING[4],
    wordWrap: 'break-word',
    maxWidth: '0px',
  },
  dspHeader: {
    width: '7%',
  },
  dspHeaderText: {
    marginLeft: '5px',
  },
  dspIcon: {
    width: '7%',
    paddingLeft: '0px',
  },
  objectType: {
    width: '12%',
    paddingLeft: '0px',
  },
  flightDates: {
    width: '21%',
    paddingLeft: '0px',
  },
  childLevelFlightDates: {
    width: '21%',
    paddingLeft: '0px',
    borderTopRightRadius: SPACING[4],
    borderBottomRightRadius: SPACING[4],
  },
  endIcon: {
    width: '4%',
    paddingLeft: '0px',
    textAlign: 'right',
  },
  childLevelEndCell: {
    width: '4%',
    paddingLeft: '0px',
    backgroundColor: NEUTRALS.N0_WHITE,
  },
  iconStyle: {
    cursor: 'pointer',
    margin: '0px 0px 0px -15px',
  },
  popUp: {
    padding: '8px 10px',
  },
  baseChildLevelTableStyle: {
    border: 'hidden',
  },
  attachFlightTableHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  activeChildFlightRow: {
    backgroundColor: NEUTRALS.N0_WHITE,
  },
  inactiveChildFlightRow: {
    backgroundColor: NEUTRALS.N50_ALABASTER,
    borderTop: `1px solid ${NEUTRALS.N0_WHITE}`,
  },
  childFlightsError: {
    width: '97%',
  },
  accordionTitle: {
    paddingTop: SPACING[16],
    paddingBottom: SPACING[4],
    fontSize: SPACING[12],
    color: NEUTRALS.N600_ABBEY,
  },
  activationFlightModal: {
    backgroundColor: `${NEUTRALS.N900_BLACK}4D`,
    width: 575,
  },
  activationFlightModalContainer: {
    background: NEUTRALS.N0_WHITE,
    padding: SPACING[32],
  },
  activationFlightModalTextDiv: {
    wordBreak: 'break-word',
  },
  modalWarning: {
    height: '100%',
    marginRight: SPACING[16],
    verticalAlign: 'top',
  },
  activationModalHeader: {
    ...COPILOT_TYPOGRAPHY.HEADING.H4,
  },
  activationModalText: {
    ...COPILOT_TYPOGRAPHY.BODY.SMALL,
    color: NEUTRALS.N600_ABBEY,
  },
  activationModalFlightName: {
    color: NEUTRALS.N900_BLACK,
    fontWeight: 500,
  },
  activationModalBtns: {
    textAlign: 'right',
    marginTop: SPACING[24],
  },
  activationModalCancelBtn: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    borderRadius: SPACING[4],
    backgroundColor: NEUTRALS.N0_WHITE,
    color: NEUTRALS.N900_BLACK,
    border: `1px solid ${NEUTRALS.N300_ALTO}`,
    textTransform: 'capitalize',
    marginRight: SPACING[16],
    padding: '5px 27px',
    boxShadow: 'none',
  },
  activationModalDeactivateBtn: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    borderRadius: SPACING[4],
    textTransform: 'capitalize',
    backgroundColor: REDS.R500_WATERMELON,
    border: `1px solid ${REDS.R500_WATERMELON}`,
    color: NEUTRALS.N0_WHITE,
    padding: '5px 16px',
    boxShadow: 'none',
  },
  activationModalActivateBtn: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    borderRadius: SPACING[4],
    textTransform: 'capitalize',
    backgroundColor: BLUES.B500_WAVE,
    border: `1px solid ${BLUES.B500_WAVE}`,
    color: NEUTRALS.N0_WHITE,
    padding: '5px 27px',
    boxShadow: 'none',
  },
  modalBodySection: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[12],
    marginBottom: SPACING[12],
    whiteSpace: 'break-spaces',
    textAlign: 'left',
  },
  activationFlightModelStyle: {
    whiteSpace: 'break-spaces',
    textAlign: 'left',
  },
  activationModelCancleBtn: {
    marginRight: SPACING[12],
  },
  attachFlightObjectCountStyle: {
    marginTop: '10px',
    marginBottom: '5px',
  },
};
