import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { Image, WppTypography } from 'buildingBlocks';
import { ALL_OPERATORS, OPERATOR_SVGS } from 'containers/StrategyWizard/constants';
import { DragDropTypes } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { checkIsOperator } from 'containers/StrategyWizard/utils';
import { COPILOT_COLORS } from 'globalStyles';
import { generateRandomUUID } from 'utils/formattingUtils';
import { useFormulaContext } from '../contexts/FormulaProvider';
import { DraggableItem } from '../contexts/types';

const { operator, metric, dragContentOpMet } = FORMULA_SECTION_STYLES;
const { WPP: { grey200, datavizBrand100 } } = COPILOT_COLORS;

type DraggableComponentProps = {
  dragContent: string
};

const DraggableComponent = (props: DraggableComponentProps) => {
  const { dragContent } = props;
  const [pressed, setPressed] = useState<boolean>(false);
  const { dropItems, dropItemIds, handleRemoveItem, setDropzoneActive } = useFormulaContext();
  const isOperator = checkIsOperator(dragContent);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: _.includes(ALL_OPERATORS, dragContent) ? DragDropTypes.operator : DragDropTypes.metric,
    item: {
      id: generateRandomUUID(),
      content: dragContent,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item: DraggableItem, monitor) => {
      // Remove dropItem when dropped outside of dropzone
      // getDropResult will return { message: 'dropzone' } if item was dropped in dropzone
      if (!monitor.getDropResult() && dropItemIds.has(item.id)) {
        handleRemoveItem(item.id);
      }
    },
  }), [dropItems]);

  const getStyle = () => {
    const borderColor = isOperator ? grey200 : datavizBrand100;
    const style = { ...(isDragging && { outline: `2px solid ${borderColor}` }) };
    if (isOperator) {
      return {
        ...style,
        ...operator,
        ...(pressed && { backgroundColor: grey200 }),
      };
    }
    return {
      ...style,
      ...metric,
      ...(pressed && { backgroundColor: datavizBrand100 }),
    };
  };

  useEffect(() => {
    if (isDragging && !pressed) {
      setPressed(true);
      setDropzoneActive(isDragging);
    }
    // after finishing a drag this gets hit once more - dragging into dropzone should keep dropzone active
    if (!isDragging && pressed) {
      setDropzoneActive(isDragging);
      setPressed(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      style={getStyle()}
      ref={drag}
    >
      {_.has(OPERATOR_SVGS, dragContent) ? <Image src={OPERATOR_SVGS[dragContent]} alt={dragContent} /> : <WppTypography style={dragContentOpMet} type="s-midi" tag="p">{dragContent}</WppTypography>}
    </div>
  );
};

export default DraggableComponent;
