import _ from 'lodash';
import React, { ReactElement } from 'react';
import { DSP, FLIGHT_EXTERNAL_TYPE, STRATEGY_TYPE } from 'constantsBase';
import { AttachFlightsInfoType, ModalSessionFlightsInfoType, WizardFormAttachFlights } from 'containers/StrategyWizard/types';
import { Permission } from 'utils/featureFlags';
import { FetchState } from 'utils/types';

enum FlightAttachStatusEnum {
  add = 'add',
  addAndRemovedBidModifier = 'addAndRemovedBidModifier',
  update = 'update',
  attached = 'attached',
  remove = 'remove',
  removedFromOtherStrategy = 'removedFromOtherStrategy',
  ineligible = 'ineligible',
}

enum FlightStatus {
  eligible = 'ELIGIBLE',
  ineligible = 'INELIGIBLE',
  warning = 'WARNING',
}

export const ATTACH_FLIGHTS_STEP_INITIAL_VALUES = {
  member: null,
  advertiser: null,
  brand: null,
  optimizationLevel: null,
  attachedFlights: [],
} as WizardFormAttachFlights;

const PREFIX = 'STRATEGY_WIZARD_ATTACH_FLIGHTS:';
export const UPDATE_ADDED_AND_UPDATED_FLIGHTS = `${PREFIX}UPDATE_ADDED_AND_UPDATED_FLIGHTS`;
export const FLIGHT_OVERRIDE_STRATEGY_FLAG = `${PREFIX}FLIGHT_OVERRIDE_STRATEGY_FLAG`;
export const FLIGHT_STATUS = {
  ELIGIBLE: FlightStatus.eligible,
  INELIGIBLE: FlightStatus.ineligible,
  WARNING: FlightStatus.warning,
};

export const RESET_STRATEGY_CONFIGURATION = `${PREFIX}RESET_STRATEGY_CONFIGURATION`;

export const FLIGHT_ATTACH_STATUS = {
  ADD: { status: FlightAttachStatusEnum.add, style: 'ELIGIBLE' },
  ADD_AND_REMOVE_BID_MODIFIER: {
    status: FlightAttachStatusEnum.addAndRemovedBidModifier,
    style: 'WARNING',
  },
  UPDATE: { status: FlightAttachStatusEnum.update, style: 'ELIGIBLE' },
  ATTACHED: { status: FlightAttachStatusEnum.attached, style: 'ATTACHED' },
  REMOVE: { status: FlightAttachStatusEnum.remove, style: 'ERROR' },
  REMOVE_OTHER: {
    status: FlightAttachStatusEnum.removedFromOtherStrategy,
    style: 'WARNING',
  },
  INELIGIBLE: { status: FlightAttachStatusEnum.ineligible, style: 'WARNING' },
};

export const RESET = `${PREFIX}RESET`;

export enum ExternalTypeOptimizationLevel {
  HIGHER_ORDER = 'higherOrder',
  LOWER_ORDER = 'lowerOrder',
}

export enum OptimizationType {
  crossPlatform = 'crossPlatform',
  campaign = 'campaign',
  lineItem = 'lineItem',
}

export const OPTIMIZATION_TYPE_OPTIONS = {
  [OptimizationType.crossPlatform]: {
    displayName: 'Cross-Platform',
    header: 'Budget set and managed in Copilot',
    description: 'Create a strategy that can manage optimization and delivery on multiple Campaigns & Insertion Orders across multiple buying platforms.',
    bannerTitle: 'Cross-Platform Strategy',
    bannerDesc: 'Copilot will manage budget according to the budget intervals and goals configured in this strategy. Budgets will be regularly updated on all attached objects. Targeting will not be updated on any attached objects.',
    imgSrc: '/img/components/StrategyWizard/cross-platform-optimization.svg',
  },
  [OptimizationType.campaign]: {
    displayName: 'Campaign',
    header: 'Budget inherited from Insertion Order',
    description: 'Create a strategy that can manage optimization and delivery on a single Campaign or Insertion Order within a single buying platform.',
    bannerTitle: 'Campaign Strategy',
    bannerDesc: 'Copilot will manage budget according to the budget intervals inherited from the object attached to this strategy. Targeting will not be updated on any attached objects.',
    imgSrc: '/img/components/StrategyWizard/parent-level-optimization.svg',
  },
  [OptimizationType.lineItem]: {
    displayName: 'Line Items',
    header: 'Budget not considered',
    description: 'Create a strategy that can manage targeting and bidding on individual Line Items or Ad Groups within a single buying platform.',
    bannerTitle: 'Line Items Strategy',
    bannerDesc: 'Copilot will manage the objects according to the goals configured in this strategy. Targeting and budgets will not be updated on any attached objects.',
    imgSrc: '/img/components/StrategyWizard/child-level-optimization.svg',
  },
};

export const OPTIMIZATION_LEVELS = {
  [DSP.TTD.id]: {
    [ExternalTypeOptimizationLevel.HIGHER_ORDER]: {
      externalTypeIds: [FLIGHT_EXTERNAL_TYPE.ttdCampaign.id],
      name: 'Campaign',
      header: 'Optimize Campaign & Associated Ad Groups',
      description: `Copilot prioritizes budget delivery while optimizing
                    the selected Campaign and its associated Ad Groups towards a goal.`,
      defaultStrat: STRATEGY_TYPE.ttdBudgetOptimization.id,
      permissions: [Permission.stratTTDBudgetOptimization],
      displayName: { single: 'Campaign', multiple: 'Campaigns' },
    },
    [ExternalTypeOptimizationLevel.LOWER_ORDER]: {
      externalTypeIds: [FLIGHT_EXTERNAL_TYPE.ttdMegagon.id],
      name: 'Ad Group',
      header: 'Optimize Individual Ad Groups',
      description: 'Copilot will optimize the selected Ad Groups towards a goal.',
      defaultStrat: STRATEGY_TYPE.customBidList.id,
      permissions: [Permission.stratCustomBidList],
      displayName: { single: 'Ad Group', multiple: 'Ad Groups' },
    },
  },
  [DSP.WALMART.id]: {
    [ExternalTypeOptimizationLevel.HIGHER_ORDER]: {
      externalTypeIds: [FLIGHT_EXTERNAL_TYPE.wmtCampaign.id],
      name: 'Campaign',
      header: 'Optimize Campaign & Associated Ad Groups',
      description: `Copilot prioritizes budget delivery while optimizing
                    the selected Campaign and its associated Ad Groups towards a goal.`,
      defaultStrat: STRATEGY_TYPE.wmtBudgetOptimization.id,
      permissions: [Permission.stratWMTBudgetOptimization],
      displayName: { single: 'Campaign', multiple: 'Campaigns' },
    },
    [ExternalTypeOptimizationLevel.LOWER_ORDER]: {
      externalTypeIds: [FLIGHT_EXTERNAL_TYPE.wmtMegagon.id],
      name: 'Ad Group',
      header: 'Optimize Individual Ad Groups',
      description: 'Copilot will optimize the selected Ad Groups towards a goal.',
      defaultStrat: null,
      permissions: [],
      displayName: { single: 'Ad Group', multiple: 'Ad Groups' },
    },
  },
  [DSP.DBM.id]: {
    [ExternalTypeOptimizationLevel.HIGHER_ORDER]: {
      externalTypeIds: [FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id],
      name: 'Insertion Order',
      header: 'Optimize Insertion Order & Associated Line Items',
      description: `Copilot prioritizes budget delivery while optimizing
                    the selected Insertion Order and its associated Line Items towards a goal.`,
      defaultStrat: STRATEGY_TYPE.dbmBudgetOptimization.id,
      permissions: [Permission.stratDBMBudgetOptimization, Permission.stratDBMCustomSDF],
      displayName: { single: 'Insertion Order', multiple: 'Insertion Orders' },
    },
    [ExternalTypeOptimizationLevel.LOWER_ORDER]: {
      externalTypeIds: [FLIGHT_EXTERNAL_TYPE.dbmLineItem.id],
      name: 'Line Item',
      header: 'Optimize Individual Line Items',
      description: 'Copilot will optimize the selected Line Items towards a goal.',
      defaultStrat: null,
      permissions: [],
      displayName: { single: 'Line Item', multiple: 'Line Items' },
    },
  },
  [DSP.APN.id]: {
    [ExternalTypeOptimizationLevel.HIGHER_ORDER]: {
      externalTypeIds: [FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id],
      name: 'Insertion Order',
      header: 'Optimize Insertion Order & Associated Line Items',
      description: `Copilot prioritizes budget delivery while optimizing
                    the selected Insertion Order and its associated Line Items towards a goal.`,
      defaultStrat: STRATEGY_TYPE.apnBudgetOptimization.id,
      permissions: [Permission.stratAPNBudgetOptimization],
      displayName: { single: 'Insertion Order', multiple: 'Insertion Orders' },
    },
    [ExternalTypeOptimizationLevel.LOWER_ORDER]: {
      externalTypeIds: [FLIGHT_EXTERNAL_TYPE.apnLineItem.id],
      name: 'Line Items',
      header: 'Optimize Individual Line Items',
      description: 'Copilot will optimize the selected Line Items towards a goal.',
      defaultStrat: STRATEGY_TYPE.helios.id,
      permissions: [Permission.stratHeliosSegRec, Permission.stratHelios],
      displayName: { single: 'Line Item', multiple: 'Line Items' },
    },
  },
  [DSP.AMZN.id]: {
    [ExternalTypeOptimizationLevel.HIGHER_ORDER]: {
      externalTypeIds: [FLIGHT_EXTERNAL_TYPE.amznCampaign.id],
      name: 'Campaign',
      header: 'Optimize Campaign & Associated Ad Groups',
      description: `Copilot prioritizes budget delivery while optimizing
                    the selected Campaign and its associated Ad Groups towards a goal`,
      defaultStrat: STRATEGY_TYPE.amznBudgetOptimization.id,
      permissions: [Permission.stratAMZNBudgetOptimization],
      displayName: { single: 'Campaign', multiple: 'Campaigns' },
    },
    [ExternalTypeOptimizationLevel.LOWER_ORDER]: {
      externalTypeIds: [FLIGHT_EXTERNAL_TYPE.amznAdGroup.id],
      name: 'Ad Group',
      header: 'Optimize Individual Ad Groups',
      description: 'Copilot will optimize the selected Ad Groups towards a goal.',
      defaultStrat: null,
      permissions: [],
      displayName: { single: 'Ad Group', multiple: 'Ad Groups' },
    },
  },
  [DSP.MULTIPLE.id]: {
    [ExternalTypeOptimizationLevel.HIGHER_ORDER]: {
      externalTypeIds: [FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id, FLIGHT_EXTERNAL_TYPE.amznCampaign.id, FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id, FLIGHT_EXTERNAL_TYPE.ttdCampaign.id],
      name: 'Campaigns & Insertion Orders',
      header: 'Optimize Campaigns & Insertion Orders',
      description: `Copilot prioritizes budget delivery while optimizing the selected Campaigns &
                    Insertion Orders and their associated Ad Groups & Line Items towards a goal.`,
      defaultStrat: STRATEGY_TYPE.crossPlatformOptimization.id,
      permissions: [Permission.stratCrossPlatformOptimization],
      displayName: { single: 'Campaign/Insertion Order', multiple: 'Campaigns and/or Insertion Orders' },
    },
    [ExternalTypeOptimizationLevel.LOWER_ORDER]: {
      externalTypeIds: [FLIGHT_EXTERNAL_TYPE.apnLineItem.id, FLIGHT_EXTERNAL_TYPE.amznAdGroup.id, FLIGHT_EXTERNAL_TYPE.dbmLineItem.id, FLIGHT_EXTERNAL_TYPE.ttdMegagon.id],
      name: 'Ad Groups & Line Items',
      header: 'Optimize Ad Groups & Line Items',
      description: 'Copilot will optimize the selected Ad Groups & Line Items towards a goal.',
      defaultStrat: [],
      permissions: [Permission.stratCrossPlatformOptimization],
      displayName: { single: 'Ad Group/Line Item', multiple: 'Ad Groups and/or Line Items' },
    },
  },
};

export const EXTERNAL_TYPE_TO_DSP_AND_DEFAULT_OPT_LEVEL = {
  [FLIGHT_EXTERNAL_TYPE.ttdCampaign.id]: {
    dsp: DSP.TTD.id,
    optimizationLevel: OPTIMIZATION_LEVELS[DSP.TTD.id][ExternalTypeOptimizationLevel.HIGHER_ORDER],
  },
  [FLIGHT_EXTERNAL_TYPE.ttdMegagon.id]: {
    dsp: DSP.TTD.id,
    optimizationLevel: OPTIMIZATION_LEVELS[DSP.TTD.id][ExternalTypeOptimizationLevel.LOWER_ORDER],
  },
  [FLIGHT_EXTERNAL_TYPE.wmtCampaign.id]: {
    dsp: DSP.WALMART.id,
    optimizationLevel: OPTIMIZATION_LEVELS[DSP.WALMART.id][ExternalTypeOptimizationLevel.HIGHER_ORDER],
  },
  [FLIGHT_EXTERNAL_TYPE.wmtMegagon.id]: {
    dsp: DSP.WALMART.id,
    optimizationLevel: OPTIMIZATION_LEVELS[DSP.WALMART.id][ExternalTypeOptimizationLevel.LOWER_ORDER],
  },
  [FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id]: {
    dsp: DSP.DBM.id,
    optimizationLevel: OPTIMIZATION_LEVELS[DSP.DBM.id][ExternalTypeOptimizationLevel.HIGHER_ORDER],
  },
  [FLIGHT_EXTERNAL_TYPE.dbmLineItem.id]: {
    dsp: DSP.DBM.id,
    optimizationLevel: OPTIMIZATION_LEVELS[DSP.DBM.id][ExternalTypeOptimizationLevel.LOWER_ORDER],
  },
  [FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id]: {
    dsp: DSP.APN.id,
    optimizationLevel: OPTIMIZATION_LEVELS[DSP.APN.id][ExternalTypeOptimizationLevel.HIGHER_ORDER],
  },
  [FLIGHT_EXTERNAL_TYPE.apnLineItem.id]: {
    dsp: DSP.APN.id,
    optimizationLevel: OPTIMIZATION_LEVELS[DSP.APN.id][ExternalTypeOptimizationLevel.LOWER_ORDER],
  },
  [FLIGHT_EXTERNAL_TYPE.amznCampaign.id]: {
    dsp: DSP.AMZN.id,
    optimizationLevel: OPTIMIZATION_LEVELS[DSP.AMZN.id][ExternalTypeOptimizationLevel.HIGHER_ORDER],
  },
  [FLIGHT_EXTERNAL_TYPE.amznAdGroup.id]: {
    dsp: DSP.AMZN.id,
    optimizationLevel: OPTIMIZATION_LEVELS[DSP.AMZN.id][ExternalTypeOptimizationLevel.LOWER_ORDER],
  },
};

export const HIGHER_ORDER_EXTERNAL_TYPE_IDS = new Set(_.flatMapDeep(_.keys(OPTIMIZATION_LEVELS), (dsp) => OPTIMIZATION_LEVELS[dsp][ExternalTypeOptimizationLevel.HIGHER_ORDER].externalTypeIds));

export const INCOMPATIBLE_OPT_LEVELS = [
  OPTIMIZATION_LEVELS[DSP.DBM.id][ExternalTypeOptimizationLevel.LOWER_ORDER],
  OPTIMIZATION_LEVELS[DSP.AMZN.id][ExternalTypeOptimizationLevel.LOWER_ORDER],
  OPTIMIZATION_LEVELS[DSP.MULTIPLE.id][ExternalTypeOptimizationLevel.LOWER_ORDER],
];

export const attachFlightsInfoInitialState: AttachFlightsInfoType = {
  flightsStatus: {},
  ineligibleFlights: [],
  eligibleFlights: [],
  toBeDetached: [],
  attachedToThisStrategy: [],
};

export enum SelectedTab {
  selectLineItemCampaign = 'Select Line Items & Campaigns',
  bulkUploader = 'Bulk Uploader',
}

export const optLvlPermissions = [
  'strat_helios_seg_rec',
  'strat_helios',
  'strat_custom_bid_list',
];

export const modalSessionFlightsInfoInitialState: ModalSessionFlightsInfoType = {
  loadingFlightCandidates: false,
  flightCandidates: [],
  searchTerm: '',
  pendingFlights: [],
  attachedToAnotherStrategy: [],
  attachedToThisStrategy: [],
  eligibleFlights: [],
  ineligibleFlights: [],
  flightsStatus: {},
  bulkUpload: {
    bulkUploadFailedMsg: null,
    bulkUploadedFlights: [],
    bulkUploadLoading: false,
    notFoundFlights: [],
    attachedToCurrentFlights: [],
  },
  syncFlightsStatus: { kind: FetchState.initial },
};

export enum FlightCategory {
  attachedToThisStrategy = 'attachedToThisStrategy',
  eligibleFlights = 'eligibleFlights',
  ineligibleFlights = 'ineligibleFlights',
  toBeDetached = 'toBeDetached',
  reactivatedFlights = 'reactivatedFlights',
  toBeDeactivated = 'toBeDeactivated',
  deactivatedFlights = 'deactivatedFlights',
  eligCPFlightsWithoutAmountBudgetType = 'eligCPFlightsWithoutAmountBudgetType',
  eligCPFlightsWithSpend = 'eligCPFlightsWithSpend',
}

export const MAX_FLIGHTS_ALLOWED = 100;

export const ALL = 'All';

export const getAllOption = (isMemberField?: boolean): { displayName?: string, name?: string, content: ReactElement } => ({
  [isMemberField ? 'displayName' : 'name']: ALL,
  content: <span style={{ ...(isMemberField && { marginLeft: 10 }) }}>All</span>,
});
