import _ from 'lodash';
import React from 'react';
import { WppAccordion } from 'buildingBlocks';
import { AttachFlightsInfoType } from 'containers/StrategyWizard/types';
import { flightKey } from 'containers/StrategyWizard/utils';
import { Flight } from 'utils/types';
import AttachFlightsDetails from './AttachFlightsDetails';
import ChildLevelFlightDetails from './ChildLevelFlightDetails';

type ActiveFlightsTablesProps = {
  activeFlights: Array<Flight>
  removeFlight: Function
  attachFlightsInfo: AttachFlightsInfoType
  isHigherOrderOptLvl: boolean
  editingCrossPlatformStrat: boolean
  selectedOptType: string
};

type HigherOrderFlightDetailsProps = {
  flight: Flight
  index: number
  flightCategory: string
  isHigherOrderOptLvl: boolean
  removeFlight: Function
  editingCrossPlatformStrat: boolean
  selectedOptType: string
};

const HigherOrderFlightDetails = (props: HigherOrderFlightDetailsProps) => {
  const { flight, index, flightCategory, isHigherOrderOptLvl, removeFlight, editingCrossPlatformStrat, selectedOptType } = props;

  return (
    <WppAccordion size="m" className="object-attach-accordion">
      <div slot="header">
        <AttachFlightsDetails
          key={`${flightKey(flight)} - ${flight.id}`}
          index={index}
          flight={flight}
          isHigherOrderOptLvl={isHigherOrderOptLvl}
          flightCategory={flightCategory}
          removeFlight={removeFlight}
          editingCrossPlatformStrat={editingCrossPlatformStrat}
        />
      </div>
      <ChildLevelFlightDetails parentFlightExtId={flight.externalId} flight={flight} selectedOptType={selectedOptType} />
    </WppAccordion>
  );
};

const ActiveFlightsTable = (props: ActiveFlightsTablesProps) => {
  const {
    activeFlights,
    removeFlight,
    attachFlightsInfo,
    isHigherOrderOptLvl,
    editingCrossPlatformStrat,
    selectedOptType,
  } = props;

  const { attachedToThisStrategy } = attachFlightsInfo;
  const getFlightCategory = (flight) => (_.includes(attachedToThisStrategy, flight) ? 'attachedToThisStrategy' : 'eligibleFlights');

  return (
    <>
      {isHigherOrderOptLvl
        ? (
          <>
            {_.map(activeFlights, (flight: Flight, index: number) => (
              <HigherOrderFlightDetails
                key={index}
                index={index}
                flight={flight}
                isHigherOrderOptLvl={isHigherOrderOptLvl}
                flightCategory={getFlightCategory(flight)}
                removeFlight={removeFlight}
                editingCrossPlatformStrat={editingCrossPlatformStrat}
                selectedOptType={selectedOptType}
              />
            ))}
          </>
        )
        : (_.map(activeFlights, (flight: Flight, index: number) => (
          <AttachFlightsDetails
            key={`${flightKey(flight)} - ${flight.id}`}
            flight={flight}
            isHigherOrderOptLvl={isHigherOrderOptLvl}
            flightCategory={getFlightCategory(flight)}
            removeFlight={removeFlight}
            index={index}
          />
        )))}
    </>
  );
};

export default ActiveFlightsTable;
