import _ from 'lodash';
import React, { ReactNode, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { WppButton, WppModal, WppTypography } from 'buildingBlocks';
import { GOAL_TYPES } from 'constantsBase';
import { ALL_OPERATORS, OPERATOR_SVGS, SAVE_AWG_CONFIG } from 'containers/StrategyWizard/constants';
import { OPTIMIZATION_DIRECTION_TEXT } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { AWG_WEIGHTS_MODAL_STYLES, CONFIRMATION_MODAL } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { checkIsNumber, getDropzoneContent } from 'containers/StrategyWizard/utils';
import { useAWGContext } from './contexts/AWGProvider';
import { validateFormula } from './contexts/utils';

const { cancelBtnStyle } = AWG_WEIGHTS_MODAL_STYLES;
const { subHeaderContainer, weightedColor, unweightedColor, confirmModalActionsBtn } = CONFIRMATION_MODAL;

export const getFormulaText = (formulaList, metricConfig) => _.map(formulaList, (item: string, i: number) => {
  const text = _.includes(ALL_OPERATORS, item) || checkIsNumber(item) ? item : getDropzoneContent(item);
  // pad ends of each operator (except parenthesis)
  const formattedText = (item in OPERATOR_SVGS) ? _.pad(text, 3) : text;
  if (!_.isEmpty(_.get(metricConfig, `[${item}].weighting`))) {
    return <span key={i} style={weightedColor}>Weighted {text}</span>;
  }
  return <span key={i} style={unweightedColor}>{formattedText}</span>;
});

type SubHeaderProps = {
  label: string
  children: ReactNode
};

const SubHeader = ({ label, children }: SubHeaderProps) => (
  <div style={subHeaderContainer}>
    <WppTypography type="s-midi" tag="p">{label}</WppTypography>
    {children}
  </div>
);

type ConfirmationModalProps = {
  setAWGModalOpen: (x: any) => void
};

const ConfirmationModal = ({ setAWGModalOpen }: ConfirmationModalProps) => {
  const { selectedFormula, formulaMetrics, isImpactGoal } = useAWGContext();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const validating = useRef<boolean>(false);
  const { setValue, trigger, formState: { errors }, setError } = useFormContext();
  const dispatch = useDispatch();
  const customGoal = useWatch({ name: 'customGoal', defaultValue: selectedFormula });
  const optDirection = OPTIMIZATION_DIRECTION_TEXT[customGoal?.optimizationDirection]?.displayName;

  const handleConfirm = () => {
    const metricConfigToSave = _.some(formulaMetrics, (metric) => !!_.size(metric.weighting))
      ? _.pickBy(formulaMetrics, (metric) => _.size(metric.weighting))
      : {};
    setValue('customGoal', { ...customGoal, name: _.trim(customGoal.name) });
    setValue('metricsConfig', metricConfigToSave);
    if (!isImpactGoal) {
      setValue('goal.type', GOAL_TYPES.awgCreateYourOwn.value);
    }
    const payload = {
      customGoal,
      metricsConfig: metricConfigToSave,
    };
    dispatch({ type: SAVE_AWG_CONFIG, payload });
    setOpenModal(false);
    setAWGModalOpen(false);
  };

  return (
    <>
      <WppButton
        variant="primary"
        size="m"
        onClick={() => {
          validating.current = true;
          trigger(['customGoal.name', 'customGoal.value', 'customGoal.optimizationDirection']);
          validateFormula(selectedFormula?.formula, setError, validating);
          if (!validating.current && !_.isEmpty(customGoal.formula) && _.isEmpty(errors)) {
            setOpenModal(true);
          }
        }}
      >
        Save
      </WppButton>
      <WppModal open={openModal && _.isEmpty(errors)}>
        <div slot="header">
          <WppTypography type="xl-heading" tag="p">Confirm Formula</WppTypography>
        </div>
        <div slot="body">
          <WppTypography type="s-body" tag="p">Please confirm the details of your goal.</WppTypography>
          <SubHeader label="Goal Name">
            <WppTypography type="m-strong" tag="p">{customGoal?.name}</WppTypography>
          </SubHeader>
          <SubHeader label="Formula">
            <WppTypography type="m-strong" tag="p">{getFormulaText(customGoal?.formula, formulaMetrics)}</WppTypography>
          </SubHeader>
          <SubHeader label="Optimization Direction">
            <WppTypography type="m-strong" tag="p">{optDirection}</WppTypography>
          </SubHeader>
        </div>
        <div slot="actions" style={confirmModalActionsBtn}>
          <WppButton variant="secondary" size="s" onClick={() => setOpenModal(false)} style={cancelBtnStyle}>Edit</WppButton>
          <WppButton variant="primary" size="s" onClick={handleConfirm}>Confirm</WppButton>
        </div>
      </WppModal>
    </>
  );
};

export default ConfirmationModal;
