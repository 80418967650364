import _ from 'lodash';
import React from 'react';
import { WppCard, WppTypography, WppIconTrendAscend, WppIconTrendDescent, WppListItem } from 'buildingBlocks';
import DspIcon from 'components/DspIcon';
import { DSP } from 'constantsBase';
import { GrabFormulaFilters } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { GoalData } from 'containers/StrategyWizard/steps/GoalSelection/types';
import { isCYODGoalTypeValue } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { OptimizationDirection } from 'containers/StrategyWizard/types';
import { getTransformedEquation } from 'containers/StrategyWizard/utils';

type StrategyGoalCardProps = {
  goal: GoalData
  selectedFilter: GrabFormulaFilters
};

type GetGoalDataDivProps = {
  goalName: string
  equation: string
};

type GetGoalLongTextProps = {
  longText: string
  direction: OptimizationDirection
};

const { stratGoalDetails, optimizationDirectionColor, brandColor, goalCardLongText, grabFormulaInnerContainer, grabFormulaNameStyle, grabFormulaStrategyNameStyle } = FORMULA_SECTION_STYLES;

const GoalDataDiv = ({ goalName, equation }: GetGoalDataDivProps) => (
  <>
    <WppTypography tag="p" type="m-strong" slot="header">{goalName}</WppTypography>
    <WppListItem className="grab-formula-list-item" style={grabFormulaNameStyle}>
      <WppTypography slot="label" tag="p" type="s-body">{equation}</WppTypography>
    </WppListItem>
  </>
);

const GoalLongText = ({ longText, direction }: GetGoalLongTextProps) => (
  <div style={goalCardLongText}>
    {direction === OptimizationDirection.down
      ? <WppIconTrendDescent style={optimizationDirectionColor} />
      : <WppIconTrendAscend style={optimizationDirectionColor} />}
    <WppTypography tag="p" type="xs-body">{longText}</WppTypography>
  </div>
);

const StrategyGoalCard = ({ goal, selectedFilter }: StrategyGoalCardProps) => {
  const { brandName, equation, memberName, strategyName, dsp, direction, longText, displayName, name } = goal;
  const isCyodGoalType = isCYODGoalTypeValue(name);
  const goalName = isCyodGoalType ? _.startCase(name).replace(/\s/g, ' ') : displayName;
  const transformedEquation = getTransformedEquation(equation);

  return (
    <WppCard
      value={goalName}
    >
      <GoalDataDiv goalName={goalName} equation={transformedEquation} />
      {GrabFormulaFilters.users === selectedFilter
      && (
        <div style={grabFormulaInnerContainer}>
          <WppListItem className="grab-formula-list-item" style={grabFormulaStrategyNameStyle}><WppTypography slot="label" tag="p" type="s-midi">{strategyName}</WppTypography></WppListItem>
          <div style={stratGoalDetails}>
            {_.size(brandName) ? <WppTypography tag="span" type="s-midi" style={brandColor}>{brandName}</WppTypography> : null}
            {_.toNumber(dsp) !== DSP.MULTIPLE.id && <WppTypography tag="span" type="s-midi">{_.upperCase(memberName)}</WppTypography>}
            <DspIcon dspId={_.toNumber(dsp)} />
          </div>
        </div>
      )}
      {GrabFormulaFilters.copilot === selectedFilter
      && (
        <GoalLongText
          longText={longText}
          direction={direction}
        />
      )}
    </WppCard>
  );
};

export default StrategyGoalCard;
