import _ from 'lodash';
import React from 'react';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { WppTypography } from 'buildingBlocks';

type AWGMetric = {
  isWeighted: boolean
  dimension: string
  weighting: object
  name: string
};

type FormulaMetricProps = {
  metric: AWGMetric
};

const FormulaMetric = (props: FormulaMetricProps) => {
  const { metric } = props;
  const metricName = _.startCase(metric?.name);
  return (
    <>
      {metric?.isWeighted
        ? (
          <div style={FORMULA_SECTION_STYLES.weightedBorderStyle}>
            <WppTypography type="s-midi" style={FORMULA_SECTION_STYLES.weightStyles}>Weighted</WppTypography>
            <WppTypography type="s-midi" style={FORMULA_SECTION_STYLES.weightedMetricStyle}>{metricName}</WppTypography>
          </div>
        )
        : <WppTypography type="s-midi" style={FORMULA_SECTION_STYLES.unWeightedStyle}>{metricName}</WppTypography>}
    </>
  );
};

export default FormulaMetric;
