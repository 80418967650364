import _ from 'lodash';
import React from 'react';
import { WppGrid, WppTypography } from 'buildingBlocks';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { ALL_METRICS } from 'containers/StrategyWizard/constants';
import { getDropzoneContent } from 'containers/StrategyWizard/utils';
import DraggableComponent from './DraggableComponent';

const { metricsColumn, metricsContainer } = FORMULA_SECTION_STYLES;

const MetricsSection = () => (
  <WppGrid direction="column" item all={20} style={metricsColumn}>
    <WppTypography type="s-strong" tag="p">Metrics</WppTypography>
    <div style={metricsContainer}>
      {_.map(ALL_METRICS, (metric) => (
        <DraggableComponent key={metric} dragContent={getDropzoneContent(metric)} />
      ))}
    </div>
  </WppGrid>
);

export default MetricsSection;
