import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { WppTab, WppTabs } from 'buildingBlocks';
import { SelectedTab } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { ATTACH_DSP_OBJECT_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { WizardFormAttachFlights } from 'containers/StrategyWizard/types';
import { WppTabsCustomEvent, TabsChangeEventDetail } from 'utils/types';

type Props = {
  menuItemOneName: string
  menuItemTwoName: string
  setSelectedTab: (arg: any) => void
};

export const FlightMenuSelector = (props: Props) => {
  const {
    menuItemOneName,
    menuItemTwoName,
    setSelectedTab,
  } = props;

  const [activeItem, setActiveItem] = useState<string>(menuItemOneName);
  const advertiser = useWatch<WizardFormAttachFlights>({ name: 'advertiser' });

  useEffect(() => {
    setActiveItem(menuItemOneName);
  }, [menuItemOneName]);

  const handleItemClick = (_e, { name }) => {
    if (name === SelectedTab.bulkUploader) {
      setSelectedTab(SelectedTab.bulkUploader);
    } else {
      setSelectedTab(SelectedTab.selectLineItemCampaign);
    }
    setActiveItem(name);
  };

  return (
    <WppTabs
      value={activeItem}
      style={ATTACH_DSP_OBJECT_STYLES.flightMenuSelectorTabs}
      onWppChange={(event: WppTabsCustomEvent<TabsChangeEventDetail>) => {
        handleItemClick(event, { name: event.detail.value });
      }}
    >
      <WppTab value={menuItemOneName}>{menuItemOneName}</WppTab>
      <WppTab value={menuItemTwoName} disabled={!advertiser}>{menuItemTwoName}</WppTab>
    </WppTabs>
  );
};

export default FlightMenuSelector;
