import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React, { MutableRefObject } from 'react';
import { Icon, Loader, WppGrid, WppTable, WppTableBodyCell, WppTableBodyRow, WppTableHeader, WppTableHeaderCell, WppTableHeaderRow, WppTypography } from 'buildingBlocks';
import { GoalSuccessEvent, MetricsFormattingConstants } from 'constantsBase';
import Tooltip from 'containers/Tooltip';
import { BudgetTypes } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { displayDate } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { Flight } from 'utils/types';
import { BUDGET_INTERVALS, BUDGET_SECTION_STYLES } from '../styles';
import { useGoalSectionContext } from '../contexts/GoalSectionProvider';
import RevenueTypeConfiguration from './RevenueTypeSection/RevenueTypeConfiguration';
import { getRevTypeToolTipContent } from '../utils';

const { tooltipIcon } = BUDGET_SECTION_STYLES;
const { activeBullet, futureIntervalTextStyle, singleBudgDateHeader, singleBudgDelivHeader, singleBudgetdeli, revenuecalc, budgetIntervalListStyle } = BUDGET_INTERVALS;

type BudgetIntervalProps = {
  startDate: string
  endDate: string
  budgetAmount: number
  showLabels: boolean
  initialFormValues: WizardFormGoalSelection
  resetConfirmedGoal: Function
  finishCalculations: MutableRefObject<boolean>
  delivery?: number
  budgetImps?: number
};

const formatBudget = (budgetAmount: number, budgetType: string, budgetImps: number) => {
  if (budgetType === BudgetTypes.imps) {
    return numeral(budgetImps).format(MetricsFormattingConstants.NO_DECIMALS);
  }
  return numeral(budgetAmount).format(MetricsFormattingConstants.TWO_DECIMALS);
};

const formatBudgetUnit = (currency: string, budgetType: string) => {
  if (budgetType === BudgetTypes.imps) {
    return _.upperCase(GoalSuccessEvent.impressions);
  }
  return currency;
};

const BudgetIntervalHeader = ({ toolTipContent, hasRevTypePermission }: { toolTipContent: string, hasRevTypePermission: boolean }) => (
  <WppTableHeader>
    <WppTableHeaderRow>
      <WppTableHeaderCell style={budgetIntervalListStyle} />
      <WppTableHeaderCell style={singleBudgDateHeader}>
        <WppTypography type="s-strong" tag="p">Start Date</WppTypography>
      </WppTableHeaderCell>
      <WppTableHeaderCell style={singleBudgDateHeader}>
        <WppTypography type="s-strong" tag="p">End Date</WppTypography>
      </WppTableHeaderCell>
      <WppTableHeaderCell style={singleBudgetdeli}>
        <WppTypography type="s-strong" tag="p">Budget</WppTypography>
      </WppTableHeaderCell>
      <WppTableHeaderCell style={singleBudgDelivHeader}>
        <WppTypography type="s-strong" tag="p">Delivered</WppTypography>
      </WppTableHeaderCell>
      {hasRevTypePermission && (
        <WppTableHeaderCell colSpan={1}>
          <div style={{ display: 'flex' }}>
            <WppTypography type="s-strong" tag="p" style={revenuecalc}>Revenue Calculation</WppTypography>
            <Tooltip content={toolTipContent} showHelpIcon={hasRevTypePermission} tooltipstyling={tooltipIcon} />
          </div>
        </WppTableHeaderCell>
      )}
    </WppTableHeaderRow>
  </WppTableHeader>
);

const BudgetInterval = ({
  endDate, startDate, budgetAmount, showLabels, delivery,
  budgetImps, initialFormValues, resetConfirmedGoal, finishCalculations,
}: BudgetIntervalProps) => {
  const {
    currencyCode: currency, isImpsBudgetType, hasAmznFlights, budgetType, strategyId, hasRevTypePermission, revenueTypeEnabled,
    reduxWizardFormValues: {
      attachFlightsStep: { attachedFlights },
      budgetAllocationState,
    },
  } = useGoalSectionContext();
  const flight: Flight = _.head(attachedFlights);
  const { timezone, externalType: flightExtType, externalId: flightExtId } = flight;
  const budgetData = _.get(budgetAllocationState, 'data');
  const cumDataLatest = _.last(_.get(budgetData, `${flightExtId}.parentData.cumData`));
  const deliveryInImps = _.get(cumDataLatest, 'imps');
  const intervalActive = moment(startDate).isBefore(moment(), 'day');
  const formattedDelivery = _.isNumber(delivery) && numeral(delivery).format(MetricsFormattingConstants.NO_DECIMALS);
  const toolTipContent = getRevTypeToolTipContent(isImpsBudgetType, hasAmznFlights);
  const startDateTodayOrPast = moment(startDate).isSameOrBefore(moment(), 'day');
  const revenueCalculationText = revenueTypeEnabled ? 'Customize' : 'Platform Reported';

  const getDelivery = () => {
    if (!startDateTodayOrPast) return 'Scheduled';
    if (!_.isNumber(delivery) || !intervalActive) return '0';
    if (!finishCalculations.current && strategyId) return <Loader size="mini" inline active />;
    if (budgetType === BudgetTypes.imps) {
      return `${numeral(deliveryInImps).format(MetricsFormattingConstants.NO_DECIMALS)} ${_.upperCase(GoalSuccessEvent.impressions)}`;
    }
    return `${formattedDelivery} ${currency}`;
  };

  return (
    <WppGrid>
      <WppTable>
        {showLabels && <BudgetIntervalHeader toolTipContent={toolTipContent} hasRevTypePermission={hasRevTypePermission} />}
        <WppTableBodyRow>
          <WppTableBodyCell>
            {startDateTodayOrPast && <Icon name="circle" size="small" style={activeBullet} />}
          </WppTableBodyCell>
          <WppTableBodyCell>
            <WppTypography type="s-body" tag="p">{displayDate(startDate, flightExtType, timezone)}</WppTypography>
          </WppTableBodyCell>
          <WppTableBodyCell>
            <WppTypography type="s-body" tag="p">{displayDate(endDate, flightExtType, timezone)}</WppTypography>
          </WppTableBodyCell>
          <WppTableBodyCell style={singleBudgetdeli}>
            <WppTypography type="s-body" tag="p">{formatBudget(budgetAmount, budgetType, budgetImps)} {formatBudgetUnit(currency, budgetType)}</WppTypography>
          </WppTableBodyCell>
          <WppTableBodyCell style={singleBudgDelivHeader}>
            {getDelivery()}
          </WppTableBodyCell>
          {hasRevTypePermission && (
            <WppTableBodyCell style={{ width: '212px' }}>
              {!showLabels
                ? <div style={futureIntervalTextStyle}>{revenueCalculationText}</div>
                : (
                  <RevenueTypeConfiguration
                    initialFormValues={initialFormValues}
                    resetConfirmedGoal={resetConfirmedGoal}
                  />
                )}
            </WppTableBodyCell>
          )}
        </WppTableBodyRow>
      </WppTable>
    </WppGrid>
  );
};

export default BudgetInterval;
