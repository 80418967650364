import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext, useWatch } from 'react-hook-form';
import { WppButton, WppTypography, WppLabel, WppSideModal, WppIconPlus } from 'buildingBlocks';
import { AttachFlightsInfoType, ModalSessionFlightsInfoType, WizardFormAttachFlights, WizardFormValues } from 'containers/StrategyWizard/types';
import { BUDGET_ALLOCATION_SUPPORTED_EXT_TYPES, Status } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { fetchBudgetAllocationState } from 'containers/StrategyWizard/utils';
import { GlobalState } from 'reducers';
import { Flight } from 'utils/types';
import AttachFlightsModalForm from './AttachFlightsModal/AttachFlightsModalForm';
import { ATTACH_FLIGHTS_STEP_INITIAL_VALUES, modalSessionFlightsInfoInitialState, OptimizationType } from '../constants';
import { ATTACH_DSP_OBJECT_STYLES, MODAL_STYLES } from '../styles';
import { configuringCrossPlatformStratCheck } from '../utils';

type Props = {
  modalIsOpen: boolean
  setModalIsOpen: (x: boolean) => void
  strategyId?: number
  attachFlightsInfo: AttachFlightsInfoType
  setAttachFlightsInfo: (x: any) => void
  selectedOptType: OptimizationType
  flightLimitReached: boolean
  defaultCurrencyId: number | null
  setDefaultCurrencyId: (x: any) => void
  memAdvBrandFilter: Array<{ memberId: number, advertiserId: number }> | null
  setMemAdvBrandFilter: (x: any) => void
};

const AttachFlightsModal = (props: Props) => {
  const [modalSessionFlightsInfo, setModalSessionFlightsInfo] = useState<ModalSessionFlightsInfoType>(modalSessionFlightsInfoInitialState);
  const [sessionAttachFlights, setSessionAttachFlights] = useState<Array<Flight>>([]);
  const dispatch = useDispatch();
  const {
    strategyTypeSelectionStep,
    budgetAllocationState,
  } = useSelector<GlobalState>((state) => state.strategyWizard) as WizardFormValues;

  const stratTypeId = _.get(strategyTypeSelectionStep, 'strategyType.id');
  const disableModalTrigger = budgetAllocationState.kind === Status.loading;
  const { setValue, reset } = useFormContext<WizardFormAttachFlights>();
  const attachedFlights = useWatch({ name: 'attachedFlights' });
  const member = useWatch({ name: 'member' });
  const advertiser = useWatch({ name: 'advertiser' });
  const brand = useWatch({ name: 'brand' });

  const {
    modalIsOpen,
    setModalIsOpen,
    strategyId,
    attachFlightsInfo,
    setAttachFlightsInfo,
    selectedOptType,
    flightLimitReached,
    defaultCurrencyId,
    setDefaultCurrencyId,
    memAdvBrandFilter,
    setMemAdvBrandFilter,
  } = props;

  const { ineligibleFlights } = attachFlightsInfo;
  const reactivatedFlights = _.get(attachFlightsInfo, 'reactivatedFlights', []);
  const toBeDeactivated = _.get(attachFlightsInfo, 'toBeDeactivated', []);
  const deactivatedFlights = _.get(attachFlightsInfo, 'deactivatedFlights', []);
  const {
    attachedToAnotherStrategy,
    eligibleFlights,
    flightsStatus,
    attachedToThisStrategy,
  } = modalSessionFlightsInfo;

  const configuringCrossPlatformStrat = configuringCrossPlatformStratCheck(selectedOptType);
  const noFlightsAttached = _.isEmpty(attachedFlights);
  const disabled = attachedToAnotherStrategy.length > 0
    || (noFlightsAttached && _.isEmpty([...eligibleFlights, ...attachedToThisStrategy]));

  useEffect(() => {
    if ((!brand || !configuringCrossPlatformStrat) && noFlightsAttached) {
      setSessionAttachFlights([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member, advertiser]);

  const handleModalNextBtnClick = () => {
    // Edge Case: sync flights in the case where user detached flight then tries to re-add flight via modal.
    let newToBeDetached = [...attachFlightsInfo.toBeDetached];
    const newAttachedToThisStrategy = [...attachFlightsInfo.attachedToThisStrategy];

    // remove content key which displays the DSP icon
    const cleanedEligibleFlights = _.map(eligibleFlights, (eligFlight) => _.omit(eligFlight, 'content'));
    // concat both currently attached eligible flights and eligible flights from modal session.
    const uniqEligibleFlights = _.uniqBy(_.concat(attachFlightsInfo.eligibleFlights, cleanedEligibleFlights), 'externalId');
    const toBeDetachedExtIds = _.map(attachFlightsInfo.toBeDetached, (f) => f.externalId);

    if (attachedToThisStrategy.length > 0) {
      _.forEach(attachedToThisStrategy, (flight) => {
        if (_.includes(toBeDetachedExtIds, flight.externalId)) {
          const flightToReattach = _.find(newToBeDetached, (f) => f.externalId === flight.externalId);
          newAttachedToThisStrategy.push(flightToReattach);
          newToBeDetached = _.filter(newToBeDetached, (f) => f.externalId !== flight.externalId);
        }
      });
    }

    const combineModalSessionAndAttachFlightsInfo = {
      eligibleFlights: uniqEligibleFlights,
      flightsStatus: { ...flightsStatus, ...attachFlightsInfo.flightsStatus },
      toBeDetached: newToBeDetached,
      attachedToThisStrategy: newAttachedToThisStrategy,
    };

    // concat to ensure that the correct flights are maintained within attachedFlights field depending on strat type
    const newAttachedFlights = _.concat(
      uniqEligibleFlights,
      newAttachedToThisStrategy,
      ineligibleFlights,
      ...(configuringCrossPlatformStrat ? _.concat(reactivatedFlights, toBeDeactivated, deactivatedFlights) : []),
    );
    setValue('attachedFlights', newAttachedFlights);
    setAttachFlightsInfo({ ...attachFlightsInfo, ...combineModalSessionAndAttachFlightsInfo });
    setModalSessionFlightsInfo(modalSessionFlightsInfoInitialState);
    setSessionAttachFlights([]);
    setModalIsOpen(false);
    // reset the budget allocation state based on new flights
    if (_.includes(BUDGET_ALLOCATION_SUPPORTED_EXT_TYPES, _.get(_.head(newAttachedFlights), 'externalType'))) {
      fetchBudgetAllocationState(newAttachedFlights, _.get(budgetAllocationState, 'data'), dispatch, stratTypeId);
    }
  };

  const clearSelections = () => {
    setModalSessionFlightsInfo(modalSessionFlightsInfoInitialState);
    setSessionAttachFlights([]);
    // reset the form, default currency, and mem/adv brand filters if in create mode and user decides to close the modal after making selections
    if (!strategyId && noFlightsAttached) {
      reset(ATTACH_FLIGHTS_STEP_INITIAL_VALUES);
      setMemAdvBrandFilter(null);
      setDefaultCurrencyId(null);
    }
  };

  const handleModalClose = () => {
    clearSelections();
    setModalIsOpen(false);
  };

  const disableClearSelectionsBtn = !brand && !member && !advertiser;
  const filterDesc = configuringCrossPlatformStrat
    ? 'Apply a brand or advertiser filter to select objects below.'
    : 'Apply a member and advertiser filter to select objects below.';

  return (
    <>
      {!flightLimitReached && (
        <WppButton variant="secondary" style={ATTACH_DSP_OBJECT_STYLES.button} disabled={disableModalTrigger} onClick={() => setModalIsOpen(true)}>
          <WppIconPlus slot="icon-start" /> Attach Platform Object
        </WppButton>
      )}
      { modalIsOpen && (
      <WppSideModal
        open={modalIsOpen}
        size="l"
        onWppSideModalClose={handleModalClose}
        onWppSideModalOpen={() => setModalIsOpen(true)}
        disableOutsideClick
        id="attach-flights-modal"
      >
        <WppTypography type="2xl-heading" tag="h3" slot="header">Object Attachment</WppTypography>
        <div slot="body">
          <div style={MODAL_STYLES.contentHeaderDiv}>
            <div style={MODAL_STYLES.contentHeader}>
              <WppLabel
                htmlFor="member"
                typography="s-strong"
                config={{
                  text: 'Filter',
                }}
              />
              <WppTypography type="s-body" className="attach-object-side-model-typography">{filterDesc}</WppTypography>
            </div>
            {(_.isNil(strategyId) && noFlightsAttached) && (
            <WppButton variant="secondary" size="s" onClick={clearSelections} disabled={disableClearSelectionsBtn}>
              Clear Selections
            </WppButton>
            )}
          </div>
          <AttachFlightsModalForm
            strategyId={strategyId}
            selectedOptType={selectedOptType}
            modalSessionFlightsInfo={modalSessionFlightsInfo}
            setModalSessionFlightsInfo={setModalSessionFlightsInfo}
            sessionAttachFlights={sessionAttachFlights}
            setSessionAttachFlights={setSessionAttachFlights}
            memAdvBrandFilter={memAdvBrandFilter}
            setMemAdvBrandFilter={setMemAdvBrandFilter}
            defaultCurrencyId={defaultCurrencyId}
            setDefaultCurrencyId={setDefaultCurrencyId}
          />
        </div>
        <div slot="actions" style={MODAL_STYLES.actionButtonContainer}>
          <WppButton
            variant="secondary"
            onClick={handleModalClose}
          >
            Cancel
          </WppButton>
          <WppButton
            type="submit"
            onClick={handleModalNextBtnClick}
            disabled={disabled}
          >
            Attach
          </WppButton>
        </div>
      </WppSideModal>
      )}
    </>
  );
};

export default AttachFlightsModal;
