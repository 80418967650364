/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import _ from 'lodash';
import moment from 'moment';
import React, { useState, useEffect, SetStateAction, Dispatch, useRef, MutableRefObject } from 'react';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import { Icon, WppButton, WppGrid, WppIconPlus, WppInlineMessage, WppTable, WppTableHeader, WppTableHeaderCell, WppTableHeaderRow, WppTypography } from 'buildingBlocks';
import { GOAL_VALUE_TYPE } from 'constantsBase';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { getTotalDelivered } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import Tooltip from 'containers/Tooltip';
import CrossPlatformBudgetInterval from './CrossPlatformBudgetInterval';
import { BUDGET_INTERVALS } from '../styles';
import { getGoalTargetProps } from '../GoalSection/GoalConfiguration';
import { CrossPlatformBudgetSettingsValidationType, getRevTypeToolTipContent, validateCrossPlatformBudgetSettings as validate } from '../utils';
import { BudgetSettingsType } from '../types';
import { useGoalSectionContext } from '../contexts/GoalSectionProvider';

const MAX_INTERVAL_LIMIT = 12;
const MIN_INTERVAL_LIMIT = 3;
const {
  addBudgetIntervalButton, tooltipIcon, noIntervalsMessage, crossPlatBudgGrid, dateHeader, budgetHeader, budgetHeaderNoDelivery, deliveryHeader,
  showIntervalsContainer, showIntervalsText, showIntervalsIcon, budgetIntervalListStyle, revenueCalc, crossNoBudgIntervalMsgBtnDiv,
} = BUDGET_INTERVALS;

type CrossPlatformBudgetSettingsProps = {
  crossPlatformBudgetSettingsValidation: CrossPlatformBudgetSettingsValidationType
  setCrossPlatformBudgetSettingsValidation: Dispatch<SetStateAction<CrossPlatformBudgetSettingsValidationType>>
  initialFormValues: WizardFormGoalSelection
  resetConfirmedGoal: Function
  finishCalculations: MutableRefObject<boolean>
};

const CrossPlatformBudgetSettings = (props: CrossPlatformBudgetSettingsProps) => {
  const {
    crossPlatformBudgetSettingsValidation, setCrossPlatformBudgetSettingsValidation,
    initialFormValues, resetConfirmedGoal,
    finishCalculations,
  } = props;
  const {
    currencyCode: currency, isImpsBudgetType, strategyId, hasRevTypePermission, hasAmznFlights, reduxWizardFormValues: { budgetAllocationState },
  } = useGoalSectionContext();

  const budgetData = _.get(budgetAllocationState, 'data');

  const { control } = useFormContext();
  const formBudgetSettings = useWatch({ name: 'budgetSettings' });
  const revTypeConfig = useWatch({ name: 'budget' });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'budgetSettings',
    shouldUnregister: false,
  });
  const [budgetSettings, setBudgetSettings] = useState<BudgetSettingsType>(_.get(initialFormValues, 'budgetSettings', {}));
  const [showAllBudgetIntervals, setShowAllBudgetIntervals] = useState<boolean>(false);
  const budgetSettingsLength = fields.length;

  const editedBudgetIndex = useRef(new Set());

  const goalTargetProps = getGoalTargetProps(GOAL_VALUE_TYPE.CURRENCY, currency);

  const delivery = (strategyId && !_.isEmpty(budgetData)) && getTotalDelivered(budgetData, _.values(budgetSettings), revTypeConfig, null, finishCalculations);

  useEffect(() => {
    if (formBudgetSettings) {
      setBudgetSettings(_.assign({}, formBudgetSettings));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formBudgetSettings]);

  useEffect(() => {
    setCrossPlatformBudgetSettingsValidation(validate(formBudgetSettings));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formBudgetSettings]);

  const selectedDate = (index: number) => {
    if (moment(budgetSettings[index]?.startDate).isAfter(moment(budgetSettings[index]?.endDate))) {
      return budgetSettings[index]?.startDate;
    }
    return budgetSettings[index]?.endDate;
  };

  // change the budget intervals displayed based on showAllBudgetIntervals
  const checkEndDateActiveIntervals = _.filter(budgetSettings, (interval) => moment(interval.endDate).isSameOrAfter(moment(), 'day'));
  const checkInActiveIntervals = _.filter(budgetSettings, (interval) => moment(interval.endDate).isBefore(moment(), 'day'));
  const budgetIntervalLimit = checkEndDateActiveIntervals.length <= MIN_INTERVAL_LIMIT ? MIN_INTERVAL_LIMIT : checkEndDateActiveIntervals.length;
  let intervals = fields;
  if (fields.length > budgetIntervalLimit && !showAllBudgetIntervals) {
    const startIndex = fields.length - budgetIntervalLimit;
    intervals = fields
      .reduce((obj, val, key) => {
        if (+key >= startIndex) {
          // eslint-disable-next-line no-param-reassign
          obj[key] = val;
        }
        return obj;
      }, []);
  }
  const displayIntervalsBtn = (intervals.length > MIN_INTERVAL_LIMIT) && (checkInActiveIntervals.length >= 1);
  const toolTipContent = getRevTypeToolTipContent(isImpsBudgetType, hasAmznFlights);
  const dateRangeErr = (intervals.length > 1 && crossPlatformBudgetSettingsValidation.rangeOverlap ? 'Budget Intervals cannot overlap.' : '');
  return (
    <WppGrid container fullWidth>
      <WppGrid direction="row" style={crossPlatBudgGrid}>
        {!!intervals.length && (
          <WppTable>
            <WppTableHeader>
              <WppTableHeaderRow>
                {strategyId && <WppTableHeaderCell style={budgetIntervalListStyle} />}
                <WppTableHeaderCell style={dateHeader} className={strategyId && 'dateHeaderNoDelivery'}>
                  <WppTypography type="s-strong" tag="p">Start Date</WppTypography>
                </WppTableHeaderCell>
                <WppTableHeaderCell style={dateHeader} className={strategyId && 'dateHeaderNoDelivery'}>
                  <WppTypography type="s-strong" tag="p">End Date</WppTypography>
                </WppTableHeaderCell>
                <WppTableHeaderCell style={strategyId ? budgetHeader : budgetHeaderNoDelivery}>
                  <WppTypography type="s-strong" tag="p">Budget</WppTypography>
                </WppTableHeaderCell>
                {strategyId && (
                  <WppTableHeaderCell style={deliveryHeader} colSpan={1}>
                    <WppTypography type="s-strong" tag="p">Delivery</WppTypography>
                  </WppTableHeaderCell>
                )}
                {hasRevTypePermission && (
                  <WppTableHeaderCell colSpan={1}>
                    <div style={{ display: 'flex' }}>
                      <WppTypography type="s-strong" tag="p" style={revenueCalc}>Revenue Calculation</WppTypography>
                      <Tooltip content={toolTipContent} showHelpIcon={hasRevTypePermission} tooltipstyling={tooltipIcon} />
                    </div>
                  </WppTableHeaderCell>
                )}
                <WppTableHeaderCell />
              </WppTableHeaderRow>
            </WppTableHeader>
            {_.size(budgetSettings) && intervals.map((item, index) => (
              <CrossPlatformBudgetInterval
                key={item.id}
                index={index}
                currency={currency}
                budgetSettings={budgetSettings}
                delivery={delivery}
                editedBudgetIndex={editedBudgetIndex}
                selectedDate={selectedDate}
                goalTargetProps={goalTargetProps}
                remove={remove}
                initialFormValues={initialFormValues}
                resetConfirmedGoal={resetConfirmedGoal}
                finishCalculations={finishCalculations}
              />
            ))}
          </WppTable>
        )}
        {dateRangeErr && <WppInlineMessage size="s" message={dateRangeErr} type="error" />}
      </WppGrid>
      <div style={crossNoBudgIntervalMsgBtnDiv}>
        {!intervals.length && (
          <div style={noIntervalsMessage}>
            <WppInlineMessage
              size="s"
              message="There are no active or upcoming intervals for the attached objects."
              type="information"
            />
          </div>
        )}
        <>
          <WppButton
            variant="secondary"
            size="s"
            style={addBudgetIntervalButton}
            disabled={budgetSettingsLength === MAX_INTERVAL_LIMIT}
            onClick={() => {
              append({ startDate: '', endDate: '', type: 'amount', newlyAdded: true, budget: null });
            }}
          >
            <WppIconPlus slot="icon-start" />
            Add Budget Intervals
          </WppButton>
        </>
      </div>
      {displayIntervalsBtn && (
        <div
          style={showIntervalsContainer}
          onClick={() => setShowAllBudgetIntervals(!showAllBudgetIntervals)}
          role="button"
          tabIndex={-1}
        >
          <WppTypography type="s-body" style={showIntervalsText} tag="span">
            {showAllBudgetIntervals ? 'Hide budget intervals' : 'Show all budget intervals'}
          </WppTypography>
          <Icon
            style={showIntervalsIcon}
            name={`chevron ${showAllBudgetIntervals ? 'up' : 'down'}`}
          />
        </div>
      )}
    </WppGrid>
  );
};

export default CrossPlatformBudgetSettings;
