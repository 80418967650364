import _ from 'lodash';
import { DSP, FLIGHT_EXTERNAL_TYPE } from 'constantsBase';
import { buildFlightName, isCrossPlatformStrategyType, isHigherOrderExternalType, multipleParentFlightsCheck, MAX_NAME_LENGTH } from 'containers/StrategyWizard/utils';
import { Advertiser, Brand, Flight } from 'utils/types';
import { Advertiser as AdvertiserAPI, AdvertiserBrand, Brand as BrandAPI, Member } from 'utils/copilotAPI';
import { EXTERNAL_TYPE_TO_DSP_AND_DEFAULT_OPT_LEVEL, OptimizationType } from './constants';
import { isBudgetOptimization } from '../StrategyConfirmation/utils';

export const configuringCrossPlatformStratCheck = (selectedOptType: OptimizationType) => _.isEqual(selectedOptType, OptimizationType.crossPlatform);

export const configuringCampaignStratCheck = (selectedOptType: OptimizationType) => _.isEqual(selectedOptType, OptimizationType.campaign);

export const configuringLineItemStratCheck = (selectedOptType: OptimizationType) => _.isEqual(selectedOptType, OptimizationType.lineItem);

export const getSingleFlightNameAsStrategyName = (flightsSelected: Array<Flight>): string => buildFlightName(_.head(flightsSelected));

export const getCrossPlatformStrategyName = (flightsSelected: Array<Flight>, brandOrAdv: Brand | Advertiser): string => {
  const baseCrossPlatformStratName = `${_.get(brandOrAdv, 'name')} |`;
  const flightExtTypes = _.uniq(_.map(flightsSelected, 'externalType'));
  const getExtIdsBasedOnExtType = (extType: number) => _.map(_.filter(flightsSelected, (f) => f.externalType === extType), 'externalId');
  const flightsArrByExtType = _.map(flightExtTypes, getExtIdsBasedOnExtType);
  const flightExtTypeToFlightExtIds = _.zipObject(flightExtTypes, flightsArrByExtType);
  const sortedDspToRespectiveFlights = _.orderBy(_.map(flightExtTypes, (extType: number) => {
    const dspCode = _.get(DSP.getById(EXTERNAL_TYPE_TO_DSP_AND_DEFAULT_OPT_LEVEL[extType].dsp), 'code');
    const flightExtTypeName = _.get(FLIGHT_EXTERNAL_TYPE.getById(extType), 'wizardAbbreviation');
    return `${dspCode} ${flightExtTypeName} [${_.join(flightExtTypeToFlightExtIds[extType], ', ')}]`;
  }));
  const strategyName = `${baseCrossPlatformStratName} ${_.join(sortedDspToRespectiveFlights, ' | ')}`;
  return _.size(strategyName) > MAX_NAME_LENGTH ? strategyName.slice(0, MAX_NAME_LENGTH) : strategyName;
};

export const getOptimizationType = (strategyTypeId: number, attachedFlights: Array<Flight>): OptimizationType => {
  if (!strategyTypeId && _.isEmpty(attachedFlights)) {
    return null;
  }
  if (isCrossPlatformStrategyType(strategyTypeId) || multipleParentFlightsCheck(attachedFlights)) {
    return OptimizationType.crossPlatform;
  }
  if (isBudgetOptimization(strategyTypeId) || isHigherOrderExternalType(_.get(_.head(attachedFlights), 'externalType'))) {
    return OptimizationType.campaign;
  }
  return OptimizationType.lineItem;
};

export const getBrand = async (advertiserId: number) => {
  try {
    const brandsArrRes = await AdvertiserBrand.brandsForAdvertiser({ advertiserId });
    const brandId = _.head(brandsArrRes.data);
    if (brandId) {
      const brandRes = await BrandAPI.get({ id: brandId, populate: 'advertisers', limit: 1, isDeleted: false });
      const brandObj = _.head(brandRes.data) as Brand;
      return brandObj;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getMemAdv = async (flight: Flight) => {
  try {
    const memRes = await Member.get({ where: { id: _.get(flight, 'member') }, enabled: 1 });
    const advRes = await AdvertiserAPI.get({ where: { id: _.get(flight, 'advertiser') }, enabled: 1 });
    return { flightMem: _.head(memRes.data), flightAdv: _.head(advRes.data) };
  } catch (error) {
    console.error(error);
    return null;
  }
};
