import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { useWatch } from 'react-hook-form';
import { GlobalState } from 'reducers';
import { WppGrid } from 'buildingBlocks';
import { ModalSessionFlightsInfoType } from 'containers/StrategyWizard/types';
import { OptimizationType } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { configuringLineItemStratCheck, configuringCrossPlatformStratCheck } from 'containers/StrategyWizard/steps/AttachFlights/utils';
import { Flight, StrategyType } from 'utils/types';
import MemberFormField from './MemberFormField';
import AdvertiserFormField from './AdvertiserFormField';
import BrandFormField from './BrandFormField';
import AttachFlights from './AttachFlights';

type AttachFlightsModalFormProps = {
  selectedOptType: OptimizationType
  modalSessionFlightsInfo: ModalSessionFlightsInfoType
  setModalSessionFlightsInfo: (x: any) => void
  sessionAttachFlights: Array<Flight>
  setSessionAttachFlights: (x: any) => void
  memAdvBrandFilter: Array<{ memberId: number, advertiserId: number }> | null
  setMemAdvBrandFilter: (x: any) => void
  defaultCurrencyId: number | null
  setDefaultCurrencyId: (x: any) => void
  strategyId?: number
};

const AttachFlightsModalForm = (props: AttachFlightsModalFormProps) => {
  const {
    strategyId,
    selectedOptType,
    modalSessionFlightsInfo,
    setModalSessionFlightsInfo,
    sessionAttachFlights,
    setSessionAttachFlights,
    memAdvBrandFilter,
    setMemAdvBrandFilter,
    defaultCurrencyId,
    setDefaultCurrencyId,
  } = props;
  const selectedStrategyType = useSelector<GlobalState>((rootState) => rootState.strategyWizard.strategyTypeSelectionStep.strategyType) as StrategyType;
  const { loadingFlightCandidates } = modalSessionFlightsInfo;
  const attachedFlights = useWatch({ name: 'attachedFlights' });
  const selectedOptLvl = useWatch({ name: 'optimizationLevel' });
  const hasFlightsAttached = !_.isEmpty(attachedFlights);
  const configuringCrossPlatformStrat = configuringCrossPlatformStratCheck(selectedOptType);
  const configuringLineItemStrat = configuringLineItemStratCheck(selectedOptType);
  // temporarily set flightDisplayName until optLevel is selected
  const getFlightDisplayName = () => {
    if (configuringCrossPlatformStrat) {
      return { single: 'Object', multiple: 'Objects' };
    }
    if (selectedOptLvl) {
      return selectedOptLvl.displayName;
    }
    return {
      single: configuringLineItemStrat ? 'Line Item' : 'Insertion Order',
      multiple: configuringLineItemStrat ? 'Line Items' : 'Insertion Orders',
    };
  };

  return (
    <WppGrid container fullWidth>
      {configuringCrossPlatformStrat && (
        <WppGrid item all={24}>
          <BrandFormField
            strategyId={strategyId}
            hasFlightsAttached={hasFlightsAttached}
            setMemAdvBrandFilter={setMemAdvBrandFilter}
            setModalSessionFlightsInfo={setModalSessionFlightsInfo}
            setSessionAttachFlights={setSessionAttachFlights}
            setDefaultCurrencyId={setDefaultCurrencyId}
          />
        </WppGrid>
      )}
      <WppGrid container item all={24} fullWidth>
        <WppGrid item all={12}>
          <MemberFormField
            selectedOptType={selectedOptType}
            strategyId={strategyId}
            hasFlightsAttached={hasFlightsAttached}
            setModalSessionFlightsInfo={setModalSessionFlightsInfo}
            memAdvBrandFilter={memAdvBrandFilter}
            loadingFlightCandidates={loadingFlightCandidates}
          />
        </WppGrid>
        <WppGrid item all={12}>
          <AdvertiserFormField
            strategyId={strategyId}
            hasFlightsAttached={hasFlightsAttached}
            setModalSessionFlightsInfo={setModalSessionFlightsInfo}
            loadingFlightCandidates={loadingFlightCandidates}
            memAdvBrandFilter={memAdvBrandFilter}
            configuringCrossPlatformStrat={configuringCrossPlatformStrat}
          />
        </WppGrid>
      </WppGrid>
      <AttachFlights
        selectedOptType={selectedOptType}
        flightDisplayName={getFlightDisplayName()}
        modalSessionFlightsInfo={modalSessionFlightsInfo}
        setModalSessionFlightsInfo={setModalSessionFlightsInfo}
        selectedStrategyType={selectedStrategyType}
        sessionAttachFlights={sessionAttachFlights}
        setSessionAttachFlights={setSessionAttachFlights}
        defaultCurrencyId={defaultCurrencyId}
        setDefaultCurrencyId={setDefaultCurrencyId}
        strategyId={strategyId}
      />
    </WppGrid>
  );
};

export default AttachFlightsModalForm;
