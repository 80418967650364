import _ from 'lodash';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { GOAL_SETTINGS, REVENUE_TYPES_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { OptimizationDirection } from 'containers/StrategyWizard/types';
import { WppInlineMessage } from 'buildingBlocks';
import OptimizationOption from './OptimizationOption';

const { optDirectionStyle } = GOAL_SETTINGS;

const OptimizationOptions = () => {
  const { formState: { errors } } = useFormContext();
  return (
    <>
      <div style={{ ...REVENUE_TYPES_STYLES.revenueOptions, ...optDirectionStyle }}>
        {_.map(OptimizationDirection, (optDirection) => (
          <OptimizationOption
            key={optDirection}
            optDirection={optDirection}
          />
        ))}
      </div>
      {_.get(errors, 'customGoal.optimizationDirection')
        && <WppInlineMessage size="s" type="error" message="Select an option for Copilot to Minimize or Maximize the goal value." />}
    </>
  );
};

export default OptimizationOptions;
