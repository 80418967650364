// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.intervalDelivery {
  background-color: rgba(133, 163, 255, 0.1333333333);
}
.intervalDelivery::part(label) {
  color: #0A2FFF;
}`, "",{"version":3,"sources":["webpack://./src/containers/StrategyWizard/steps/GoalSelection/BudgetSection/style.scss"],"names":[],"mappings":"AAOE;EANE,mDAOsB;AAN1B;AAAI;EACE,cAKW;AAHjB","sourcesContent":["@mixin tag($color, $bg-color){\n    background-color: $bg-color;\n    &::part(label){\n      color: $color;\n    }\n  }\n  \n  .intervalDelivery{\n    @include tag(#0A2FFF, #85A3FF22);\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
