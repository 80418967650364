import React from 'react';
import { WppGrid, WppInlineMessage } from 'buildingBlocks';

type ValidationMessagesProps = {
  hasValueErrors: boolean
  hasOutcomeErrors: boolean
  isValueEmpty: boolean
};

const ValidationMessages = (props: ValidationMessagesProps) => {
  const { hasOutcomeErrors, hasValueErrors, isValueEmpty } = props;

  return (
    <WppGrid>
      {hasOutcomeErrors && !hasValueErrors && <WppInlineMessage size="s" message="Outcome is required." type="error" />}
      {!hasOutcomeErrors && hasValueErrors && <WppInlineMessage size="s" message={isValueEmpty ? 'Revenue Value is required' : 'Must be greater than 0'} type="error" />}
      {hasOutcomeErrors && hasValueErrors && <WppInlineMessage size="s" message="Indicate outcome and values for all campaigns." type="error" />}
    </WppGrid>
  );
};

export default ValidationMessages;
