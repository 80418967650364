import _ from 'lodash';
import React, { useState } from 'react';
import { Message, Icon, SemanticCOLORS } from 'buildingBlocks';
import { FlightIds } from './BulkUploader';

type Props = {
  list: FlightIds
  msgHeader: string
  error?: boolean
  color?: SemanticCOLORS
  success?: boolean
};

const FlightUploadMessage = (props: Props) => {
  const { list, msgHeader, ...rest } = props;
  const [showList, setShowList] = useState<boolean>(false);

  const onClick = () => {
    setShowList(!showList);
  };

  return (
    <Message {...rest} style={{ display: 'block', marginTop: '16px' }}>
      <a style={{ outline: 'none' }} tabIndex={0} role="link" onClick={onClick}>
        <Message.Header style={{ cursor: 'pointer' }}>
          {showList ? <Icon name="caret down" /> : <Icon name="caret right" />}{msgHeader}
        </Message.Header>
      </a>
      {
        showList
          && (
          <Message.List>
            {_.map(list, (item, idx: number) => <Message.Item key={idx}>{item}</Message.Item>)}
          </Message.List>
          )
      }
    </Message>
  );
};

export default FlightUploadMessage;
