import _ from 'lodash';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useFormContext, useWatch } from 'react-hook-form';
import { WppEmptyContent, WppTypography } from 'buildingBlocks';
import { ALLOWED_DRAG_DROP_TYPES } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { ALL_OPERATORS } from 'containers/StrategyWizard/constants';
import { COPILOT_COLORS } from 'globalStyles';
import keymap from 'utils/keymap';
import DroppableComponents from './DroppableComponents';
import { useFormulaContext } from '../contexts/FormulaProvider';
import { ActionTypes, DraggableItem, FormulaBuilderAction } from '../contexts/types';
import DropzoneActions from './DropzoneActions';
import GrabFormula from './GrabFormula/GrabFormula';

const {
  dropzoneImgContainer, dropzone, grabFormulaDiv,
} = FORMULA_SECTION_STYLES;
const { KEY_ARROW_LEFT, KEY_ARROW_RIGHT, BACKSPACE, DELETE } = keymap;

const EmptyDropZone = () => (
  <div style={dropzoneImgContainer}>
    <WppEmptyContent width={150} />
    <WppTypography type="s-body" tag="p">Drop Operators and Metrics here.</WppTypography>
  </div>
);

const Dropzone = () => {
  const [shouldBlink, setShouldBlink] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  const lastDroppedItemBottom = useRef<number>(null);
  const {
    dropItems, addDropItem, dropItemIds, dropzoneActive, cursorIdx,
    setDropzoneActive, insertItemAt, setCursorIdx, handleRemoveItem,
    addUndoAction, searchInputRef, dropzoneRef,
  } = useFormulaContext();
  const metricsConfig = useWatch({ name: 'metricsConfig' });
  const { formState: { errors }, setValue } = useFormContext();

  const [, drop] = useDrop(() => ({
    accept: ALLOWED_DRAG_DROP_TYPES,
    // eslint-disable-next-line consistent-return
    drop: (item: DraggableItem, monitor) => {
      if (!dropItemIds.has(item.id)) {
        const actionObj = { action: ActionTypes.add, contentId: item.id, content: item.content } as Partial<FormulaBuilderAction>;
        // handles only adding new elements to the end of the dropzone
        if (!_.has(item, 'index')) {
          addDropItem(item);
          actionObj.contentIdx = _.size(dropItemIds);
          actionObj.cursorIdx = _.size(dropItemIds);
        } else {
          const clientOffset = monitor.getClientOffset();
          // drag DraggableComponents towards whitespace in Dropzone, completely away from/below other components
          if (lastDroppedItemBottom.current < clientOffset.y) {
            addDropItem(item);
          } else {
            // drag DraggableComponents directly between DragzoneComponents
            insertItemAt(item, item.index);
          }
          actionObj.contentIdx = item.index;
          actionObj.cursorIdx = item.index;
        }
        setCursorIdx(_.size(dropItemIds));
        setDropzoneActive(true);
        addUndoAction(actionObj);
        return { message: 'dropzone' };
      }
    },
    hover: (_item, monitor) => {
      const clientOffset = monitor.getClientOffset();
      // handles cursor position when dragging DraggableComponent to an area in Dropzone completely away from DropzoneComponents
      if (lastDroppedItemBottom.current < clientOffset.y) {
        setCursorIdx(_.size(dropItemIds));
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }), [dropItems]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleDelete = useCallback((selected: boolean, dropItems: Array<DraggableItem>, cursorIdx: number, handleRemoveItem: Function, setSelected: Dispatch<SetStateAction<boolean>>) => {
    if (selected) {
      const componentToDelete = dropItems[cursorIdx];
      const actionObj = {
        action: ActionTypes.delete,
        contentId: componentToDelete.id,
        content: componentToDelete.content,
        contentIdx: cursorIdx,
        cursorIdx,
      };
      const metricToDelete = _.camelCase(componentToDelete.content);
      setValue('metricsConfig', _.omit(metricsConfig, metricToDelete));
      handleRemoveItem(componentToDelete.id);
      setSelected(false);
      addUndoAction(actionObj);
    } else {
      setCursorIdx(cursorIdx - 1);
      setSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropItems, cursorIdx]);

  const handleKeyDown = (e) => {
    const alreadyFocusedCustomInput = shouldBlink;
    const deleteOrMoveLeftAtLeftBound = (cursorIdx === 0 && !selected) && [KEY_ARROW_LEFT, BACKSPACE, DELETE].includes(e.keyCode);
    const moveRightAtRightBound = _.isEqual(cursorIdx, _.size(dropItemIds)) && _.isEqual(KEY_ARROW_RIGHT, e.keyCode);
    const alphanumeric = /^[a-z0-9]$/i;
    const allowedKeys = [...ALL_OPERATORS, 'Backspace', 'Delete', 'Enter', 'ArrowLeft', 'ArrowRight', '.'];
    const invalidKeyPress = !alphanumeric.test(e.key) && !_.includes(allowedKeys, e.key);
    if (alreadyFocusedCustomInput || deleteOrMoveLeftAtLeftBound || moveRightAtRightBound || invalidKeyPress) {
      return;
    }
    const includesReservedKeys = [KEY_ARROW_RIGHT, KEY_ARROW_LEFT, BACKSPACE, DELETE].includes(e.keyCode);
    // set inputRef on CustomInputElement when user starts typing alpanumeric characters in dropzone
    if (!shouldBlink && !includesReservedKeys) {
      setShouldBlink(true);
    }
    // user presses reserved keys when not focused on CustomInputElement
    switch (e.keyCode) {
      case (KEY_ARROW_LEFT):
        if (selected) {
          setSelected(false);
        } else {
          setCursorIdx(cursorIdx - 1);
          setSelected(true);
        }
        break;
      case (BACKSPACE):
      case (DELETE):
        handleDelete(selected, dropItems, cursorIdx, handleRemoveItem, setSelected);
        break;
      case (KEY_ARROW_RIGHT):
        if (selected) {
          setCursorIdx(cursorIdx + 1);
          setSelected(false);
        } else {
          setSelected(true);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (dropzoneActive && !shouldBlink) {
      dropzoneRef.current.focus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropzoneActive, dropItems, shouldBlink]);

  const activeBorderStyle = { ...(dropzoneActive && { border: `1px solid ${COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B500_WAVE}` }) };
  const errorBorderStyle = { ...((!_.isEmpty(_.get(errors, 'customGoal.formula')) && !dropzoneActive) && { border: `1px solid ${COPILOT_COLORS.WPP.danger500}` }) };
  // connects the dropzone div to the DnD backend, making it a valid drop target
  // when a draggable item is dropped onto this div, the drop() fn defined in useDrop will be called
  drop(dropzoneRef);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      style={{ ...dropzone, ...activeBorderStyle, ...errorBorderStyle }}
      ref={dropzoneRef}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      onClick={() => {
        // handles clicking outside of dropzone then clicking back in - resets shouldBlink
        if (shouldBlink) {
          setShouldBlink(false);
        }
        setDropzoneActive(true);
      }}
      onKeyDown={(e) => {
        // when typing in Grab Formula input field, onKeyDown in Dropzone is triggered
        if (_.isNil(searchInputRef.current)) {
          handleKeyDown(e);
        }
      }}
      onBlur={() => {
        // only set dropzoneActive to false if Grab Formula modal is closed
        if (dropzoneActive && !shouldBlink && _.isNil(searchInputRef.current)) {
          setDropzoneActive(false);
          setShouldBlink(false);
          setCursorIdx(_.size(dropItemIds));
          setSelected(false);
        }
      }}
    >
      <div style={grabFormulaDiv}>
        <GrabFormula />
        <DropzoneActions handleDelete={handleDelete} selected={selected} setSelected={setSelected} />
      </div>
      {(_.isEmpty(dropItems) && !dropzoneActive)
        ? <EmptyDropZone />
        : (
          <DroppableComponents
            shouldBlink={shouldBlink}
            setShouldBlink={setShouldBlink}
            selected={selected}
            setSelected={setSelected}
            lastDroppedItemBottom={lastDroppedItemBottom}
          />
        )}
    </div>
  );
};

export default Dropzone;
