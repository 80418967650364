import React, { Dispatch, SetStateAction } from 'react';
import { WppActionButton, WppIconEdit, WppIconRefresh, WppPill, WppPillGroup, WppTypography } from 'buildingBlocks';
import { REVENUE_TYPES_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { RevenueBulkUpdateTypes } from 'containers/StrategyWizard/types';

const { buttonStyle } = REVENUE_TYPES_STYLES;

type BulkUpdateProps = {
  setShowBulkUpdate: Dispatch<SetStateAction<boolean>>
  setShowBulkEdit: Dispatch<SetStateAction<boolean>>
  updateOutcomeObjects: Dispatch<SetStateAction<string>>
  resetRevOptions: () => void
  selectedObjects: Array<string>
};

const BulkUpdate = (props: BulkUpdateProps) => {
  const { setShowBulkUpdate, setShowBulkEdit, updateOutcomeObjects, resetRevOptions, selectedObjects } = props;
  return (
    <div>
      <WppPillGroup type="display" value={selectedObjects.length}>
        <WppPill
          label={`${selectedObjects.length} selected`}
          value={selectedObjects.length}
          removable
          onWppClose={() => resetRevOptions()}
        />
      </WppPillGroup>
      <WppActionButton variant="secondary" style={buttonStyle} onClick={() => { setShowBulkEdit(true); setShowBulkUpdate(false); }}>
        <WppIconEdit slot="icon-start" />
        <WppTypography type="s-strong" tag="p">Bulk Edit</WppTypography>
      </WppActionButton>
      <WppActionButton variant="secondary" style={buttonStyle} onClick={() => { updateOutcomeObjects(RevenueBulkUpdateTypes.reset); resetRevOptions(); }}>
        <WppIconRefresh slot="icon-start" />
        <WppTypography type="s-strong" tag="p">Bulk Reset</WppTypography>
      </WppActionButton>
    </div>
  );
};

export default BulkUpdate;
