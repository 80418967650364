/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import KeyBinding from 'react-keybinding-component';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { GlobalState } from 'reducers';
import { Form, WppGrid, WppTypography } from 'buildingBlocks';
import PermissionWrapper from 'components/PermissionWrapper';
import { DSP } from 'constantsBase';
import {
  ATTACH_FLIGHTS, RESET_GOALS_AND_STRATEGY_TYPE, WizardSteps, WIZARD_STEPS, SET_PRODUCT_LOCATION,
  RESET_GOALS_AND_STRATEGY_TYPE_AMZN, UPDATE_BUDGET_ALLOCATION_STATE, CONFIGURE_STRATEGY, SET_STRATEGY_NAME,
} from 'containers/StrategyWizard/constants';
import { AttachFlightsInfoType, WizardFormAttachFlights, WizardFormValues } from 'containers/StrategyWizard/types';
import { getUniqFlightExtIds } from 'containers/StrategyWizard/utils';
import { QSParams } from 'containers/StrategyWizard/hooks';
import NavFooter from 'containers/StrategyWizard/components/NavFooter';
import { BudgetTypes, BUDGET_ALLOCATION_SUPPORTED_EXT_TYPES, Status } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { updateQueryString } from 'containers/StrategyWizard/StrategyWizard';
import PageForbidden from 'containers/403';
import { AMZNOrder, Currency, Member } from 'utils/copilotAPI';
import { Permission } from 'utils/featureFlags';
import { isNumeric, createLinkWithQS } from 'utils/functionHelpers';
import { usePrevious } from 'utils/hooks/usePrevious';
import KEY_MAP, { KeyboardEvent } from 'utils/keymap';
import { Flight, User } from 'utils/types';
import { Router } from 'utils/withRouter';
import { wppBodyContainer } from 'components/PageTemplate/style';
import AttachFlightsModal from './components/AttachFlightsModal';
import AttachFlightsTable from './components/AttachFlightsTable';
import {
  ExternalTypeOptimizationLevel, EXTERNAL_TYPE_TO_DSP_AND_DEFAULT_OPT_LEVEL,
  MAX_FLIGHTS_ALLOWED, OPTIMIZATION_LEVELS, OptimizationType,
} from './constants';
import { ATTACH_DSP_OBJECT_STYLES } from './styles';
import {
  getBrand, getOptimizationType, getMemAdv, getCrossPlatformStrategyName,
  getSingleFlightNameAsStrategyName, configuringCrossPlatformStratCheck, configuringLineItemStratCheck, configuringCampaignStratCheck,
} from './utils';
import OptimizationTypeBanner from './components/OptimizationTypeBanner';

const crossPlatformOptLevel = OPTIMIZATION_LEVELS[DSP.MULTIPLE.id][ExternalTypeOptimizationLevel.HIGHER_ORDER];

type Props = {
  qsParams: QSParams
  attachFlightsInfo: AttachFlightsInfoType
  setAttachFlightsInfo: (x: any) => void
  canAccessCrossPlatform: boolean
  router: Router
  strategyId?: number
};

const AttachFlightsStep = (props: Props) => {
  const { qsParams, strategyId, attachFlightsInfo, setAttachFlightsInfo, canAccessCrossPlatform, router } = props;
  const { eligibleFlights, ineligibleFlights, flightsStatus, toBeDetached, attachedToThisStrategy } = attachFlightsInfo;
  const eligCPFlightsWithoutAmountBudgetType = _.get(attachFlightsInfo, 'eligCPFlightsWithoutAmountBudgetType', []);
  const eligCPFlightsWithSpend = _.get(attachFlightsInfo, 'eligCPFlightsWithSpend', []);
  const isCreateMode = _.isNil(strategyId);

  const historyLocState = _.get(router.location, 'state');
  const prefixLink = `/strategies/wizard${isNumeric(strategyId) ? `/${strategyId}` : ''}`;
  const qsOptLevel = _.get(qsParams, 'wizardForm.optimizationLevel');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector<GlobalState>((state) => state.login.user) as User;
  const reduxWizardFormValues = useSelector<GlobalState>((state) => state.strategyWizard) as WizardFormValues;
  const prev = usePrevious(reduxWizardFormValues);
  const { attachFlightsStep: reduxAttachedFlightsStep, strategyTypeSelectionStep: { strategyType }, budgetAllocationState } = reduxWizardFormValues;
  const selectedStratTypeId = _.get(strategyType, 'id') as number;
  const budgetAllocationData = _.get(budgetAllocationState, 'data');
  const loadingBudgetAllocationState = budgetAllocationState.kind === Status.loading;
  const reduxAttachedFlights = reduxAttachedFlightsStep.attachedFlights;
  const reduxDefaultCurrency = _.get(reduxAttachedFlightsStep, 'defaultCurrency');
  const reduxStrategyConfig = _.get(reduxWizardFormValues, 'strategyConfigurationStep');
  const initialFormValues = {
    member: _.get(reduxAttachedFlightsStep, 'member'),
    advertiser: _.get(reduxAttachedFlightsStep, 'advertiser'),
    brand: _.get(reduxAttachedFlightsStep, 'brand'),
    optimizationLevel: _.get(reduxAttachedFlightsStep, 'optimizationLevel'),
    attachedFlights: reduxAttachedFlights,
  };

  const formMethods = useForm<WizardFormAttachFlights>({ defaultValues: initialFormValues });
  const { control, reset, setValue } = formMethods;
  const member = useWatch({ name: 'member', control });
  const advertiser = useWatch({ name: 'advertiser', control });
  const brand = useWatch({ name: 'brand', control });
  const optimizationLevel = useWatch({ name: 'optimizationLevel', control });
  const attachedFlights = useWatch({ name: 'attachedFlights', control });
  const attachedFlightsExtTypeIds = getUniqFlightExtIds(attachedFlights);
  const attachedFlightsLen = _.size(attachedFlights);
  const oneFlightAttached = attachedFlightsLen === 1;
  const hasFlightsAttached = attachedFlightsLen > 0;
  const firstFlight = _.head(attachedFlights);
  const attachedFlightsAdvIds = _.uniq(_.map(attachedFlights, (flight: Flight) => _.get(flight, 'advertiser')));

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [defaultCurrencyId, setDefaultCurrencyId] = useState<number | null>(reduxDefaultCurrency ? _.get(reduxDefaultCurrency, 'id') : null);
  const [optTypeSelectionOpen, setOptTypeSelectionOpen] = useState<boolean>(!brand && !advertiser && isCreateMode && !qsOptLevel);
  const [selectedOptType, setSelectedOptType] = useState<OptimizationType>(null);
  const [memAdvBrandParams, setMemAdvBrandParams] = useState<{ brandIds: number, defaultAdvertiserCurrency: number } | null>(null);
  const [memAdvBrandFilter, setMemAdvBrandFilter] = useState<Array<{ memberId: number, advertiserId: number }> | null>(null);

  const configuringCrossPlatformStrat = configuringCrossPlatformStratCheck(selectedOptType);
  const configuringCampaignStrat = configuringCampaignStratCheck(selectedOptType);
  const configuringLineItemStrat = configuringLineItemStratCheck(selectedOptType);
  const flightLimitReached = (configuringCampaignStrat && oneFlightAttached)
    || ((configuringLineItemStrat || configuringCrossPlatformStrat) && attachedFlightsLen === MAX_FLIGHTS_ALLOWED);
  const disabled = ((_.size(eligCPFlightsWithoutAmountBudgetType) || _.size(eligCPFlightsWithSpend)) && configuringCrossPlatformStrat)
    || (strategyId ? loadingBudgetAllocationState : (loadingBudgetAllocationState || _.isEmpty(attachedFlights)))
    || (strategyId && _.isEmpty(reduxAttachedFlights) && _.isEmpty(attachedFlights));

  useEffect(() => {
    // prevents optTypeSelectionOpen state issues if user is configuring new strategy with QS populated on step 1 and hits the navBar +New Strategy btn
    if (!location.search && _.get(historyLocState, 'resetOptTypeModal') && location.pathname === '/strategies/wizard/1' && selectedOptType) {
      setOptTypeSelectionOpen(true);
      setSelectedOptType(null);
    }
  }, [historyLocState]);

  useEffect(() => {
    reset({
      member: initialFormValues.member,
      advertiser: initialFormValues.advertiser,
      brand: _.get(initialFormValues, 'brand', null),
      optimizationLevel: initialFormValues.optimizationLevel,
      attachedFlights: initialFormValues.attachedFlights,
    });
    setDefaultCurrencyId(_.get(reduxDefaultCurrency, 'id', null));
    setSelectedOptType(_.get(reduxAttachedFlightsStep, 'selectedOptType', getOptimizationType(selectedStratTypeId, initialFormValues.attachedFlights)));
  }, [initialFormValues.member]);

  useEffect(() => {
    // set the brand if user first goes through the mem/adv workflow prior to flight selection
    if (canAccessCrossPlatform && configuringCrossPlatformStrat && advertiser && isCreateMode && !hasFlightsAttached) {
      const advertiserId = advertiser.id;
      const listOfBrandAdvIds = _.map(_.get(brand, 'advertisers'), 'id');
      // only reset the brand if the newly selected adv is not within list of brand advs
      if (!_.includes(listOfBrandAdvIds, advertiserId)) {
        getBrand(advertiserId)
          .then((brandObj) => {
            setValue('brand', brandObj);
          });
      }
    }
  }, [attachedFlightsLen, advertiser]);

  useEffect(() => {
    // reset the modal if there is a brand and there are multiple flights from different advertisers attached
    if (brand && configuringCrossPlatformStrat) {
      setValue('member', null);
      setValue('advertiser', null);
    }

    // reset brand, default currency, and mem/adv filter if all flights are removed from flight management page
    if (canAccessCrossPlatform && !hasFlightsAttached && isCreateMode) {
      setDefaultCurrencyId(null);
      setMemAdvBrandFilter(null);
    }

    // set the optLvl to cross-platform if there is more than one higher order flight attached from multiple DSPs
    if (configuringCrossPlatformStrat && !_.isEqual(optimizationLevel, crossPlatformOptLevel) && _.size(attachedFlightsExtTypeIds) > 1) {
      setValue('optimizationLevel', crossPlatformOptLevel);
    }
    // if flights from only one DSP were selected via brand selection no optLevel will be set
    const flightFlightsDefaultOptLevel = _.get(EXTERNAL_TYPE_TO_DSP_AND_DEFAULT_OPT_LEVEL[_.head(attachedFlightsExtTypeIds)], 'optimizationLevel');
    if (_.size(attachedFlightsExtTypeIds) === 1 && optimizationLevel !== flightFlightsDefaultOptLevel) {
      setValue('optimizationLevel', flightFlightsDefaultOptLevel);
    }
  }, [attachedFlightsLen]);

  useEffect(() => {
    // remove all eligible flights without currency based budgets or existing spend when budgetAllocationData is updated while configuring cross-platform strategies
    if (!budgetAllocationData) return;

    const eligibleFlightExtIds = _.map(eligibleFlights, 'externalId');
    const eligFlightsWithoutAmountBudgetTypeExtIds = _.filter(
      eligibleFlightExtIds,
      (extId: string) => (!_.isEqual(_.get(budgetAllocationData, `[${extId}].hierarchy.parentSettings.budgetType`), BudgetTypes.amount)),
    );
    const eligFlightsWithSpendExtIds = _.filter(
      eligibleFlightExtIds,
      (extId: string) => (!_.includes(eligFlightsWithoutAmountBudgetTypeExtIds, extId) && _.size(_.get(budgetAllocationData, `[${extId}].parentData.cumData`))),
    );
    if (configuringCrossPlatformStrat && _.size(eligibleFlights) && (!_.isEmpty(eligFlightsWithoutAmountBudgetTypeExtIds) || !_.isEmpty(eligFlightsWithSpendExtIds))) {
      const eligCrossPlatformFlightsWithoutAmountBudgetType = _.filter(eligibleFlights, (flight: Flight) => _.includes(eligFlightsWithoutAmountBudgetTypeExtIds, flight.externalId));
      const eligCrossPlatformFlightsWithSpend = _.filter(eligibleFlights, (flight: Flight) => _.includes(eligFlightsWithSpendExtIds, flight.externalId));
      setAttachFlightsInfo({
        ...attachFlightsInfo,
        eligibleFlights: _.filter(eligibleFlights, (flight: Flight) => !_.includes(_.concat(eligFlightsWithoutAmountBudgetTypeExtIds, eligFlightsWithSpendExtIds), flight.externalId)),
        eligCPFlightsWithoutAmountBudgetType: [...eligCPFlightsWithoutAmountBudgetType, ...eligCrossPlatformFlightsWithoutAmountBudgetType],
        eligCPFlightsWithSpend: [...eligCPFlightsWithSpend, ...eligCrossPlatformFlightsWithSpend],
      });
    }
  }, [budgetAllocationData]);

  // set the member/advertisers tied to the brand and default currency
  useEffect(() => {
    const newParams = { brandIds: _.get(brand, 'id'), defaultAdvertiserCurrency: defaultCurrencyId };
    const setBrandFilters = async () => {
      const brandMemAdvArr = await Member.memberAdvBrand({ brandIds: brand.id, defaultAdvertiserCurrency: defaultCurrencyId });
      setMemAdvBrandFilter(_.get(brandMemAdvArr, 'data', null));
    };

    if (configuringCrossPlatformStrat && canAccessCrossPlatform && brand && defaultCurrencyId && !_.isEqual(newParams, memAdvBrandParams)) {
      setMemAdvBrandParams(newParams);
      setBrandFilters();
    } else if (configuringCrossPlatformStrat && brand && !defaultCurrencyId) {
      // reset default currency and base mem/adv filter if all flights are removed from an existing campaign level opt strat
      const allBrandMemsAdvs = _.map(brand.advertisers, (adv) => ({ memberId: adv.member, advertiserId: adv.id }));
      setMemAdvBrandFilter(allBrandMemsAdvs);
    }
  }, [defaultCurrencyId, brand, attachedFlightsLen]);

  const handleOnClick = async () => {
    const attachFlightsStepValues = {
      member,
      advertiser,
      brand,
      defaultCurrency: reduxDefaultCurrency,
      optimizationLevel,
      attachedFlights,
      eligibleFlights,
      ineligibleFlights,
      flightsStatus,
      toBeDetached,
      attachedToThisStrategy,
      selectedOptType,
      ...((!isCreateMode && configuringCrossPlatformStrat) && {
        reactivatedFlights: _.get(attachFlightsInfo, 'reactivatedFlights'),
        toBeDeactivated: _.get(attachFlightsInfo, 'toBeDeactivated'),
        deactivatedFlights: _.get(attachFlightsInfo, 'deactivatedFlights'),
      }),
    };
    // set the brand upon next click for non-cross-platform strats in create mode
    if (!configuringCrossPlatformStrat && isCreateMode) {
      await getBrand(advertiser.id)
        .then((brandObj) => {
          attachFlightsStepValues.brand = brandObj;
        });
    }
    // set or clear the member/advertiser when configuring cross-platform strategies
    if (configuringCrossPlatformStrat && _.size(attachedFlightsAdvIds) === 1) {
      await getMemAdv(_.head(attachedFlights))
        .then((retObj) => {
          attachFlightsStepValues.member = _.get(retObj, 'flightMem', null);
          attachFlightsStepValues.advertiser = _.get(retObj, 'flightAdv', null);
        });
    }
    if (configuringCrossPlatformStrat && (_.size(attachedFlightsAdvIds) !== 1) && (member || advertiser)) {
      attachFlightsStepValues.member = null;
      attachFlightsStepValues.advertiser = null;
    }
    // filter out removed flights from budget allocation state
    if (_.includes(BUDGET_ALLOCATION_SUPPORTED_EXT_TYPES, _.get(firstFlight, 'externalType')) && _.size(_.keys(budgetAllocationData)) > _.size(attachedFlights)) {
      const filteredBudgetAllocationData = _.pick(budgetAllocationData, _.map(attachedFlights, 'externalId'));
      dispatch({ type: UPDATE_BUDGET_ALLOCATION_STATE, payload: { kind: Status.hasData, data: filteredBudgetAllocationData } });
    }
    // set the default currency on the form if it has changed from the initialValues in create mode
    if (!_.isEqual(_.get(reduxDefaultCurrency, 'id'), defaultCurrencyId) && isCreateMode) {
      const newCurrencyRes = await Currency.getById(_.toString(defaultCurrencyId));
      attachFlightsStepValues.defaultCurrency = newCurrencyRes.data;
    }
    dispatch({
      type: ATTACH_FLIGHTS,
      payload: attachFlightsStepValues,
    });
    if ((_.get(member, 'dsp') === DSP.AMZN.id) && configuringCampaignStrat) {
      const amznOrderRes = await AMZNOrder.get({ externalId: _.get(_.head(attachedFlights), 'externalId') });
      const amznProductLocation = _.get(_.head(amznOrderRes.data), 'optimization.productLocation');
      dispatch({ type: SET_PRODUCT_LOCATION, payload: amznProductLocation });
    }

    // Create flow: if user changes basic info depending on selected opt type, we reset GoalSelectionStep and StrategyTypeSelectionStep
    const prevValues = _.pick(prev.attachFlightsStep, ['member', 'advertiser', 'brand', 'optimizationLevel']);
    const currValues = _.pick(attachFlightsStepValues, ['member', 'advertiser', 'brand', 'optimizationLevel']);
    const shouldResetCrossPlatform = configuringCrossPlatformStrat
      && (!_.isEqual(prevValues.brand, currValues.brand) || (prevValues.member && !prevValues.brand && !_.isEqual(prevValues.member, currValues.member)));
    const shouldResetOtherStrats = !configuringCrossPlatformStrat && !_.isEqual(prevValues.member, currValues.member);
    if ((!_.isNil(prevValues.member) || !_.isNil(prevValues.brand)) && isCreateMode && (shouldResetCrossPlatform || shouldResetOtherStrats)) {
      if (_.get(currValues, 'member.dsp') === DSP.AMZN.id) {
        dispatch({ type: RESET_GOALS_AND_STRATEGY_TYPE_AMZN });
      } else {
        dispatch({ type: RESET_GOALS_AND_STRATEGY_TYPE });
      }
    }

    // set strategy name in create mode when isHigherOrder
    // at least one flight will be always available otherwise next button is disabled
    const newHigherOrderStrategy = isCreateMode && !configuringLineItemStrat;
    const strategyName = newHigherOrderStrategy
      && (configuringCrossPlatformStrat ? getCrossPlatformStrategyName(attachedFlights, (brand ?? advertiser)) : getSingleFlightNameAsStrategyName(attachedFlights));
    if (newHigherOrderStrategy) {
      dispatch({ type: SET_STRATEGY_NAME, payload: strategyName });
    }

    if (!isCreateMode && _.isEmpty(attachedFlights)) {
      dispatch({ type: CONFIGURE_STRATEGY, payload: { ...reduxStrategyConfig, groupSettings: {} } });
    }

    const values = {
      ...reduxWizardFormValues,
      attachFlightsStep: attachFlightsStepValues,
      ...(newHigherOrderStrategy && { strategyConfirmationStep: { ...reduxWizardFormValues.strategyConfirmationStep, name: strategyName } }),
    } as WizardFormValues;
    const pathname = `${prefixLink}/${WIZARD_STEPS[WizardSteps.goalSelectionStep].id}`;
    updateQueryString(values, qsParams, router.navigate, prev, pathname);
  };

  const runShortcuts = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.keyCode === KEY_MAP.ENTER) {
      e.preventDefault();
      router.navigate(`${prefixLink}/${WIZARD_STEPS[WizardSteps.strategyConfirmationStep].id}`);
    }
  };

  const onBackClick = () => {
    const backScreenID = strategyId ? WIZARD_STEPS[WizardSteps.attachFlightsStep].id : WIZARD_STEPS[WizardSteps.attachFlightsStep].subSteps.optimizationType.id;
    navigate(createLinkWithQS(`${prefixLink}/${backScreenID}`));
    if (_.isEqual(backScreenID, 1.1) && !strategyId) {
      setOptTypeSelectionOpen(true);
    }
  };

  return (
    <PermissionWrapper
      user={user}
      permissions={Permission.manageStrategyFlights}
      unauthorizedComponent={<PageForbidden message="You are not permitted to manage strategies." />}
    >
      <FormProvider {...formMethods}>
        <Form style={wppBodyContainer}>
          <WppGrid fullWidth container>
            <WppGrid item all={24} style={ATTACH_DSP_OBJECT_STYLES.header}>
              <WppTypography type="xl-heading" tag="h4">{optTypeSelectionOpen ? 'Optimization Type' : 'Object Attachment'}</WppTypography>
              <WppTypography type="s-body">{!optTypeSelectionOpen ? 'Select the platform objects to be attached to your strategy.' : null}</WppTypography>
            </WppGrid>
            <WppGrid item all={24}>
              <OptimizationTypeBanner
                isCreateMode={isCreateMode}
                canAccessCrossPlatform={canAccessCrossPlatform}
                flightLimitReached={flightLimitReached}
                optTypeSelectionOpen={optTypeSelectionOpen}
                setOptTypeSelectionOpen={setOptTypeSelectionOpen}
                selectedOptType={selectedOptType}
                setSelectedOptType={setSelectedOptType}
                strategyId={strategyId}
              />
              {!optTypeSelectionOpen && (
                <AttachFlightsModal
                  strategyId={strategyId}
                  modalIsOpen={modalIsOpen}
                  setModalIsOpen={setModalIsOpen}
                  attachFlightsInfo={attachFlightsInfo}
                  setAttachFlightsInfo={setAttachFlightsInfo}
                  selectedOptType={selectedOptType}
                  flightLimitReached={flightLimitReached}
                  defaultCurrencyId={defaultCurrencyId}
                  setDefaultCurrencyId={setDefaultCurrencyId}
                  memAdvBrandFilter={memAdvBrandFilter}
                  setMemAdvBrandFilter={setMemAdvBrandFilter}
                />
              )}
            </WppGrid>
            <WppGrid item all={24}>
              {!optTypeSelectionOpen && (
                <AttachFlightsTable
                  selectedOptType={selectedOptType}
                  attachFlightsInfo={attachFlightsInfo}
                  setAttachFlightsInfo={setAttachFlightsInfo}
                  flightLimitReached={flightLimitReached}
                  strategyId={strategyId}
                  configuringCrossPlatformStrat={configuringCrossPlatformStrat}
                />
              )}
            </WppGrid>
            <KeyBinding
              onKey={(e) => runShortcuts(e)}
              type="keydown"
            />
          </WppGrid>
        </Form>
        <NavFooter
          onNextClick={handleOnClick}
          onBackClick={onBackClick}
          showBackButton={!strategyId}
          nextButtonDisabled={disabled}
          strategyId={strategyId}
          selectedOptType={selectedOptType}
          canAccessCrossPlatform={canAccessCrossPlatform}
          setOptTypeSelectionOpen={setOptTypeSelectionOpen}
          optTypeSelectionOpen={optTypeSelectionOpen}
          setSelectedOptType={setSelectedOptType}
          setAttachFlightsInfo={setAttachFlightsInfo}
        />
      </FormProvider>
    </PermissionWrapper>
  );
};

export default AttachFlightsStep;
