import _ from 'lodash';
import React, { useEffect, SetStateAction, Dispatch } from 'react';
import { WppCheckbox, WppGrid, WppTable, WppTableBody, WppTableBodyCell, WppTableBodyRow, WppTableHeader, WppTableHeaderCell, WppTableHeaderRow, WppTypography } from 'buildingBlocks';
import { REVENUE_TYPES_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { RevTypeOption } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { useGoalSectionContext } from 'containers/StrategyWizard/steps/GoalSelection/contexts/GoalSectionProvider';
import { RevenueTypeOutcomes } from 'containers/StrategyWizard/types';
import OutcomesOptions from './OutcomesOptions';

const { noResultFound, outcomesName, outcomesDspName, outcomeBillable, outcomeValue, revenueTableGridStyle } = REVENUE_TYPES_STYLES;

type OutcomesProps = {
  revOutcomes: RevenueTypeOutcomes
  setShowBulkUpdate: Dispatch<SetStateAction<boolean>>
  setSelectAllChecked: Dispatch<SetStateAction<boolean>>
  selectAllChecked: boolean
  setSelectedObjs: Dispatch<SetStateAction<Array<string>>>
  selectedObjects: Array<string>
  updateSingleOutcome: (extId: string, value: string | number) => void
  revenueTypeOptions: Array<RevTypeOption>
  showBulkEdit: boolean
};

const OutcomesTable = (props: OutcomesProps) => {
  const {
    revOutcomes, setShowBulkUpdate, setSelectAllChecked, showBulkEdit, selectAllChecked,
    selectedObjects, setSelectedObjs, updateSingleOutcome, revenueTypeOptions,
  } = props;
  const { revTypeSearchStr } = useGoalSectionContext();

  useEffect(() => {
    if (!showBulkEdit) setShowBulkUpdate(!!_.size(selectedObjects));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedObjects]);

  const onSelectAllClick = () => {
    if (!selectAllChecked) {
      const allObjs = Object.keys(revOutcomes);
      const selectedObjIds = allObjs.flatMap((topKey) => Object.keys(revOutcomes[topKey]));
      setSelectedObjs(selectedObjIds);
      setSelectAllChecked(true);
    } else {
      setSelectedObjs([]);
      setSelectAllChecked(false);
    }
  };

  return (
    <WppGrid container fullWidth>
      <WppGrid direction="row" style={revenueTableGridStyle}>
        <WppTable>
          <WppTableHeader>
            <WppTableHeaderRow>
              <WppTableHeaderCell>
                <WppCheckbox
                  onClick={onSelectAllClick}
                  checked={selectAllChecked && !_.isEmpty(revOutcomes)}
                  style={REVENUE_TYPES_STYLES.outcomesRowCheckbox}
                />
              </WppTableHeaderCell>
              <WppTableHeaderCell style={outcomesName}>
                <WppTypography type="s-strong" tag="p">Campaigns</WppTypography>
              </WppTableHeaderCell>
              <WppTableHeaderCell style={outcomesDspName}>
                <WppTypography type="s-strong" tag="p" style={{ marginRight: '6px' }}>DSP</WppTypography>
              </WppTableHeaderCell>
              <WppTableHeaderCell style={outcomeBillable}>
                <WppTypography type="s-strong" tag="p" style={{ marginRight: '6px' }}> Billable Outcome</WppTypography>
              </WppTableHeaderCell>
              <WppTableHeaderCell style={outcomeValue} colSpan={1}>
                <WppTypography type="s-strong" tag="p">Billable Value</WppTypography>
              </WppTableHeaderCell>
            </WppTableHeaderRow>
          </WppTableHeader>
          {!_.isEmpty(revOutcomes) && (
            <>
              {_.map(revOutcomes, (outcomesObj, dsp) => (
                <OutcomesOptions
                  key={dsp}
                  dsp={_.toNumber(dsp)}
                  outcomes={outcomesObj}
                  selectedObjects={selectedObjects}
                  setSelectedObjs={setSelectedObjs}
                  updateSingleOutcome={updateSingleOutcome}
                  revenueTypeOptions={revenueTypeOptions}
                />
              ))}
            </>
          )}
          <WppTableBody className="wpp-tbody">
            <WppTableBodyRow className="wpp-table-body-row outcomeValueTableError">
              <WppTableBodyCell className="wpp-table-body-cell" colSpan={5} style={outcomeValue}>
                {_.isEmpty(revOutcomes) && (
                  <WppGrid style={noResultFound}>
                    <WppTypography type="s-body" tag="p">{!_.isEmpty(revTypeSearchStr) ? `No results found for ${revTypeSearchStr}.` : 'No active flights found'}.</WppTypography>
                  </WppGrid>
                )}
              </WppTableBodyCell>
            </WppTableBodyRow>
          </WppTableBody>
        </WppTable>
      </WppGrid>
    </WppGrid>
  );
};

export default OutcomesTable;
