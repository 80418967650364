import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Form, ObjectDropdown, WppLabel } from 'buildingBlocks';
import { WizardFormAttachFlights } from 'containers/StrategyWizard/types';
import { ALL, getAllOption, modalSessionFlightsInfoInitialState } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { Advertiser as AdvertiserAPI } from 'utils/copilotAPI';
import { MODAL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { Advertiser } from 'utils/types';

type AdvertiserFormFieldProps = {
  hasFlightsAttached: boolean
  loadingFlightCandidates: boolean
  setModalSessionFlightsInfo: (x: any) => void
  memAdvBrandFilter: Array<{ memberId: number, advertiserId: number }> | null
  configuringCrossPlatformStrat: boolean
  strategyId?: number
};

const AdvertiserFormField = (props: AdvertiserFormFieldProps) => {
  const {
    hasFlightsAttached,
    loadingFlightCandidates,
    setModalSessionFlightsInfo,
    memAdvBrandFilter,
    configuringCrossPlatformStrat,
    strategyId,
  } = props;

  const { control, setValue } = useFormContext<WizardFormAttachFlights>();
  const brand = useWatch({ name: 'brand' });
  const member = useWatch({ name: 'member' });
  const advertiser = useWatch({ name: 'advertiser' });

  // optLvl may not be set if flights were attached via brand workflow first
  const brandSpecificAdvIds = memAdvBrandFilter ? _.map(_.filter(memAdvBrandFilter, ['memberId', _.get(member, 'id')]), 'advertiserId') : undefined;

  const [advertisers, setAdvertisers] = useState<Array<Advertiser>>([]);
  const [advertiserFetchError, setAdvertiserFetchError] = useState<string>('');
  const [loadingAdvertisers, setLoadingAdvertiser] = useState<boolean>(false);
  const isCreateMode = _.isNil(strategyId);
  const disabled = !_.isEmpty(advertiserFetchError)
    || (configuringCrossPlatformStrat && !isCreateMode && !brand)
    || (configuringCrossPlatformStrat && isCreateMode && hasFlightsAttached && !brand)
    || (!configuringCrossPlatformStrat && (hasFlightsAttached || !isCreateMode))
    || !member;
  const classForStylingOverride = (loadingFlightCandidates && !hasFlightsAttached && isCreateMode) ? 'conditionally-loading-flights' : '';
  const displayText = brand ? 'All' : 'Search Advertisers...';

  const fetchAdvertisers = async (mem: number, searchTerm: string = '') => {
    setLoadingAdvertiser(true);
    const limit = 10;
    const populate = 'defaultCurrency';
    const sort = 'updatedAt DESC';
    const where = {
      member: mem,
      // we are already considering currency for the brandSpecificAdvIds if defaultCurrency is available
      ...(brandSpecificAdvIds && { id: brandSpecificAdvIds }),
      or: [
        { name: { contains: searchTerm } },
        { externalId: { contains: searchTerm } },
      ],
    };

    try {
      let res = await AdvertiserAPI.get({ where, sort, limit, populate });
      if (res.data.length === 0) {
        const newWhere = {
          ...where,
          or: [
            { name: { contains: '' } },
            { externalId: { contains: '' } },
          ],
        };
        res = await AdvertiserAPI.get({ where: newWhere, sort, limit, populate });
      }
      setLoadingAdvertiser(false);
      setAdvertisers(res.data);
    } catch {
      setLoadingAdvertiser(false);
      setAdvertiserFetchError('Failed to fetch advertisers');
    }
  };

  useEffect(() => {
    if (!disabled) {
      fetchAdvertisers(member.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member]);

  const handleOnChange = async (adv: Advertiser) => {
    const selectedAdv = _.isEqual(adv.name, ALL) ? null : adv;
    setValue('advertiser', selectedAdv);
    // reset modalSessionFlightsInfo if there is no brand and no flights attached
    if ((!brand || !configuringCrossPlatformStrat) && !hasFlightsAttached) {
      setModalSessionFlightsInfo(modalSessionFlightsInfoInitialState);
    }
  };

  const handleSearchChange = (_e: React.FormEvent, allProps: { searchQuery: string }) => (
    fetchAdvertisers(member.id, allProps.searchQuery)
  );

  const filteredAdvertisers = brandSpecificAdvIds ? _.filter(advertisers, (adv: Advertiser) => _.includes(brandSpecificAdvIds, adv.id)) : advertisers;
  const options = (advertiser && configuringCrossPlatformStrat && brand && _.size(filteredAdvertisers) > 1) ? [getAllOption(), ...filteredAdvertisers] : filteredAdvertisers;
  const keyFn = (adv) => (_.isEqual(adv.name, ALL) ? adv.name : `[ ${_.get(adv, 'externalId')} ] - ${adv.name}`);

  return (
    <Form.Field style={{ width: '100%' }}>
      <WppLabel
        htmlFor="advertiser"
        typography="s-strong"
        config={{
          text: 'Advertiser',
        }}
        style={MODAL_STYLES.attachFormFeildLabel}
      />
      <Controller
        name="advertiser"
        control={control}
        rules={{ required: true }}
        render={() => (
          <>
            <ObjectDropdown
              fluid
              selection
              className={classForStylingOverride}
              options={options}
              keyFn={keyFn}
              selectOnBlur={false}
              onClick={_.noop}
              onChange={handleOnChange}
              placeholder={advertiser ? null : 'Search Advertisers...'}
              disabled={disabled || loadingFlightCandidates}
              text={advertiser
                ? `[ ${advertiser.externalId} ] - ${advertiser.name}`
                : displayText}
              loading={loadingAdvertisers}
              search={{
                searchType: 'api',
                onSearchChange: handleSearchChange,
                debounce: { timer: 500 },
              }}
            />
            <span style={{ color: '#DB2828' }}>{advertiserFetchError}</span>
          </>
        )}
      />
    </Form.Field>
  );
};

export default AdvertiserFormField;
