import _ from 'lodash';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { Icon, WppAccordion, WppTypography, WppInlineMessage, WppProgressIndicator } from 'buildingBlocks';
import { DSP } from 'constantsBase';
import { BudgetInterval } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { getIntervalDateToUse, getTotalDelivered } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { WizardFormGoalSelection, BudgetSetting } from 'containers/StrategyWizard/types';
import { ISO_DATE } from 'utils/dateTime';
import { PossibleStates } from 'utils/hooks/useFetcher';
import CrossPlatformBudgetSettings from './CrossPlatformBudgetSettings';
import SingleBudgetInterval from './SingleBudgetInterval';
import { budgetIntervalsFetcher } from '../hooks';
import { BUDGET_SECTION_STYLES } from '../styles';
import { CrossPlatformBudgetSettingsValidationType } from '../utils';
import { useGoalSectionContext } from '../contexts/GoalSectionProvider';

type Props = {
  flightExtType: number
  childObjectTypeDisplayName: string
  crossPlatformBudgetSettingsValidation: CrossPlatformBudgetSettingsValidationType
  setCrossPlatformBudgetSettingsValidation: Dispatch<SetStateAction<CrossPlatformBudgetSettingsValidationType>>
  initialFormValues: WizardFormGoalSelection
  resetConfirmedGoal: Function
};

const BudgetIntervals = ({
  flightExtType,
  childObjectTypeDisplayName,
  crossPlatformBudgetSettingsValidation,
  setCrossPlatformBudgetSettingsValidation,
  initialFormValues,
  resetConfirmedGoal,
}: Props) => {
  const [displayAllBudgetIntervals, setDisplayAllBudgetIntervals] = useState<boolean>(false);
  const finishCalculations = useRef<boolean>(false);
  const {
    parentObjectTypeDisplayName, dsp, isCrossPlatformOptimization, strategyId,
    reduxWizardFormValues: {
      attachFlightsStep: { attachedFlights },
      budgetAllocationState,
    },
  } = useGoalSectionContext();
  const flightExtId = _.get(_.head(attachedFlights), 'externalId');
  const budgetConfig = useWatch({ name: 'budget' });

  if (!isCrossPlatformOptimization) {
    const budgetData = _.get(budgetAllocationState, 'data');
    const budgetIntervalsFetchState = budgetIntervalsFetcher(flightExtType, flightExtId);
    const budgetIntervalsFetchComplete = budgetIntervalsFetchState.kind === PossibleStates.hasData || budgetIntervalsFetchState.kind === PossibleStates.error;
    const [activeOrFutureBudgetIntervals, pastBudgetIntervals] = _.partition(_.get(budgetIntervalsFetchState, 'data'), (bI) => moment.utc(_.get(bI, 'endDate')) >= moment.utc());
    const showNoBudgetIntervalsMessage = _.size(activeOrFutureBudgetIntervals) === 0;
    const budgetIntervalsToShow = (displayAllBudgetIntervals ? activeOrFutureBudgetIntervals : [_.head(activeOrFutureBudgetIntervals)]) as Array<BudgetSetting | BudgetInterval>;
    const delivery = (strategyId && !_.isEmpty(budgetData) && !showNoBudgetIntervalsMessage) && getTotalDelivered(budgetData, budgetIntervalsToShow, budgetConfig, null, finishCalculations);

    return (
      <WppAccordion size="m" withDivider={false} className="budgetIntervalAccordion" expanded style={BUDGET_SECTION_STYLES.accordionStyle}>
        <WppTypography type="m-strong" tag="h5" slot="header">Budget Intervals</WppTypography>
        <div style={BUDGET_SECTION_STYLES.budgetIntervalsContainer}>
          <WppTypography type="s-body" tag="p">Review budget settings. Please note, delivery is prioritized over goal performance for each interval.</WppTypography>
          <div style={BUDGET_SECTION_STYLES.budgetIntervalInfobanner}>
            <WppInlineMessage
              size="l"
              titleText={`Budget Intervals for ${parentObjectTypeDisplayName}`}
              message={`Your Copilot strategy will inherit and apply all budget interval settings configured on the ${DSP.getById(dsp).displayName}
              ${parentObjectTypeDisplayName}. To edit budget settings, please update the ${parentObjectTypeDisplayName}. All ${childObjectTypeDisplayName} associated
              with this ${parentObjectTypeDisplayName} will contribute towards budget delivery.`}
              type="information"
              hideCloseBtn
              showTooltipFrom={4000}
            />
          </div>
          {!budgetIntervalsFetchComplete && <WppProgressIndicator variant="circle" width={20} style={BUDGET_SECTION_STYLES.loader} />}
          {budgetIntervalsFetchComplete
            && (
              // eslint-disable-next-line no-nested-ternary
              showNoBudgetIntervalsMessage
                ? (
                  <p style={BUDGET_SECTION_STYLES.noBudgetIntervalsMessage}>
                    <Icon name="info circle" />
                    {`${_.size(pastBudgetIntervals)} Budget Intervals completed. There are no active or upcoming intervals for the ${parentObjectTypeDisplayName}.`}
                  </p>
                ) : (
                  <div style={BUDGET_SECTION_STYLES.budgetIntervals}>
                    {_.map(budgetIntervalsToShow, (bI, idx) => {
                      const showLabels = idx === 0;
                      const deliveryValue = _.get(delivery, moment(getIntervalDateToUse(bI.startDate)).format(ISO_DATE));
                      return (
                        <SingleBudgetInterval
                          key={_.toString(bI.startDate)}
                          startDate={_.toString(bI.startDate)}
                          endDate={_.toString(bI.endDate)}
                          budgetAmount={_.get(bI, 'budgetAmount')}
                          showLabels={showLabels}
                          delivery={deliveryValue}
                          budgetImps={_.get(bI, 'budgetImps')}
                          initialFormValues={initialFormValues}
                          resetConfirmedGoal={resetConfirmedGoal}
                          finishCalculations={finishCalculations}
                        />
                      );
                    })}
                    {(_.size(activeOrFutureBudgetIntervals) > 1) && (
                      <div
                        onClick={() => setDisplayAllBudgetIntervals(!displayAllBudgetIntervals)}
                        style={BUDGET_SECTION_STYLES.showAllBudgetIntervals}
                        role="button"
                        tabIndex={0}
                      >
                        {displayAllBudgetIntervals ? 'Hide' : 'Show'} billing periods <Icon name={`chevron ${displayAllBudgetIntervals ? 'up' : 'down'}`} />
                      </div>
                    )}
                  </div>
                )
            )}
        </div>
      </WppAccordion>
    );
  }
  // cross-platform SECTION
  return (
    <>
      <WppAccordion size="m" withDivider={false} className="budgetIntervalAccordion" expanded style={BUDGET_SECTION_STYLES.accordionStyle}>
        <WppTypography type="m-strong" tag="h5" slot="header">
          Budget Intervals
        </WppTypography>
        <div style={BUDGET_SECTION_STYLES.budgetIntervalsContainer}>
          <WppTypography type="s-body" tag="p">
            Review budget settings. Please note, delivery is prioritized over goal performance for each interval.
          </WppTypography>
          <div style={BUDGET_SECTION_STYLES.budgetIntervalInfobanner}>
            <WppInlineMessage
              size="l"
              titleText={`Budget Intervals for ${parentObjectTypeDisplayName}`}
              message="Copilot overrides original budget settings. You can adjust budget intervals below to ensure all attached objects stay within budget for delivery."
              type="information"
              hideCloseBtn
              showTooltipFrom={4000}
            />
          </div>
          <CrossPlatformBudgetSettings
            crossPlatformBudgetSettingsValidation={crossPlatformBudgetSettingsValidation}
            setCrossPlatformBudgetSettingsValidation={setCrossPlatformBudgetSettingsValidation}
            initialFormValues={initialFormValues}
            resetConfirmedGoal={resetConfirmedGoal}
            finishCalculations={finishCalculations}
          />
        </div>
      </WppAccordion>
    </>
  );
};

export default BudgetIntervals;
