import _ from 'lodash';
import React, { useState } from 'react';
import { WppCardGroup, WppCard, WppTypography, WppGrid, WppInlineMessage } from 'buildingBlocks';
import { OPTTYPE_CARD } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { OptLevelType } from 'containers/StrategyWizard/types';
import { CardGroupChangeEventDetail } from 'utils/types';
import { OPTIMIZATION_TYPE_OPTIONS, OptimizationType } from '../constants';

type OptTypeCardProps = {
  optType: OptLevelType
  value: string
  itemSelected: boolean
  disabled?: boolean
};

const OptTypeCard = ({ optType, value, itemSelected, disabled }: OptTypeCardProps) => (
  <WppCard
    className={itemSelected ? 'opt-type-card-selected' : ''}
    value={value}
    disabled={disabled}
  >
    <div style={OPTTYPE_CARD.optTypeCard}>
      <WppTypography tag="p" type="m-strong" slot="header">{optType.displayName}</WppTypography>
      <WppInlineMessage
        size="m"
        message={optType.header}
        type="information"
        style={OPTTYPE_CARD.optTypeInformation}
      />
      <WppTypography tag="p" type="s-body" style={OPTTYPE_CARD.optTypeDescription}>{optType.description}</WppTypography>
      <img src={optType.imgSrc} alt={optType.displayName} style={OPTTYPE_CARD.optTypeImg} />
    </div>
  </WppCard>
);

type OptimizationTypeSelectionProps = {
  canAccessCrossPlatform: boolean
  disableOptTypeModal: boolean
  selectedOptType: OptimizationType
  setSelectedOptType: (x: any) => void
};

const OptimizationTypeSelection = (props: OptimizationTypeSelectionProps) => {
  const { canAccessCrossPlatform, disableOptTypeModal, selectedOptType, setSelectedOptType } = props;
  const optTypeOptions = canAccessCrossPlatform ? OPTIMIZATION_TYPE_OPTIONS : _.omit(OPTIMIZATION_TYPE_OPTIONS, [OptimizationType.crossPlatform]);
  const defaultOptType = selectedOptType ?? _.head(_.keys(optTypeOptions)) as OptimizationType;
  const [modalSelectedOptType, setModalSelectedOptType] = useState<OptimizationType>(defaultOptType);
  const handleConfirmClick = (optType: OptimizationType) => {
    if (!_.isEqual(selectedOptType, optType)) {
      setSelectedOptType(optType);
    }
  };

  const handleSingleCardGroupChange = (event: CustomEvent<CardGroupChangeEventDetail>) => {
    const selectedValue = event.detail.value as OptimizationType;
    setModalSelectedOptType(selectedValue);
    handleConfirmClick(selectedValue);
  };

  return (
    <WppCardGroup
      value={modalSelectedOptType}
      onWppChange={handleSingleCardGroupChange}
    >
      <WppGrid container fullWidth>
        {_.map(optTypeOptions, (oT: OptLevelType) => {
          const optTypeKey = _.findKey(optTypeOptions, oT) as OptimizationType;
          return (
            <WppGrid item all={8} key={oT.displayName}>
              <OptTypeCard
                optType={oT}
                disabled={disableOptTypeModal}
                value={optTypeKey}
                itemSelected={_.isEqual(modalSelectedOptType, optTypeKey)}
              />
            </WppGrid>
          );
        })}
      </WppGrid>
    </WppCardGroup>
  );
};

export default OptimizationTypeSelection;
