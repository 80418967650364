import React, { Dispatch, SetStateAction, useState } from 'react';
import { WppButton, WppSideModal, WppTypography } from 'buildingBlocks';
import { GoalData } from 'containers/StrategyWizard/steps/GoalSelection/types';
import { useAWGContext } from 'containers/StrategyWizard/steps/GoalSelection/GoalSection/AWG/contexts/AWGProvider';
import { GrabFormulaFilters } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { getGoalIdByName } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { useFormulaContext } from 'containers/StrategyWizard/steps/GoalSelection/GoalSection/AWG/contexts/FormulaProvider';
import { formatGoalFromDbToUi } from 'containers/StrategyWizard/utils';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import GrabFormulaModalContent from './GrabFormulaModalContent';

const { grabFormulaModelActionBtnContainer } = FORMULA_SECTION_STYLES;

type GrabFormulaModalProps = {
  openModal: boolean
  setOpenModal: Dispatch<SetStateAction<boolean>>
  onModalClose: (event: CustomEvent) => void
};

const GrabFormulaModal = ({ openModal, setOpenModal, onModalClose }: GrabFormulaModalProps) => {
  const { setSelectedFormula } = useAWGContext();
  const { dropzoneRef } = useFormulaContext();
  const [selectedGrabFormula, setSelectedGrabFormula] = useState<GoalData>();

  const [selectedFilter, setSelectedFilter] = useState<string>();
  const handleCloseModal = (event: CustomEvent) => {
    onModalClose(event);
  };

  const handleContinueClick = async () => {
    let goalObj = { ...selectedGrabFormula };
    if (selectedFilter === GrabFormulaFilters.copilot) {
      const goalId = await getGoalIdByName(selectedGrabFormula.name);
      goalObj = { ...goalObj, id: goalId };
    }
    const formattedGoal = formatGoalFromDbToUi([goalObj]);
    // only formula and metrics of customGoal are updated
    setSelectedFormula(formattedGoal);
    dropzoneRef.current.focus();
    setOpenModal(false);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <WppSideModal
      open={openModal}
      onWppSideModalClose={handleCloseModal}
      withBackButton
      className="grabFormulaModal"
      size="2xl"
    >
      <h3 slot="header">
        <WppTypography type="2xl-heading" tag="p">Grab Formula</WppTypography>
      </h3>
      <p slot="body">
        <GrabFormulaModalContent setSelectedGrabFormula={setSelectedGrabFormula} setSelectedFilter={setSelectedFilter} openModal={openModal} />
      </p>
      <div slot="actions" style={grabFormulaModelActionBtnContainer}>
        <WppButton variant="secondary" size="m" onClick={handleClose}>Close</WppButton>
        <WppButton size="m" onClick={handleContinueClick} disabled={!selectedGrabFormula}>Grab Formula</WppButton>
      </div>
    </WppSideModal>
  );
};

export default GrabFormulaModal;
