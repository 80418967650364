import _ from 'lodash';
import React from 'react';
import { WppAccordion, WppTypography } from 'buildingBlocks';
import { FLIGHT_EXTERNAL_TYPE } from 'constantsBase';
import { flightKey } from 'containers/StrategyWizard/utils';
import { FlightsStatusType } from 'containers/StrategyWizard/types';
import { FlightCategory } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { FLIGHT_DETAIL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { pluralizer } from 'utils/formattingUtils';
import { Flight } from 'utils/types';
import AttachFlightsDetails from './AttachFlightsDetails';

const getFlightCategoryTitleContent = (conditionalTitle: string, flightCategory: FlightCategory, flightSize: number) => {
  const isAre = pluralizer('is', 'are')(flightSize);
  switch (flightCategory) {
    case (FlightCategory.reactivatedFlights):
      return `${conditionalTitle} will be activated after the strategy is saved.`;
    case (FlightCategory.toBeDeactivated):
      return `${conditionalTitle} will be deactivated after the strategy is saved.`;
    case (FlightCategory.deactivatedFlights):
      return `${conditionalTitle} ${isAre} inactive and will not be optimized by Copilot.`;
    case (FlightCategory.ineligibleFlights):
      return `${conditionalTitle} ${isAre} expired as there are no active budget intervals.`;
    case (FlightCategory.eligCPFlightsWithoutAmountBudgetType):
      return 'Objects must be configured with currency based budgets for Cross-Platform strategies, please remove to continue.';
    case (FlightCategory.eligCPFlightsWithSpend):
      return 'Objects with prior delivery cannot be attached to Cross-Platform strategies, please remove to continue.';
    case (FlightCategory.toBeDetached):
    default:
      return `${conditionalTitle} will be detached after the strategy is saved.`;
  }
};

/* eslint-disable react/prop-types */
const ExpiredOrDetachedFlightsTable = ({
  flights,
  flightCategory,
  removeFlight,
  flightsStatus,
  isHigherOrderOptLvl,
  flightLimitReached,
  editingCrossPlatformStrat,
}) => (
  <>
    {_.map(flights, (flight: Flight) => {
      const eligibile = flightsStatus[flightKey(flight)].eligible;
      return (
        <AttachFlightsDetails
          key={`${flightKey(flight)} - ${flight.id}`}
          flight={flight}
          removeFlight={removeFlight}
          isHigherOrderOptLvl={isHigherOrderOptLvl}
          flightCategory={flightCategory}
          flightLimitReached={flightLimitReached}
          eligibile={eligibile}
          editingCrossPlatformStrat={editingCrossPlatformStrat}
        />
      );
    })}
  </>
);

type ExpiredOrDetachedFlightsAccordianProps = {
  flights: Array<Flight>
  flightCategory: FlightCategory
  flightsStatus: FlightsStatusType
  isHigherOrderOptLvl: boolean
  removeFlight: Function
  flightLimitReached?: boolean
  strategyId?: number
  configuringCrossPlatformStrat?: boolean
};

const ExpiredOrDetachedFlightsAccordion = ({
  flights,
  flightCategory,
  flightsStatus,
  isHigherOrderOptLvl,
  flightLimitReached,
  removeFlight,
  strategyId,
  configuringCrossPlatformStrat,
}: ExpiredOrDetachedFlightsAccordianProps) => {
  const externalTypeDisplayName = _.get(FLIGHT_EXTERNAL_TYPE.getById(_.head(flights).externalType), 'wizardDisplayName')
    ?? FLIGHT_EXTERNAL_TYPE.getById(_.head(flights).externalType).displayName;
  const editingCrossPlatformStrat = !_.isNil(strategyId) && configuringCrossPlatformStrat;
  const flightDisplayName = configuringCrossPlatformStrat ? 'object' : externalTypeDisplayName;
  const conditionalTitle = pluralizer(`This ${flightDisplayName}`, `These ${flightDisplayName}s`)(_.size(flights));

  const panels = [
    {
      title: {
        content: getFlightCategoryTitleContent(conditionalTitle, flightCategory, _.size(flights)),
        style: FLIGHT_DETAIL_STYLES.accordionTitle,
      },
    },
  ];

  return (
    <WppAccordion size="m" className="expired-or-detached-flights-accordion" expanded withDivider={false}>
      <WppTypography type="m-strong" slot="header">{panels[0].title.content}</WppTypography>
      <div>
        <ExpiredOrDetachedFlightsTable
          flights={flights}
          flightCategory={flightCategory}
          removeFlight={removeFlight}
          flightsStatus={flightsStatus}
          isHigherOrderOptLvl={isHigherOrderOptLvl}
          flightLimitReached={flightLimitReached}
          editingCrossPlatformStrat={editingCrossPlatformStrat}
        />
      </div>
    </WppAccordion>
  );
};

export default ExpiredOrDetachedFlightsAccordion;
