import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React, { ComponentType, MutableRefObject } from 'react';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { Icon, WppIconTrash, WppTableBodyCell, WppTypography, WppTableBodyRow, WppInput, WppDatepicker, Loader, WppTableBody } from 'buildingBlocks';
import { MetricsFormattingConstants } from 'constantsBase';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { ISO_DATE, NUMBER_DATE_FORMAT } from 'utils/dateTime';
import { WppInputCustomEvent, InputChangeEventDetail, DatePickerEventDetail, WppDatepickerCustomEvent } from 'utils/types';
import { BudgetStatus } from '../constants';
import { BUDGET_INTERVALS } from '../styles';
import './style.scss';
import { BudgetSettingsType } from '../types';
import { getBudgetCellStyleClass, numberValidate, removeAdditionalDots } from '../utils';
import { useGoalSectionContext } from '../contexts/GoalSectionProvider';
import RevenueTypeConfiguration from './RevenueTypeSection/RevenueTypeConfiguration';

const { budgetIntervalListStyle, activeBullet, inactiveBullet, calendarCell,
  budgetCell, deliveryCell, trashIconCell, inputcurrency, revenueCalculationCellStyle, futureIntervalTextStyle,
} = BUDGET_INTERVALS;

type CrossPlatformBudgetIntervalProps = {
  index: number
  currency: string
  budgetSettings: BudgetSettingsType
  delivery: { [key: string]: number }
  editedBudgetIndex: React.MutableRefObject<Set<any>>
  selectedDate: (index: number) => Date
  goalTargetProps: {
    component: ComponentType<any>
    labelPosition?: string
    label?: string
  }
  remove: (index?: number | number[]) => void
  initialFormValues: WizardFormGoalSelection
  resetConfirmedGoal: Function
  finishCalculations: MutableRefObject<boolean>
};

const CrossPlatformBudgetInterval = (props: CrossPlatformBudgetIntervalProps) => {
  const { control } = useFormContext();
  const { revenueTypeEnabled, strategyId, hasRevTypePermission, reduxWizardFormValues: { goalSelectionStep } } = useGoalSectionContext();

  const {
    index,
    currency,
    budgetSettings,
    delivery,
    editedBudgetIndex,
    selectedDate,
    remove,
    initialFormValues,
    resetConfirmedGoal,
    finishCalculations,
  } = props;

  const revTypeConfig = useWatch({ name: 'budget' });
  const budgetSettingsObj = _.get(budgetSettings, `[${index}]`);
  const budgetSettingsStartDate = _.get(budgetSettingsObj, 'startDate') as Date;
  const budgetSettingsBudget = _.get(budgetSettingsObj, 'budget');
  const budgetSettingBudgetAsNum = _.toNumber(budgetSettingsBudget);
  const previouslySavedStartDate = _.get(goalSelectionStep.budgetSettings, `[${index}].startDate`);
  const startDateTodayOrFuture = (strategyId && previouslySavedStartDate && !_.get(budgetSettingsObj, 'newlyAdded'))
    ? moment(previouslySavedStartDate).isAfter(moment(), 'day')
    : moment(budgetSettingsStartDate).isSameOrAfter(moment(), 'day');
  const endDateActive = moment(_.get(budgetSettingsObj, 'endDate')).isSameOrAfter(moment(), 'day');
  const isNewlyAdded = _.get(budgetSettingsObj, 'newlyAdded');

  let errorMessage: string;
  let budgetDeliveryStatus: BudgetStatus;
  let intervalDelivery: string;
  const deliveryValue = _.get(delivery, moment(budgetSettingsStartDate).format(ISO_DATE));
  const revenueCalculationText = revenueTypeEnabled ? 'Customize' : 'Platform Reported';

  if (strategyId && budgetSettingsObj) {
    if (startDateTodayOrFuture) {
      intervalDelivery = 'Scheduled';
    } else {
      // Delivery is provided in events for revenue type enabled strategies - always display in currency
      intervalDelivery = numeral(deliveryValue).format(MetricsFormattingConstants.NO_DECIMALS);
      budgetSettings[index].delivery = deliveryValue;
      budgetSettings[index].active = endDateActive;
    }

    const currencyBudget = _.isNaN(budgetSettingBudgetAsNum) ? 0 : +budgetSettings[index].budget;
    const budget = (_.size(revTypeConfig)) ? currencyBudget : budgetSettingsBudget;
    // Touched and edited
    if ((editedBudgetIndex.current.has(index) || (budget && deliveryValue)) && intervalDelivery !== 'N/A') {
      if (budget < deliveryValue && endDateActive) {
        errorMessage = 'Budget should be higher than delivery.';
        budgetDeliveryStatus = BudgetStatus.LESS_THAN_DELIVERY;
      } else if (budget >= deliveryValue && budgetSettings[index].originalValue !== +budgetSettings[index].budget) {
        errorMessage = 'Please ensure objects have enough scale for the budget change.';
        budgetDeliveryStatus = BudgetStatus.GREATER_THAN_DELIVERY;
      }
    }
  }

  if (!_.isNil(budgetSettingsBudget) && editedBudgetIndex.current.has(index) && (budgetSettingBudgetAsNum < 1 || _.toString(budgetSettingsBudget) === '.')) {
    errorMessage = 'Budgets must be greater than or equal to 1.';
    budgetDeliveryStatus = BudgetStatus.LESS_THAN_DELIVERY;
  }

  const onStartDateChange = (date: Date, onChange: Function) => {
    if (!date) {
      return;
    }

    onChange(date);
  };

  const onEndDateChange = (date: Date, onChange: Function) => {
    if (!date) {
      return;
    }

    onChange(date);
  };

  const onBudgetChange = (data: { value: string }, onChange: Function) => {
    // Allow for just 2 decimal places and just one 'dot'
    const newValue = removeAdditionalDots(data.value).split('.').map((el, i) => (i ? el.split('').slice(0, 2).join('') : el)).join('.');

    onChange(newValue);
    // Capture edited index
    editedBudgetIndex.current.add(index);
  };

  const budgetIntervalBulletedList = () => (!isNewlyAdded && !startDateTodayOrFuture ? (
    <Icon name="circle" size="small" style={endDateActive ? activeBullet : inactiveBullet} />
  ) : null);

  const onRemoveInterval = () => {
    remove(index);
  };

  const deliveryToDisplay = `${intervalDelivery} ${!startDateTodayOrFuture ? currency : ''}`;
  const disableBudget = (isNewlyAdded && (!startDateTodayOrFuture || !endDateActive)) || (!isNewlyAdded && !endDateActive);

  return (
    <WppTableBody className="wpp-tbody">
      <WppTableBodyRow>
        {strategyId && (
          <WppTableBodyCell style={budgetIntervalListStyle}>
            {budgetIntervalBulletedList()}
          </WppTableBodyCell>
        )}
        <WppTableBodyCell style={calendarCell}>
          <Controller
            control={control}
            name={`budgetSettings[${index}].startDate`}
            render={({ field: { value, onChange } }) => (
              <WppDatepicker
                locale={{
                  dateFormat: 'MM/dd/yyyy',
                }}
                placeholder="mm/dd/yyyy"
                disabled={!isNewlyAdded && !startDateTodayOrFuture}
                onWppChange={(event: WppDatepickerCustomEvent<DatePickerEventDetail>) => {
                  const date: any = event.detail.date;
                  if (date) {
                    onStartDateChange(date, onChange);
                  }
                }}
                className={!strategyId && 'budgetIntDatepicker'}
                required
                value={value ? moment(value).format(NUMBER_DATE_FORMAT) : ''}
                size="s"
                minDate={moment(new Date()).format(NUMBER_DATE_FORMAT)}
                maxDate={moment(new Date()).add(5, 'y').format(NUMBER_DATE_FORMAT)}
              />
            )}
            rules={{ required: true }}
          />
        </WppTableBodyCell>
        <WppTableBodyCell style={calendarCell}>
          <Controller
            control={control}
            name={`budgetSettings[${index}].endDate`}
            render={({ field: { onChange } }) => (
              <WppDatepicker
                locale={{
                  dateFormat: 'MM/dd/yyyy',
                }}
                required
                placeholder="mm/dd/yyyy"
                disabled={!isNewlyAdded && !endDateActive}
                onWppChange={(event: WppDatepickerCustomEvent<DatePickerEventDetail>) => {
                  const date: any = event.detail.date;
                  if (date && moment(budgetSettings[index]?.startDate).isBefore(date)) {
                    onEndDateChange(date, onChange);
                  }
                }}
                className={!strategyId && 'budgetIntDatepicker'}
                value={budgetSettings[index]?.startDate ? moment(selectedDate(index)).format(NUMBER_DATE_FORMAT) : ''}
                size="s"
                minDate={budgetSettings[index]?.startDate >= new Date()
                  ? moment(budgetSettings[index]?.startDate).format(NUMBER_DATE_FORMAT)
                  : moment(new Date()).format(NUMBER_DATE_FORMAT)}
                maxDate={moment(new Date()).add(5, 'y').format(NUMBER_DATE_FORMAT)}
              />
            )}
            rules={{ required: true }}
          />
        </WppTableBodyCell>
        <WppTableBodyCell style={budgetCell}>
          <Controller
            name={`budgetSettings[${index}].budget`}
            control={control}
            render={(budgetProps) => (
              <WppInput
                disabled={disableBudget}
                className={`${getBudgetCellStyleClass(
                  endDateActive,
                  budgetDeliveryStatus,
                )} budgetIntervalInputTextAlign input-currency`}
                type="decimal"
                size="s"
                onWppChange={(
                  event: WppInputCustomEvent<InputChangeEventDetail>,
                ) => {
                  const data = { value: event.detail.value };
                  onBudgetChange(data, budgetProps.field.onChange);
                }}
                value={!_.isNull(budgetProps.field.value) ? _.toString(budgetProps.field.value) : ''}
                onKeyDown={(event) => numberValidate(event)}
                message={errorMessage}
                messageType={errorMessage ? 'error' : undefined}
                maskOptions={{
                  decimalPatternOptions: {
                    precision: 2,
                    thousandSeparator: '',
                    decimalSeparator: '.',
                  },
                }}
                {..._.omit(budgetProps, 'formState')}
              >
                <WppTypography
                  type="s-strong"
                  slot="icon-end"
                  tag="p"
                  style={inputcurrency}
                >
                  {currency}
                </WppTypography>
              </WppInput>
            )}
          />
        </WppTableBodyCell>
        {strategyId && (
          <WppTableBodyCell style={deliveryCell}>
            <WppTypography type="s-body" tag="p" className="delivery-cell">
              {finishCalculations.current ? deliveryToDisplay : <Loader size="mini" inline active />}
            </WppTypography>
          </WppTableBodyCell>
        )}
        {hasRevTypePermission && (
          <WppTableBodyCell style={revenueCalculationCellStyle}>
            {!_.isEqual(index, 0)
              ? <div style={futureIntervalTextStyle}>{revenueCalculationText}</div>
              : (
                <RevenueTypeConfiguration
                  initialFormValues={initialFormValues}
                  resetConfirmedGoal={resetConfirmedGoal}
                />
              )}
          </WppTableBodyCell>
        )}
        <WppTableBodyCell style={trashIconCell}>
          {(isNewlyAdded || _.isEqual(intervalDelivery, 'Scheduled')) && (
            <WppIconTrash
              about="trash alternate outline"
              onClick={onRemoveInterval}
              color="var(--wpp-grey-color-600)"
            />
          )}
        </WppTableBodyCell>
      </WppTableBodyRow>
    </WppTableBody>
  );
};

export default CrossPlatformBudgetInterval;
