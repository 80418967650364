import React from 'react';
import { Icon, WppButton, WppModal, WppTypography } from 'buildingBlocks';
import { DSP } from 'constantsBase';
import { EXTERNAL_TYPE_TO_DSP_AND_DEFAULT_OPT_LEVEL } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { FLIGHT_DETAIL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { Flight } from 'utils/types';

const {
  activationModalBtns,
  modalBodySection,
  iconStyle,
  activationFlightModelStyle,
  activationModelCancleBtn,
} = FLIGHT_DETAIL_STYLES;

type ActivationFlightModalProps = {
  activationModalOpen: boolean
  setActivationModalOpen: (x:any) => void
  flight: Flight
  removeFlight: Function
  isDeactivation?: boolean
};

const ActivationFlightModal = (props: ActivationFlightModalProps) => {
  const { activationModalOpen, setActivationModalOpen, flight, removeFlight, isDeactivation } = props;
  const dspDisplayName = DSP.getById(flight.dsp).displayName;
  const headerContent = isDeactivation
    ? 'You cannot detach an object with spend from a Cross-Platform strategy'
    : 'Activating an object attached to a Cross-Platform strategy';
  const flightDisplayName = EXTERNAL_TYPE_TO_DSP_AND_DEFAULT_OPT_LEVEL[flight.externalType].optimizationLevel.displayName.single;

  const onRemoveFlight = () => {
    removeFlight();
    setActivationModalOpen(false);
  };

  const onClickIcon = () => {
    setActivationModalOpen(true);
  };

  return (
    <>
      <Icon onClick={onClickIcon} name={isDeactivation ? 'times' : 'plus'} style={iconStyle} />
      <WppModal
        size="s"
        open={activationModalOpen}
      >
        <WppTypography style={activationFlightModelStyle} slot="header" tag="p" type="xl-heading">{headerContent}</WppTypography>
        <div slot="body" style={modalBodySection}>
          {isDeactivation
            ? (
              <>
                <WppTypography tag="p" type="s-body">{`This ${flightDisplayName} cannot be detached because it has spent budget set and managed by this strategy.`}</WppTypography>
                <WppTypography tag="p" type="s-body">Proceeding means <WppTypography tag="span" type="s-strong">{flight.name}</WppTypography>{` will be deactivated in ${dspDisplayName}.`}</WppTypography>
                <WppTypography tag="p" type="s-body">All delivery will continue to be counted toward the relevant budget interval in Copilot as part of this Cross-Platform campaign.</WppTypography>
              </>
            ) : (
              <>
                <WppTypography tag="p" type="s-body">{`This ${flightDisplayName}, `}<WppTypography tag="span" type="s-strong">{flight.name}</WppTypography>{`, will be activated in ${dspDisplayName} after this strategy is saved.`}</WppTypography>
                <WppTypography tag="p" type="s-body">{`Please note upon activation Copilot will consider this ${flightDisplayName} for budget allocation.`}</WppTypography>
              </>
            )}
        </div>
        <div slot="actions" style={activationModalBtns}>
          <WppButton style={activationModelCancleBtn} onClick={() => setActivationModalOpen(false)} variant="secondary">Cancel</WppButton>
          <WppButton onClick={onRemoveFlight} variant="destructive">{isDeactivation ? 'Deactivate' : 'Activate'}</WppButton>
        </div>
      </WppModal>
    </>
  );
};

export default ActivationFlightModal;
