import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { RevTypeOption } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { RevenueTypeConfig } from 'containers/StrategyWizard/types';
import OutcomeOptionRow from './OutcomeOptionRow';

type OutcomesOptionProps = {
  outcomesList: RevenueTypeConfig
  selectedObjects: Array<string>
  setSelectedObjs: Dispatch<SetStateAction<Array<string>>>
  updateSingleOutcome: (extId: string, value: string | number) => void
  revenueTypeOptions: Array<RevTypeOption>
  dsp: number
};

const OutcomesOption = (props: OutcomesOptionProps) => {
  const { outcomesList, selectedObjects, setSelectedObjs, updateSingleOutcome, revenueTypeOptions, dsp } = props;
  return (
    <>
      {_.map(outcomesList, (outcomeDetails, extId) => (
        <OutcomeOptionRow
          key={extId}
          outcomeDetails={outcomeDetails}
          externalId={extId}
          dsp={dsp}
          selectedObjects={selectedObjects}
          setSelectedObjs={setSelectedObjs}
          updateSingleOutcome={updateSingleOutcome}
          revenueTypeOptions={revenueTypeOptions}
        />
      ))}
    </>
  );
};

export default OutcomesOption;
