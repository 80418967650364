import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { WppCardGroup, WppPagination } from 'buildingBlocks';
import { DataRange, FormulaStateType, GoalData } from 'containers/StrategyWizard/steps/GoalSelection/types';
import { GrabFormulaFilters, GRAB_FORMULA_PER_PAGE_OPTIONS } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { CardGroupChangeEventDetail, PaginationChangeEventDetail } from 'utils/types';
import StrategyGoalCard from './StrategyGoalCard';

const { goalDataCardsLayout, grabFormulaPaginationStyle } = FORMULA_SECTION_STYLES;

type GrabFormulaListProps = {
  onDataRangeChange: (obj: DataRange) => void
  goalCount: number
  filterValue: GrabFormulaFilters
  formulaState: FormulaStateType
  stratGoals: Array<GoalData>
  setSelectedGrabFormula: Dispatch<SetStateAction<GoalData>>
};

const GrabFormulaList = (props: GrabFormulaListProps) => {
  const { onDataRangeChange, goalCount, filterValue, formulaState, stratGoals, setSelectedGrabFormula } = props;

  const handleSingleCardGroupChange = (event: CustomEvent<CardGroupChangeEventDetail>) => {
    const selectedValue = event.detail.value; // Get the selected value from the event

    // Find the specific goal object in stratGoals based on the selected value
    const selectedGoal = stratGoals.find((goal) => goal.displayName === selectedValue);
    setSelectedGrabFormula(selectedGoal);
  };

  return (
    <>
      {goalCount > 0 && (
        <>
          <WppCardGroup
            size="m"
            onWppChange={handleSingleCardGroupChange}
            style={goalDataCardsLayout}
          >
            {_.map(stratGoals, (goal, index) => (
              <StrategyGoalCard
                goal={goal}
                selectedFilter={filterValue}
                key={index}
              />
            ))}
          </WppCardGroup>
          <WppPagination
            style={grabFormulaPaginationStyle}
            count={goalCount}
            itemsPerPage={GRAB_FORMULA_PER_PAGE_OPTIONS}
            activePageNumber={formulaState.startingPage}
            selectedItemPerPage={formulaState.limit}
            onWppChange={(event: Event) => {
              const { itemsPerPage, page } = (event as CustomEvent<PaginationChangeEventDetail>).detail;
              onDataRangeChange({ limit: itemsPerPage, start: (page - 1) * itemsPerPage });
            }}
          />
        </>
      )}
    </>
  );
};

export default GrabFormulaList;
