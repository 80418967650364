import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { REVENUE_TYPES_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { WppListItem, WppSelect, WppInput, WppModal, WppButton, WppTypography } from 'buildingBlocks';
import { RevenueBulkUpdateTypes, RevenueEditForm } from 'containers/StrategyWizard/types';
import { RevTypeOption } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { InputChangeEventDetail, SelectChangeEventDetail, WppInputCustomEvent, WppSelectCustomEvent } from 'utils/types';

const { bulkEditModal, bulkEditDropdown, bulkEditInput, bulkEditActionBtn, bulkEditConfirmBtn } = REVENUE_TYPES_STYLES;

type BulkEditProps = {
  setShowBulkUpdate: Dispatch<SetStateAction<boolean>>
  setShowBulkEdit: Dispatch<SetStateAction<boolean>>
  setBulkEditValues: Dispatch<SetStateAction<RevenueEditForm>>
  bulkEditValues: RevenueEditForm
  updateOutcomeObjects: Dispatch<SetStateAction<string>>
  revenueTypeOptions: Array<RevTypeOption>
  resetRevOptions: () => void
};

const BulkEdit = (props: BulkEditProps) => {
  const { setShowBulkUpdate, setShowBulkEdit, bulkEditValues, setBulkEditValues, updateOutcomeObjects, resetRevOptions,
    revenueTypeOptions } = props;
  const [disableConfirm, setDisableConfirm] = useState<boolean>(true);

  useEffect(() => {
    setDisableConfirm(!bulkEditValues.outcome && !bulkEditValues.value);
  }, [bulkEditValues]);

  const onChangeHandler = (data) => {
    const tempEditObj = { ...bulkEditValues };
    if (data.target.type === 'decimal') {
      tempEditObj.value = data.detail.value;
    } else {
      tempEditObj.outcome = data.detail.value;
    }
    setBulkEditValues(tempEditObj);
  };

  return (
    <WppModal open className="bulkEditModal">
      <WppTypography slot="header" tag="p" type="xl-heading">Bulk Edit</WppTypography>
      <div slot="body">
        <div style={bulkEditModal}>
          <div style={bulkEditDropdown}>
            <WppTypography type="s-strong" tag="p">Outcome</WppTypography>
            <WppSelect
              name="outcomes"
              className="revenue-calculation"
              onWppChange={(event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
                const data = event;
                onChangeHandler(data);
              }}
              size="s"
              placeholder=""
            >
              {revenueTypeOptions.map((option) => (
                <WppListItem key={option.value.toString()} value={option.value.toString()}>
                  <p slot="label">{option.text}</p>
                </WppListItem>
              ))}
            </WppSelect>
          </div>
          <div style={bulkEditInput}>
            <WppTypography type="s-strong" tag="p">Value</WppTypography>
            <WppInput
              type="decimal"
              size="s"
              onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) => {
                onChangeHandler(event);
              }}
              value={bulkEditValues.value?.toString() || ''}
              maskOptions={{
                decimalPatternOptions: {
                  precision: 4,
                  thousandSeparator: '',
                  decimalSeparator: '.',
                },
              }}
            />
          </div>
        </div>
        <WppTypography type="s-body" tag="p">The outcome and value will apply to the selected campaigns.</WppTypography>
      </div>
      <div slot="actions" style={bulkEditActionBtn}>
        <WppButton variant="secondary" size="s" style={bulkEditConfirmBtn} onClick={() => { setShowBulkUpdate(true); setShowBulkEdit(false); }}>Cancel</WppButton>
        <WppButton
          size="s"
          onClick={() => { updateOutcomeObjects(RevenueBulkUpdateTypes.update); resetRevOptions(); }}
          disabled={disableConfirm}
        >
          Confirm
        </WppButton>
      </div>
    </WppModal>
  );
};

export default BulkEdit;
