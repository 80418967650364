import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { WppGrid, WppTypography, ValidatedCustomWppInput } from 'buildingBlocks';
import { GOAL_SETTINGS } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { InputChangeEventDetail, WppInputCustomEvent } from 'utils/types';
import OptimizationOptions from './OptimizationOptions';

const { labelStyle, columnStyle, goalSettingInput } = GOAL_SETTINGS;

// memoize to prevent re-rendering during form updates and keep input focus
const GoalSettings = React.memo(() => {
  const { control, trigger } = useFormContext<WizardFormGoalSelection>();
  return (
    <>
      <WppTypography type="m-strong" tag="p">Goal Settings</WppTypography>
      <WppGrid>
        <div style={columnStyle}>
          <WppTypography type="s-strong" tag="p" style={labelStyle}>Goal Name</WppTypography>
          <Controller
            name="customGoal.name"
            control={control}
            rules={{
              required: { value: true, message: 'Goal name is required.' },
              maxLength: {
                value: 30,
                message: 'Must not exceed 30 characters.',
              },
            }}
            render={({ field, fieldState }) => (
              <ValidatedCustomWppInput
                type="text"
                placeholder="Name your goal"
                value={field.value}
                onWppChange={(e: WppInputCustomEvent<InputChangeEventDetail>) => {
                  field.onChange(e.detail.value);
                  trigger('customGoal.name');
                }}
                skipIsDirtyCheck
                field={field}
                fieldState={fieldState}
                size="m"
                style={goalSettingInput}
              />
            )}
          />
        </div>
        <div style={columnStyle}>
          <WppTypography type="s-strong" tag="p" style={labelStyle}>Goal Acronym (Max length 5)</WppTypography>
          <Controller
            name="customGoal.value"
            control={control}
            rules={{
              required: { value: true, message: 'Goal acronym is required.' },
              maxLength: {
                value: 5,
                message: 'Must not exceed 5 characters.',
              },
              pattern: {
                value: /^\S*$/,
                message: 'No spaces allowed',
              },
            }}
            render={({ field, fieldState }) => (
              <ValidatedCustomWppInput
                type="text"
                placeholder="Give your goal a short name or acronym"
                value={field.value}
                onWppChange={(e: WppInputCustomEvent<InputChangeEventDetail>) => {
                  field.onChange(e.detail.value);
                  trigger('customGoal.value');
                }}
                skipIsDirtyCheck
                size="m"
                field={field}
                fieldState={fieldState}
                style={goalSettingInput}
              />
            )}
          />
        </div>
        <WppTypography type="s-strong" tag="p" style={labelStyle}>How would you like Copilot to optimize the campaign?</WppTypography>
        <OptimizationOptions />
      </WppGrid>
    </>
  );
});

export default GoalSettings;
