/* eslint-disable react/prop-types */
import _ from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { WppGrid, WppInput, WppTypography } from 'buildingBlocks';
import { validatedWppFieldWrapper } from 'utils/wrapperComponentsReactHookForms';
import { GOAL_SETTINGS } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import OptimizationOptions from './OptimizationOptions';

const { labelStyle, columnStyle, goalSettingInput } = GOAL_SETTINGS;

// Memoizing this component will prevent the  re-rendering every time the form updates, thereby preventing the input fields from losing focus
const GoalSettings = React.memo(() => {
  const ValidatedInput = validatedWppFieldWrapper(WppInput);
  const { control } = useFormContext();

  return (
    <>
      <WppTypography type="m-strong" tag="p">Goal Settings</WppTypography>
      <WppGrid>
        <div style={columnStyle}>
          <WppTypography type="s-strong" tag="p" style={labelStyle}>Goal Name</WppTypography>
          <Controller
            name="customGoal.name"
            control={control}
            rules={{
              required: 'Goal Name is required.',
              maxLength: {
                value: 30,
                message: 'Must not exceed 30 characters.',
              },
            }}
            render={(props) => (
              <ValidatedInput
                type="text"
                placeholder="Name your goal"
                value={props.field.value}
                onChange={props.field.onChange}
                skipIsDirtyCheck
                {..._.omit(props, 'formState')}
                fluid
                size="m"
                style={goalSettingInput}
              />
            )}
          />
        </div>
        <div style={columnStyle}>
          <WppTypography type="s-strong" tag="p" style={labelStyle}>Goal Acronym (Max length 5)</WppTypography>
          <Controller
            name="customGoal.value"
            control={control}
            rules={{
              required: 'Goal Acronym is required.',
              maxLength: {
                value: 5,
                message: 'Must not exceed 5 characters.',
              },
              pattern: {
                value: /^\S*$/,
                message: 'No spaces allowed',
              },
            }}
            render={(props) => (
              <ValidatedInput
                type="text"
                placeholder="Give your goal a short name or acronym"
                value={props.field.value}
                onChange={props.field.onChange}
                skipIsDirtyCheck
                size="m"
                {..._.omit(props, 'formState')}
                fluid
                style={goalSettingInput}
              />
            )}
          />
        </div>
        <WppTypography type="s-strong" tag="p" style={labelStyle}>How would you like Copilot to optimize the campaign?</WppTypography>
        <OptimizationOptions />
      </WppGrid>
    </>
  );
});

export default GoalSettings;
