import _ from 'lodash';
import React from 'react';
import { WppGrid, WppTypography } from 'buildingBlocks';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { ALL_OPERATORS } from 'containers/StrategyWizard/constants';
import DraggableComponent from './DraggableComponent';

const { verticalDivider, operatorContainer } = FORMULA_SECTION_STYLES;

const OperatorsSection = () => (
  <WppGrid direction="column" item all={4} style={verticalDivider}>
    <WppTypography type="s-strong" tag="p">Operators</WppTypography>
    <div style={operatorContainer}>
      {_.map(ALL_OPERATORS, (operator) => (
        <DraggableComponent key={operator} dragContent={operator} />
      ))}
    </div>
  </WppGrid>
);

export default OperatorsSection;
