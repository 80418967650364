import React, { MouseEventHandler } from 'react';
import { WppActionButton, WppIconInfo, WppTypography, WppIconTrash, WppTooltip, WppTableBodyCell, WppTable, WppTableBody, WppTableBodyRow } from 'buildingBlocks';
import DspIcon from 'components/DspIcon';
import FlightDetailsPopUp from 'components/FlightDetailsPopUp';
import { getDspFromExtTypeId, getFlightPopupMessage } from 'containers/StrategyWizard/utils';
import { COPILOT_LAYOUT } from 'globalStyles';
import { Flight } from 'utils/types';
import style from 'containers/StrategyWizard/styles';
import { FlightStatus } from './FlightStatus';

type DefaultFlightDetailsWrapperProps = {
  flight: Flight
  advertiserExtId: string
  memberExtId: string
};

const DefaultFlightDetailsWrapper = ({
  flight,
  advertiserExtId,
  memberExtId,
}: DefaultFlightDetailsWrapperProps) => (
  <div style={style.flightRowTooltip}>
    <WppTooltip data-testid="tooltip" config={{ placement: 'bottom', allowHTML: true }} theme="light">
      <WppIconInfo style={style.flightRowInfoIcon} />
      <div slot="tooltip-content">
        <FlightDetailsPopUp
          messages={getFlightPopupMessage(flight, advertiserExtId, memberExtId)}
          header="DSP Object Details"
        />
      </div>
    </WppTooltip>
    <WppTypography style={style.flightRowDetailsTypoStyle} type="xs-body" tag="span">{`[${flight.externalId}] - ${flight.name}`}</WppTypography>
  </div>
);

type Props = {
  flightStatus: Flight
  strategy: number
  flightOverrideStrategy: MouseEventHandler<HTMLWppActionButtonElement>
  color: string
  alreadyAttached: boolean
  notEligible: boolean
  flight: Flight
  advertiserExtId: string
  memberExtId: string
  removeFlight?: MouseEventHandler<HTMLWppActionButtonElement>
};

export const FlightRow = ({
  flightStatus,
  strategy,
  flightOverrideStrategy,
  color,
  alreadyAttached,
  notEligible,
  flight,
  advertiserExtId,
  memberExtId,
  removeFlight,
}: Props) => (
  <WppTable>
    <WppTableBody style={style.flightRowTableBodyStyle}>
      <WppTableBodyRow style={style.flightBodyRowStyle}>
        <WppTableBodyCell style={style.flightRowBodyStyle}>
          <div
            style={{ ...style.flightRowBorderStyle, borderLeft: `4px solid ${color}` }}
          >
            <DefaultFlightDetailsWrapper flight={flight} advertiserExtId={advertiserExtId} memberExtId={memberExtId} />
            <FlightStatus
              status={flightStatus}
              strategy={strategy}
              flightOverrideStrategy={flightOverrideStrategy}
              removeFlight={removeFlight}
              alreadyAttached={alreadyAttached}
              notEligible={notEligible}
            />
          </div>
          <div style={{
            ...style.flightRowIconStyle,
            ...(alreadyAttached && { top: '25px' }),
          }}
          >
            <DspIcon dspId={getDspFromExtTypeId(flight.externalType)} style={{ marginRight: COPILOT_LAYOUT.SPACING[8] }} />
            {
              flightStatus && removeFlight
              && (
                <WppActionButton type="button" variant="secondary" onClick={removeFlight}>
                  <WppIconTrash style={style.flightRowTrashIcon} />
                </WppActionButton>
              )
            }
          </div>
        </WppTableBodyCell>
      </WppTableBodyRow>
    </WppTableBody>
  </WppTable>
);

export default FlightRow;
