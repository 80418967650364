import _ from 'lodash';
import React, { Dispatch, MutableRefObject, SetStateAction, useMemo, useState } from 'react';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { useFormulaContext } from '../contexts/FormulaProvider';
import DropzoneComponent from './DropzoneComponent';
import CustomInputElement from './CustomInputElement';
import './customInputElement.css';

const { dropzoneAreaContainer, dropzoneArea, dropzoneComponentContainer } = FORMULA_SECTION_STYLES;

type DroppableComponentsProps = {
  shouldBlink: boolean
  setShouldBlink: Dispatch<SetStateAction<boolean>>
  selected: boolean
  setSelected: Dispatch<SetStateAction<boolean>>
  lastDroppedItemBottom: MutableRefObject<number>
};

const DroppableComponents = ({ shouldBlink, setShouldBlink, selected, setSelected, lastDroppedItemBottom }: DroppableComponentsProps) => {
  const { cursorIdx, dropItems, dropzoneActive } = useFormulaContext();
  // using this instead of collected.isDragging since it doesn't register when current component is not on top of another DropzoneComponent
  const [draggingSomething, setDraggingSomething] = useState<boolean>(false);
  const showEndCursor = dropzoneActive && (_.isNil(cursorIdx) || cursorIdx >= dropItems.length);

  const CursorComponent = useMemo(() => (
    shouldBlink
      ? (
        <CustomInputElement
          shouldBlink={shouldBlink}
          setShouldBlink={setShouldBlink}
        />
      )
      : <div id="solid-cursor" />
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [shouldBlink]);

  return (
    <div style={dropzoneAreaContainer}>
      <div style={dropzoneArea}>
        {_.map(dropItems, (item, idx: number) => (
          <div style={dropzoneComponentContainer} key={item?.id}>
            {(dropzoneActive && _.isEqual(cursorIdx, idx) && !selected) && CursorComponent}
            <DropzoneComponent
              dragContent={item?.content}
              contentIdx={idx}
              contentId={item?.id}
              selected={selected}
              setSelected={setSelected}
              draggingSomething={draggingSomething}
              setDraggingSomething={setDraggingSomething}
              lastDroppedItemBottom={lastDroppedItemBottom}
            />
          </div>
        ))}
        {showEndCursor && (CursorComponent)}
      </div>
    </div>
  );
};

export default DroppableComponents;
