import _ from 'lodash';
import React, { useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Form, ObjectDropdown, WppLabel } from 'buildingBlocks';
import { DSP } from 'constantsBase';
import { WizardFormAttachFlights } from 'containers/StrategyWizard/types';
import { MODAL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { ALL, ExternalTypeOptimizationLevel, getAllOption, modalSessionFlightsInfoInitialState, OPTIMIZATION_LEVELS } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { getBrands } from 'containers/StrategyWizard/steps/AttachFlights/actions';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { Brand } from 'utils/types';

type Option = {
  text: string
  value: string
};

const handleSearchChange = (options: Array<Option>, searchQuery: string) => {
  const search = _.toLower(searchQuery);
  return _.filter(options, (option: Option) => _.includes(_.lowerCase(option.text), search));
};

type BrandFormFieldProps = {
  hasFlightsAttached: boolean
  setMemAdvBrandFilter: (x: any) => void
  setModalSessionFlightsInfo: (x: any) => void
  setSessionAttachFlights: (x: any) => void
  setDefaultCurrencyId: (x: any) => void
  strategyId?: number
};

const BrandFormField = (props: BrandFormFieldProps) => {
  const {
    strategyId,
    hasFlightsAttached,
    setMemAdvBrandFilter,
    setModalSessionFlightsInfo,
    setSessionAttachFlights,
    setDefaultCurrencyId,
  } = props;

  const { control, reset } = useFormContext<WizardFormAttachFlights>();
  const selectedBrand = useWatch({ name: 'brand' });
  const newStratWithNoFlights = !hasFlightsAttached && _.isNil(strategyId);
  const [brandCandidates, setBrandCandidates] = useState<Array<Brand>>([]);
  const [loadingBrands, setLoadingBrands] = useState<boolean>(true);
  const disabled = hasFlightsAttached || !_.isNil(strategyId);
  const disabledTooltipWithBrand = strategyId
    ? 'Brand cannot be changed in edit mode.'
    : `You have selected objects associated with the brand, ${selectedBrand?.name}. Further selections will now be limited to ${selectedBrand?.name}. Selections will be cleared if you switch to another brand.`;
  const brandTooltip = !selectedBrand ? 'There is no brand associated with the advertiser of one or more objects attached.' : disabledTooltipWithBrand;
  const classForStylingOverride = (newStratWithNoFlights) ? 'conditionally-loading-flights' : '';
  const options = selectedBrand ? [getAllOption(), ...brandCandidates] : brandCandidates;
  const handleBrandSelect = (brand: Brand) => {
    const allSelected = _.isEqual(brand.name, ALL);
    const newFormValues = {
      brand: allSelected ? null : brand,
      member: null,
      advertiser: null,
      optimizationLevel: OPTIMIZATION_LEVELS[DSP.MULTIPLE.id][ExternalTypeOptimizationLevel.HIGHER_ORDER],
      attachedFlights: [],
    };
    setDefaultCurrencyId(null);
    reset(newFormValues);
    setMemAdvBrandFilter(null);
    setModalSessionFlightsInfo(modalSessionFlightsInfoInitialState);
    setSessionAttachFlights([]);
  };

  useMount(() => {
    if (!disabled) {
      getBrands(setBrandCandidates, setLoadingBrands);
    }
  });

  return (
    <Form.Field>
      <WppLabel
        config={{
          text: 'Brand',
          icon: disabled ? 'wpp-icon-info' : '',
          description: brandTooltip,
        }}
        htmlFor="name"
        typography="s-strong"
        style={MODAL_STYLES.attachFormFeildLabel}
      />
      <Controller
        name="brand"
        control={control}
        rules={{ required: true }}
        defaultValue={selectedBrand}
        render={() => (
          <ObjectDropdown
            fluid
            selection
            className={classForStylingOverride}
            options={options}
            value={selectedBrand}
            keyFn={(brand) => brand.name}
            onChange={handleBrandSelect}
            selectOnBlur={false}
            disabled={disabled || loadingBrands}
            text={selectedBrand ? selectedBrand.name : 'Search Brands...'}
            placeholder={selectedBrand ? null : 'Search Brands...'}
            loading={loadingBrands && !disabled}
            search={{
              searchType: 'local',
              onSearchChange: handleSearchChange,
            }}
          />
        )}
      />
    </Form.Field>
  );
};

export default BrandFormField;
