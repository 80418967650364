// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#attach-flights-modal .dropdown .divider.text {
  max-height: 17px;
  overflow: hidden;
  line-height: 17px;
}`, "",{"version":3,"sources":["webpack://./src/containers/StrategyWizard/steps/AttachFlights/style.scss"],"names":[],"mappings":"AAEI;EACI,gBAAA;EACA,gBAAA;EACA,iBAAA;AADR","sourcesContent":["#attach-flights-modal {\n  .dropdown {\n    .divider.text {\n        max-height: 17px;\n        overflow: hidden;\n        line-height: 17px;\n    }\n  } \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
