import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { Image, WppActionButton, WppIconRedo, WppIconUndo } from 'buildingBlocks';
import { DELETE_ICON, DISABLED_DELETE_ICON } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { swapActionObjIndexes } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { useFormulaContext } from '../contexts/FormulaProvider';
import { ActionTypes, FormulaBuilderAction } from '../contexts/types';

const { cursorStyle, dropzoneActionsContainer } = FORMULA_SECTION_STYLES;

type DropzoneActionsProps = {
  handleDelete: Function
  selected: boolean
  setSelected: Dispatch<SetStateAction<boolean>>
};

const DropzoneActions = React.memo(({ handleDelete, selected, setSelected }: DropzoneActionsProps) => {
  const {
    undoActions, redoActions, dropzoneActive, dropItems, cursorIdx,
    handleRemoveItem, setCursorIdx, setUndoActions, handleReorder,
    addRedoAction, setRedoActions, addUndoAction, insertItemAt,
  } = useFormulaContext();
  const isDeleteActive = _.size(dropItems) && dropzoneActive;

  const undoAddItem = (item: FormulaBuilderAction) => {
    handleRemoveItem(item.contentId);
    setCursorIdx(item.cursorIdx);
  };

  const undoDeleteItem = (item: FormulaBuilderAction) => {
    insertItemAt({ id: item.contentId, content: item.content }, item.contentIdx);
    setCursorIdx(item.cursorIdx);
  };

  const undoSwapItem = (item: FormulaBuilderAction) => {
    handleReorder(item.cursorIdx, item.contentIdx);
    setCursorIdx(item.contentIdx);
  };

  const handleUndo = () => {
    const actionToUndo = _.last(undoActions) as FormulaBuilderAction;
    switch (actionToUndo.action) {
      case ActionTypes.add:
        undoAddItem(actionToUndo);
        break;
      case ActionTypes.delete:
        undoDeleteItem(actionToUndo);
        break;
      case ActionTypes.swap:
        undoSwapItem(actionToUndo);
        swapActionObjIndexes(actionToUndo);
        break;
      default:
        break;
    }
    addRedoAction(actionToUndo);
    setUndoActions(_.dropRight(undoActions));
  };

  const handleRedo = () => {
    const actionToRedo = _.last(redoActions) as FormulaBuilderAction;
    switch (actionToRedo.action) {
      case ActionTypes.add:
        undoDeleteItem(actionToRedo);
        break;
      case ActionTypes.delete:
        undoAddItem(actionToRedo);
        break;
      case ActionTypes.swap:
        undoSwapItem(actionToRedo);
        swapActionObjIndexes(actionToRedo);
        break;
      default:
        break;
    }
    addUndoAction(actionToRedo);
    setRedoActions(_.dropRight(redoActions));
  };

  return (
    <div style={dropzoneActionsContainer}>
      <WppActionButton
        variant="secondary"
        disabled={_.isEmpty(undoActions)}
        onClick={handleUndo}
      >
        <WppIconUndo />
      </WppActionButton>
      <WppActionButton
        variant="secondary"
        disabled={_.isEmpty(redoActions)}
        onClick={handleRedo}
      >
        <WppIconRedo slot="icon-start" />
      </WppActionButton>
      {isDeleteActive
        ? <Image alt="delete" src={DELETE_ICON} style={cursorStyle} onClick={() => handleDelete(selected, dropItems, cursorIdx, handleRemoveItem, setSelected)} />
        : <Image alt="disabled delete" src={DISABLED_DELETE_ICON} />}
    </div>
  );
});

export default DropzoneActions;
