import _ from 'lodash';
import fp from 'lodash/fp';
import { RESULTS_LIMIT } from 'constantsBase';
import { AttachFlightsInfoType, ModalSessionFlightsInfoType } from 'containers/StrategyWizard/types';
import { flightKey, isCrossPlatformStrategyType } from 'containers/StrategyWizard/utils';
import { Brand, Eligibility, FlightCandidate } from 'utils/copilotAPI';
import { Flight, Brand as BrandType } from 'utils/types';
import { MEMBER } from 'utils/featureFlags';
import { FLIGHT_ATTACH_STATUS } from './constants';
import { mergeFlights } from './components/AttachFlightsModal/utils';

const createExcludeExternalIds = (selectedFlights: Array<Flight>) => {
  // fp doesn't provide map(object) by default: https://github.com/lodash/lodash/issues/2686
  // that is why we need a toPairs here
  // then we transform it back into an object because that's the format qs is expecting
  // We also need to convert the key into a string because when node is unparsing it, it will get convert to an array
  // otherwise
  const excludeExternalIds = fp.flow([
    fp.groupBy('externalType'),
    fp.toPairs,
    fp.map(([key, value]) => [`'${key}'`, _.map(value, 'externalId')]),
    fp.fromPairs,
  ])(selectedFlights);

  return excludeExternalIds;
};

export const getFlightCandidates = async (
  attachedFlights,
  filter,
  modalSessionFlightsInfo: ModalSessionFlightsInfoType,
  setModalSessionFlightsInfo: (x: any) => void,
  strategyId?: number | string,
  externalTypeIds?: Array<number>,
  isCrossPlatform = false,
  defaultCurrencyId = null,
  limit = 25,
  sort = 'externalId DESC',
  // eslint-disable-next-line consistent-return
): Promise<Array<Flight>> => {
  await setModalSessionFlightsInfo({ ...modalSessionFlightsInfo, loadingFlightCandidates: true });
  const searchTerm = _.get(filter, 'searchTerm', '');
  try {
    const excludeExternalIds = createExcludeExternalIds(attachedFlights);
    let candidates = await FlightCandidate.get({
      strategyId,
      ...filter,
      excludeExternalIds,
      searchTerm,
      limit,
      sort,
      externalTypeIds,
      isCrossPlatform,
      ...((isCrossPlatform && defaultCurrencyId) && { defaultCurrency: defaultCurrencyId }),
    });
    if (candidates.data.length === 0 && searchTerm) {
      candidates = await FlightCandidate.get({
        strategyId, ...filter, excludeExternalIds, limit, sort, externalTypeIds, isCrossPlatform,
      });
    }
    setModalSessionFlightsInfo({ ...modalSessionFlightsInfo, searchTerm, flightCandidates: candidates.data, loadingFlightCandidates: false });
    // eslint-disable-next-line no-promise-executor-return
    return await new Promise((resolve) => resolve(candidates.data));
  } catch (error) {
    setModalSessionFlightsInfo({ ...modalSessionFlightsInfo, searchTerm, loadingFlightCandidates: false });
    // eslint-disable-next-line no-console
    console.error('Failed to fetch flight candidates', error);
  }
};

export const getBrandFlightCandidates = async (
  attachedFlights,
  brandId: number,
  defaultCurrencyId: number | null,
  modalSessionFlightsInfo: ModalSessionFlightsInfoType,
  setModalSessionFlightsInfo: (x: any) => void,
  validMembers: Array<number> | '*' | false,
  selectedMemId: number,
  selectedAdvId: number,
  searchTerm: string = '',
  // eslint-disable-next-line consistent-return
): Promise<Array<Flight>> => {
  await setModalSessionFlightsInfo({ ...modalSessionFlightsInfo, loadingFlightCandidates: true });
  try {
    const excludeExternalIds = createExcludeExternalIds(attachedFlights);
    const baseParams = { brandId, excludeExternalIds, searchTerm, limit: 25, sort: 'externalId DESC', selectedMemId, selectedAdvId };
    const params = defaultCurrencyId ? { ...baseParams, defaultCurrency: defaultCurrencyId } : baseParams;
    const candidatesRes = await FlightCandidate.brandFlightCandidate(params);
    const candidates = _.isEqual(validMembers, MEMBER.ALL)
      ? candidatesRes.data
      : _.filter(candidatesRes.data, (fc) => (validMembers && _.includes(validMembers, fc.member)));
    setModalSessionFlightsInfo({ ...modalSessionFlightsInfo, searchTerm, flightCandidates: candidates, loadingFlightCandidates: false });
    // eslint-disable-next-line no-promise-executor-return
    return await new Promise((resolve) => resolve(candidates));
  } catch (error) {
    setModalSessionFlightsInfo({ ...modalSessionFlightsInfo, searchTerm, loadingFlightCandidates: false });
    // eslint-disable-next-line no-console
    console.error('Failed to fetch flight candidates', error);
  }
};

export const checkEligibility = async (
  flightsSelected: Array<Flight>,
  flightsInfo: ModalSessionFlightsInfoType | AttachFlightsInfoType,
  setFlightsInfo: (arg: any) => void,
  strategyTypeId?: number | string,
  strategyId?: number | string,
) => {
  const isAttachFlightsInfo = !_.has(flightsInfo, 'searchTerm');
  const { flightsStatus } = flightsInfo;
  const flightsStatusKey = _.keys(flightsStatus);
  const isCrossPlatformStrat = isCrossPlatformStrategyType(strategyTypeId);
  const editingCrossPlatformStrat = strategyId && isCrossPlatformStrat;

  // User removed one flight, we don't need to send a request, we just need to remove it from flightsStatus
  // Specific to ModalSessionFlightsInfoType
  if (flightsSelected && flightsStatusKey.length > flightsSelected.length) {
    const flightsStatusPayload = {};
    const flightsSelectedByKey = _.keyBy(flightsSelected, flightKey);
    _.forEach(flightsStatusKey, (key) => {
      if (flightsSelectedByKey[key]) {
        flightsStatusPayload[key] = flightsStatus[key];
      }
    });

    setFlightsInfo({ ...flightsInfo, flightsStatus: flightsStatusPayload, pendingFlights: [] });
  } else {
    // New flight added, we need to check which flight it is and send the request for this unique flight
    // In an edit step, we might need to fetch all flight that have an unknown status
    const flights = _.filter(flightsSelected, (flight) => _.isUndefined(flightsStatus[flightKey(flight)]));
    if (flights.length > 0) {
      if (!isAttachFlightsInfo) {
        setFlightsInfo({ ...flightsInfo, pendingFlights: _.uniqBy(_.concat(_.get(flightsInfo, 'pendingFlights'), flights), 'id') });
      }
      try {
        // this is a workaround for larger numbers of flights. Currently there is no need to send more then extId and
        // externalType. So we remove all other fields so we can make requests with more flights.
        const reducedPayloadFlights = _.map(flights, (f) => _.pick(f, ['externalId', 'externalType', 'member', 'advertiser']));
        // if in create mode or strategy type is cross-platform, all flights should be checked for their eligibility
        const eligibility = (isCrossPlatformStrat || !strategyId)
          ? await Eligibility.flightFirst({ flights: reducedPayloadFlights })
          : await Eligibility.checkFlightsEligibility(strategyTypeId.toString(), { flights: reducedPayloadFlights });

        const flightsStatusPayload = { ...flightsStatus };
        const ineligibleFlights = [];
        const eligibleFlights = [];
        const attachedToThisStrategy = [];
        const attachedToAnotherStrategy = [];
        const deactivatedFlights = [];
        _.forEach(eligibility.data, (flight) => {
          const key = flightKey(flight);
          const flightObj = { ...flight };
          const flightMetadata = _.find(flightsSelected, (f) => flightKey(f) === key);
          if (flight.eligible) {
            if (flightMetadata.strategy === +strategyId) {
              // populate deactivated flights when initializing exisiting cross-platform strategy based on the active flag
              if (isAttachFlightsInfo && editingCrossPlatformStrat && !_.get(flightMetadata, 'active')) {
                deactivatedFlights.push(flightMetadata);
                flightObj.status = FLIGHT_ATTACH_STATUS.REMOVE_OTHER.style;
              } else {
                attachedToThisStrategy.push(flightMetadata);
                flightObj.status = FLIGHT_ATTACH_STATUS.ATTACHED.style;
              }
            } else if (_.isFinite(flightMetadata.strategy) && flightMetadata.strategy !== strategyId) {
              // if flights info type is AttachFlightsInfo add flight to eligible flight
              (isAttachFlightsInfo ? eligibleFlights : attachedToAnotherStrategy).push(flightMetadata);
            } else {
              flightObj.status = FLIGHT_ATTACH_STATUS.ADD.style;
              eligibleFlights.push(flightMetadata);
            }
          } else {
            flightObj.status = FLIGHT_ATTACH_STATUS.INELIGIBLE.style;
            ineligibleFlights.push(flightMetadata);
          }
          flightsStatusPayload[key] = flightObj;
        });

        const updatedFlightsInfo = {
          ...flightsInfo,
          eligibleFlights: mergeFlights(flightsInfo.eligibleFlights, eligibleFlights),
          flightsStatus: flightsStatusPayload,
          pendingFlights: [],
          ineligibleFlights: mergeFlights(flightsInfo.ineligibleFlights, ineligibleFlights),
          attachedToThisStrategy: mergeFlights(flightsInfo.attachedToThisStrategy, attachedToThisStrategy),
          ...((isAttachFlightsInfo && editingCrossPlatformStrat) && { deactivatedFlights }),
        };

        const updatedFlightsInfoForModalSession = {
          ...updatedFlightsInfo,
          attachedToAnotherStrategy: mergeFlights(_.get(flightsInfo, 'attachedToAnotherStrategy'), attachedToAnotherStrategy),
        };

        setFlightsInfo(isAttachFlightsInfo ? updatedFlightsInfo : updatedFlightsInfoForModalSession);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }
};

export const getBrands = async (
  setBrandCandidates: (x: any) => void,
  setLoadingBrands: (x: any) => void,
) => {
  try {
    const brands = await Brand.get({
      limit: RESULTS_LIMIT,
      skip: 0,
      sort: 'name asc',
      populate: 'advertisers',
      isDeleted: false,
    });
    setBrandCandidates(brands.data as Array<BrandType>);
    setLoadingBrands(false);
  } catch (error) {
    console.error('Failed to fetch brands', error);
    setLoadingBrands(false);
  }
};
