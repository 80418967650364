import _ from 'lodash';
import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Accordion, Icon, WppDivider, WppTypography, WppListItem } from 'buildingBlocks';
import { APP_NAME } from 'constantsBase';
import { flightKey } from 'containers/StrategyWizard/utils';
import { WizardFormAttachFlights, FlightsStatusType, ModalSessionFlightsInfoType } from 'containers/StrategyWizard/types';
import style from 'containers/StrategyWizard/styles';
import { configuringLineItemStratCheck } from 'containers/StrategyWizard/steps/AttachFlights/utils';
import { OptimizationType } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { changeFlightStatusUtilHelper } from 'containers/StrategyWizard/steps/AttachFlights/components/AttachFlightsModal/utils';
import { COPILOT_COLORS } from 'globalStyles';
import { Flight, FlightDisplayName } from 'utils/types';
import { FlightRow } from './FlightRow';

const { NEW_DESIGN_SYSTEM: { NEUTRALS }, WPP } = COPILOT_COLORS;

type AttachFlightsModalTableProps = {
  selectedOptType: OptimizationType
  flightDisplayName: FlightDisplayName
  modalSessionFlightsInfo: ModalSessionFlightsInfoType
  setModalSessionFlightsInfo: (x: any) => void
  sessionAttachFlights: Array<Flight>
  setSessionAttachFlights: (x: any) => void
};

type ChangeFlightStatus = (s: string, b?: boolean) => void;

const LoadingFlight = () => (
  <WppListItem className="flight-row">
    <span slot="label" style={style.flightRow}>
      <div style={style.flightRowColor} />
      Loading...
    </span>
  </WppListItem>
);

type FlightRowTableLoadingProps = {
  flights: Array<Flight>
};

const FlightRowTableLoading = ({
  flights,
}: FlightRowTableLoadingProps) => (flights.length > 0 && (
  <div style={style.flightRowContainer}>
    {_.map(flights, (_flight, index: number) => (<LoadingFlight key={index} />))}
  </div>
)
);

type FlightRowTableProps = {
  flights: Array<Flight>
  detachable: boolean
  removeFlight?: ChangeFlightStatus
  flightsStatus: FlightsStatusType
  flightOverrideStrategy?: Function
  color: string
  alreadyAttached?: boolean
  notEligible?: boolean
  advertiserExtId?: string
  memberExtId?: string
};

const FlightRowTable = ({
  flights,
  detachable,
  removeFlight,
  flightsStatus,
  flightOverrideStrategy,
  color,
  alreadyAttached,
  notEligible,
  advertiserExtId,
  memberExtId,
}: FlightRowTableProps) => (flights.length > 0 && (
  <>
    {_.map(flights, (flight: Flight, index: number) => {
      const key = flightKey(flight);
      const flightStatus = _.get(flightsStatus, key, {}) as Flight;
      return (
        <div key={`${key} - ${flight.id}`}>
          <FlightRow
            flightStatus={flightStatus}
            strategy={flight.strategy}
            flightOverrideStrategy={() => flightOverrideStrategy(key)}
            color={color}
            alreadyAttached={alreadyAttached}
            notEligible={notEligible}
            flight={flight}
            advertiserExtId={advertiserExtId}
            memberExtId={memberExtId}
            removeFlight={detachable ? () => removeFlight(key) : undefined}
          />
          {!_.isEqual(_.size(flights), index + 1) && <WppDivider style={style.flightRowDividerStyle} />}
          {alreadyAttached && <WppDivider style={style.flightRowDividerStyle} />}
        </div>
      );
    })}
  </>
));

export type FlightListName =
  | 'eligibleFlights'
  | 'ineligibleFlights'
  | 'attachedToThisStrategy'
  | 'attachedToAnotherStrategy'
  | 'toBeDetached';

export const ALL_FLIGHT_LIST_NAMES = [
  'eligibleFlights',
  'ineligibleFlights',
  'attachedToThisStrategy',
  'attachedToAnotherStrategy',
  'toBeDetached',
];

export type FlightLists = {
  eligibleFlights: Array<Flight>
  ineligibleFlights: Array<Flight>
  attachedToThisStrategy: Array<Flight>
  toBeDetached: Array<Flight>
  attachedToAnotherStrategy?: Array<Flight>
  reactivatedFlights?: Array<Flight>
  toBeDeactivated?: Array<Flight>
  deactivatedFlights?: Array<Flight>
  eligCPFlightsWithoutAmountBudgetType?: Array<Flight>
  eligCPFlightsWithSpend?: Array<Flight>
};

export const matchesFlightKey = (key: string) => (flight: Flight) => flightKey(flight) === key;

type ModifyFlightLists = (flightList: FlightLists, flightKey: string, s: string, ss: string | null) => FlightLists;

/**
 * modifyFlightLists moves a flight from one list to a different one, or if only from is provided it will removed
 * the flight from that list
 * @param flightLists FlightLists
 * @param key flightKey
 * @param from list to remove flight from
 * @param ?to list to add flight to. If not provided flight will not be added to any list
 */
export const modifyFlightLists: ModifyFlightLists = (flightLists, key, from, to) => {
  const matchesFlight = matchesFlightKey(key);
  const updatedFlightLists = { ...flightLists };
  const flight = _.find(updatedFlightLists[from], matchesFlight);
  if (flight) {
    updatedFlightLists[from] = _.filter(updatedFlightLists[from], _.negate(matchesFlight));
    if (to) {
      updatedFlightLists[to] = [flight, ...updatedFlightLists[to]];
    }
  }
  return updatedFlightLists;
};

export const AttachFlightsModalTable = (props: AttachFlightsModalTableProps) => {
  const {
    selectedOptType,
    flightDisplayName,
    modalSessionFlightsInfo,
    setModalSessionFlightsInfo,
    sessionAttachFlights,
    setSessionAttachFlights,
  } = props;

  const [accordionOpen, setAccordionOpen] = useState<boolean>(false);
  const { flightsStatus, ineligibleFlights, eligibleFlights, pendingFlights, attachedToAnotherStrategy, attachedToThisStrategy } = modalSessionFlightsInfo;
  const member = useWatch({ name: 'member' });
  const advertiser = useWatch({ name: 'advertiser' });
  const { setValue } = useFormContext<WizardFormAttachFlights>();
  const allEligibleFlights = _.concat(eligibleFlights, attachedToThisStrategy);

  const getCurrentFlightLists = () => (_.pick(modalSessionFlightsInfo, ALL_FLIGHT_LIST_NAMES)) as FlightLists;

  const changeFlightStatus = (from: FlightListName, to?: FlightListName) => (key: string) => {
    const matchesFlight = matchesFlightKey(key);
    const flight = _.find(_.concat(eligibleFlights, attachedToAnotherStrategy), matchesFlight);
    if ((from !== 'attachedToAnotherStrategy' && to !== 'eligibleFlights') || _.isNil(to)) {
      setSessionAttachFlights(_.filter([...sessionAttachFlights], (f) => f.externalId !== flight.externalId));
    }
    const currentFlightLists = getCurrentFlightLists();
    changeFlightStatusUtilHelper(
      currentFlightLists,
      setValue,
      flightsStatus,
      key,
      from,
      modalSessionFlightsInfo,
      setModalSessionFlightsInfo,
      to,
      flight,
    );
  };

  const selectionHeader = configuringLineItemStratCheck(selectedOptType) ? 'Selected Line Items' : 'Selected Campaigns and/or Insertion Orders';

  const defaultFlightRowTableProps = {
    flightsStatus,
    tableStyle: style.flight.table,
    detachable: true,
  };

  const handleFlightStatusChange = (fKey) => {
    const matchesFlight = matchesFlightKey(fKey);
    const flight = _.find(sessionAttachFlights, matchesFlight);
    const previouslyAttached = _.includes(attachedToThisStrategy, flight);
    changeFlightStatus(previouslyAttached ? 'attachedToThisStrategy' : 'eligibleFlights')(fKey);
  };

  return (
    <>
      <FlightRowTableLoading
        flights={pendingFlights}
      />
      {(attachedToAnotherStrategy.length > 0 || allEligibleFlights.length > 0) && (
        <div style={style.flightRowContainer}>
          <WppTypography style={style.flightRowHeader} tag="p" type="s-strong">{selectionHeader}</WppTypography>
          <FlightRowTable
            {...defaultFlightRowTableProps}
            alreadyAttached
            color={WPP.warning400}
            flights={attachedToAnotherStrategy}
            removeFlight={changeFlightStatus('attachedToAnotherStrategy')}
            flightOverrideStrategy={changeFlightStatus('attachedToAnotherStrategy', 'eligibleFlights')}
            advertiserExtId={_.get(advertiser, 'externalId')}
            memberExtId={_.get(member, 'externalId')}
          />
          <FlightRowTable
            {...defaultFlightRowTableProps}
            color={WPP.success500}
            flights={allEligibleFlights}
            removeFlight={(fKey) => handleFlightStatusChange(fKey)}
            advertiserExtId={_.get(advertiser, 'externalId')}
            memberExtId={_.get(member, 'externalId')}
          />
        </div>
      )}
      { ineligibleFlights.length > 0 && (
      <Accordion style={style.flight.accordion}>
        <Accordion.Title
          onClick={() => setAccordionOpen(!accordionOpen)}
          active={accordionOpen}
        >
          <Icon name="dropdown" />
          These {flightDisplayName.multiple} have finished or are ineligible. They will not be updated by {APP_NAME}.
        </Accordion.Title>
        <Accordion.Content active={accordionOpen}>
          <FlightRowTable
            {...defaultFlightRowTableProps}
            notEligible
            detachable={false}
            flights={ineligibleFlights}
            color={NEUTRALS.N400_GRANITE}
          />
        </Accordion.Content>
      </Accordion>
      )}
    </>
  );
};
