import _ from 'lodash';
import React, { MutableRefObject, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Image, WppTypography, WppSideModal, WppDivider, WppGrid } from 'buildingBlocks';
import { getFormulaMetrics } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { OPERATOR_SVGS } from 'containers/StrategyWizard/constants';
import { AWG_GOAL_PAGE_STYLES, FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import FormulaMetric from './FormulaMetric';
import ConfirmationModal from './ConfirmationModal';
import WeightsSection from './WeightsSection/WeightsSection';
import WeightsSectionProvider from './contexts/WeightsSectionProvider';
import { useAWGContext } from './contexts/AWGProvider';
import FormulaBuilder from './FormulaSection/FormulaBuilder';
import GoalSettings from './CustomGoalSection/GoalSettings';
import BackButtonConfirmModal from './BackButtonConfirmModal';

const { formulaTextGridStyle, formulFieldStyle, gridBorderStyle, awgModalDivider, awgGoalSettingsDivider, awgPageModalBtn } = AWG_GOAL_PAGE_STYLES;
const { operatorImageStyle, styleFormula, itemNumBg } = FORMULA_SECTION_STYLES;

type ImpactFormulaSectionProps = {
  formulaText: Array<JSX.Element>
};

const ImpactFormulaSection = ({ formulaText }: ImpactFormulaSectionProps) => (
  <WppGrid style={gridBorderStyle}>
    <div style={formulaTextGridStyle}>
      <div style={formulFieldStyle}>
        {formulaText}
      </div>
    </div>
  </WppGrid>
);

type AWGPageProps = {
  awgRef: MutableRefObject<boolean>
  AWGModalOpen?: boolean
  setAWGModalOpen?: (x: any) => void
};

const AWGPage = ({ awgRef, AWGModalOpen, setAWGModalOpen }: AWGPageProps) => {
  const { control } = useFormContext();
  const { selectedFormula, formulaMetrics, isImpactGoal, isCampaignOptType, setFormulaMetrics } = useAWGContext();
  const metricsConfig = useWatch({ name: 'metricsConfig' });

  // eslint-disable-next-line no-param-reassign
  awgRef.current = true;

  useEffect(() => {
    // determine if we populate formulaMetrics from metricConfig or from scratch
    const getFromConfig = !_.isEmpty(metricsConfig);
    const metricInit = getFormulaMetrics(selectedFormula?.metrics, isCampaignOptType, metricsConfig, getFromConfig);
    setFormulaMetrics(metricInit);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFormula, isCampaignOptType, metricsConfig]);

  const formulaText = _.map(selectedFormula?.formula, (item: string, i: number) => {
    if (formulaMetrics && _.includes(selectedFormula.metrics, item)) {
      return <FormulaMetric key={i} metric={formulaMetrics[item]} />;
    }
    if (_.has(OPERATOR_SVGS, item)) {
      return <span key={i}><Image style={operatorImageStyle} src={OPERATOR_SVGS[item]} /></span>;
    }
    return <span key={i} style={_.isNumber(item) || !_.isNaN(Number(item)) ? itemNumBg : styleFormula}>{item}</span>;
  });

  const handleModalClose = (e) => {
    e.stopPropagation();
    setAWGModalOpen(false);
  };

  return (
    <>
      {createPortal(
        <WppSideModal open={AWGModalOpen} onWppSideModalClose={handleModalClose} size="2xl" disableOutsideClick>
          <WppTypography slot="header" tag="p" type="2xl-heading" className="AWGHeader">{!isImpactGoal ? 'Create Any Goal' : 'Cost Per Impact'}</WppTypography>
          <div slot="body">
            {!isImpactGoal
              ? (<FormulaBuilder />)
              : (<ImpactFormulaSection formulaText={formulaText} />)}
            <WppDivider style={awgModalDivider} />
            <Controller
              name="metricsConfig"
              control={control}
              render={() => (
                <WeightsSectionProvider isCampaignOptType={isCampaignOptType}>
                  <WeightsSection />
                </WeightsSectionProvider>
              )}
            />
            <WppDivider style={awgGoalSettingsDivider} />
            {!isImpactGoal && <GoalSettings />}
          </div>
          <div slot="actions" style={awgPageModalBtn}>
            <BackButtonConfirmModal setAWGModalOpen={setAWGModalOpen} />
            <ConfirmationModal setAWGModalOpen={setAWGModalOpen} />
          </div>
        </WppSideModal>,
        document.body,
      )}
    </>
  );
};
export default AWGPage;
