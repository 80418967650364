import React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { Image, WppGrid, WppTypography } from 'buildingBlocks';
import { CURSOR_POINTER } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { AWG_GOAL_PAGE_STYLES, FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import OperatorsSection from './OperatorsSection';
import MetricsSection from './MetricsSection';
import DropArea from './DropArea';
import FormulaProvider from '../contexts/FormulaProvider';

const { dropArea, metricGuide } = FORMULA_SECTION_STYLES;
const { subHeaderStyle } = AWG_GOAL_PAGE_STYLES;

const SUBHEADER_TEXT = 'Drag and drop Operators and Metrics to create a custom formula. You can also type directly in the Formula Builder.';
const GUIDE_TEXT = 'Drag and drop Operators and Metrics into the Formula Builder.';

const MetricGuide = () => (
  <div style={metricGuide}>
    <Image alt="pointer" src={CURSOR_POINTER} />
    {GUIDE_TEXT}
  </div>
);

const FormulaBuilder = () => (
  <>
    <WppTypography type="s-body" style={subHeaderStyle} tag="p">{SUBHEADER_TEXT}</WppTypography>
    <FormulaProvider>
      <WppGrid>
        <DndProvider backend={HTML5Backend}>
          <WppGrid direction="row" style={dropArea}>
            <OperatorsSection />
            <MetricsSection />
          </WppGrid>
          <MetricGuide />
          <WppGrid direction="row">
            <DropArea />
          </WppGrid>
        </DndProvider>
      </WppGrid>
    </FormulaProvider>
  </>
);

export default FormulaBuilder;
