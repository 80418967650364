import _ from 'lodash';
import { UseFormSetValue } from 'react-hook-form';
import { ExternalTypeOptimizationLevel, FlightCategory, OPTIMIZATION_LEVELS, OptimizationType } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { modifyFlightLists, FlightLists, FlightListName } from 'containers/StrategyWizard/steps/AttachFlights/components/AttachFlightsModal/AttachFlightsModalTable';
import { configuringLineItemStratCheck } from 'containers/StrategyWizard/steps/AttachFlights/utils';
import { AttachFlightsInfoType, ModalSessionFlightsInfoType, WizardFormAttachFlights } from 'containers/StrategyWizard/types';
import { Flight, Member } from 'utils/types';

export const getFilter = (
  advertiserId: number,
  memberId: number,
  strategyTypeId: number | string,
  searchTerm?: string,
) => ({
  advertiserId,
  memberId,
  strategyTypeId,
  searchTerm,
});

export const changeFlightStatusUtilHelper = (
  currentFlightLists: FlightLists,
  setValue: UseFormSetValue<WizardFormAttachFlights>,
  flightsStatus: {},
  key: string,
  from: FlightListName,
  flightsInfo: ModalSessionFlightsInfoType | AttachFlightsInfoType,
  setFlightsInfo: (arg: any) => void,
  to?: FlightListName,
  flight?: Flight,
) => {
  // check if flightsInfo object is from modalSessionFlightsInfo or attachFlightsInfo (outer state)
  const isAttachFlightsInfo = !_.has(flightsInfo, 'searchTerm');

  const updatedFlightLists = modifyFlightLists(currentFlightLists, key, from, to);
  // don't count detached flights as part of the attached flights form value, as it could lead to duplicate flights
  // in the list, and also mess with the selectionLimit (if provided)
  const allNonDetachedFlightsSelected = _.union(..._.values(_.omit(updatedFlightLists, 'toBeDetached')));

  // only call setValue if flightsInfo is AttachFlightsInfoType
  if (isAttachFlightsInfo) {
    setValue('attachedFlights', allNonDetachedFlightsSelected);
  }

  const updatedFlightsStatus = to ? flightsStatus : _.omit(flightsStatus, [key]);

  const updatedFlightsInfoBase = {
    ...flightsInfo,
    flightsStatus: updatedFlightsStatus,
    attachedToThisStrategy: updatedFlightLists.attachedToThisStrategy,
    eligibleFlights: updatedFlightLists.eligibleFlights,
    ineligibleFlights: updatedFlightLists.ineligibleFlights,
    ...(!isAttachFlightsInfo && { attachedToAnotherStrategy: updatedFlightLists.attachedToAnotherStrategy }),
    ...(_.has(currentFlightLists, FlightCategory.toBeDeactivated) && {
      reactivatedFlights: updatedFlightLists.reactivatedFlights,
      toBeDeactivated: updatedFlightLists.toBeDeactivated,
      deactivatedFlights: updatedFlightLists.deactivatedFlights,
      eligCPFlightsWithoutAmountBudgetType: updatedFlightLists.eligCPFlightsWithoutAmountBudgetType,
      eligCPFlightsWithSpend: updatedFlightLists.eligCPFlightsWithSpend,
    }),
  };

  // re-fetches flights only if function is called from modal, otherwise will just set the respective state
  if (isAttachFlightsInfo) {
    const updatedAttachFlightsInfo = { ...updatedFlightsInfoBase, toBeDetached: updatedFlightLists.toBeDetached };
    setFlightsInfo(updatedAttachFlightsInfo);
  } else {
    const modalFlightCandidates = _.get(flightsInfo, 'flightCandidates', []);
    const updatedModalFlightsInfo = { ...updatedFlightsInfoBase, flightCandidates: (to ? modalFlightCandidates : [...modalFlightCandidates, flight].sort((a, b) => (b.externalId - a.externalId))) };
    setFlightsInfo(updatedModalFlightsInfo);
  }
};

export const mergeFlights = (flightArr, flightArr2, flightArr3 = []) => _.uniqBy(_.concat(flightArr, flightArr2, flightArr3), 'id');

export const getOptimizationLevel = (member: Member, selectedOptType: OptimizationType) => {
  const dsp = member.dsp as number;
  return configuringLineItemStratCheck(selectedOptType)
    ? OPTIMIZATION_LEVELS[dsp][ExternalTypeOptimizationLevel.LOWER_ORDER]
    : OPTIMIZATION_LEVELS[dsp][ExternalTypeOptimizationLevel.HIGHER_ORDER];
};
