import _ from 'lodash';
import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { WppIconImport, WppTypography } from 'buildingBlocks';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { useAWGContext } from 'containers/StrategyWizard/steps/GoalSelection/GoalSection/AWG/contexts/AWGProvider';
import { useFormulaContext } from 'containers/StrategyWizard/steps/GoalSelection/GoalSection/AWG/contexts/FormulaProvider';
import GrabFormulaModal from './GrabFormulaModal';

const { grabFormulaStyle, grabFormulaTextDivider } = FORMULA_SECTION_STYLES;

const GrabFormula = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { searchInputRef, dropzoneRef } = useFormulaContext();
  const { selectedFormula } = useAWGContext();
  const { setValue } = useFormContext();
  const customGoal = useWatch({ name: 'customGoal' });

  const onClickHandle = () => {
    setOpenModal(true);
  };

  const onModalClose = () => {
    const customFormulaArr = _.get(customGoal, 'formula');
    const selectedFormulaArr = _.get(selectedFormula, 'formula');
    if (!customFormulaArr && selectedFormulaArr) {
      setValue('customGoal.formula', selectedFormulaArr);
    }

    setOpenModal(false);
    searchInputRef.current = null;
    dropzoneRef.current.focus();
  };

  return (
    <>
      <div style={grabFormulaStyle} onClick={onClickHandle} role="button" tabIndex={0}>
        <WppIconImport />
        <WppTypography type="xs-midi" tag="p" style={grabFormulaTextDivider}>Grab Formula</WppTypography>
      </div>
      <GrabFormulaModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        onModalClose={onModalClose}
      />
    </>
  );
};

export default GrabFormula;
